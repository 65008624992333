import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchRoles() {
	while (true) {
		const { payload: { platform }} = yield take(types.FETCH_ROLES)
		try {
			yield put(actions.isFetchingRoles(false))
			const {data: response} = yield call(api.fetchRoles, platform)
			yield put(actions.roles(response))
			yield put(actions.isFetchingRoles(true))
		} catch (err) {
			yield put(actions.isFetchingRoles(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			console.error('[fetchRoles] ', err)
			eventlog.error('fetch roles', {
				message: err.message
			})
		}
	}
}

export default fetchRoles
