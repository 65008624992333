import HandleSource from "../system/handle/handleSource";
import PlusSvg from "../../../../assets/svg/Plus";
import React, { useEffect, useState } from "react";
import TrashSvg from "../../../../assets/svg/Trash";
import styles from "./union-message.module.scss";
import { Position } from "@xyflow/react";
import { handleConnectableMax, handlePortId } from "../../config";

const Button = ({
	items: _items,
	onDeleteButtonItem,
	onAddButtonItem,
	onButtonItemMessageChange,
	selectedApp,
	isTarget,
}) => {
	const [items, setItems] = useState(_items);

	useEffect(() => {
		setItems(_items);
	}, [_items]);

	return (
		<div className="sub-message-container">
			<div className="button">
				{items.map((item, i) => {
					return (
						<div key={i} className={`item ${styles.buttonContainer}`}>
							<div
								className={styles.imageBox}
								style={{
									backgroundImage: `url("${item.image}")`,
								}}
							></div>
							<input
								className="node"
								data-nodeid="NONE"
								placeholder="Click here to edit"
								defaultValue={item.label}
								onChange={(e) => {
									items[i].label = e.target.value;
									setItems(items);
								}}
								onBlur={() => {
									onButtonItemMessageChange(i, items[i].label);
								}}
							/>
							<div className="piece-end-point">
								<HandleSource
									id={item.id}
									position={Position.Right}
									isTarget={isTarget}
									isConnectable={handleConnectableMax.medium}
								/>
								<div className="piece-end-point__drag-helper">
									<img
										alt=""
										className="piece-end-point__drag-helper-img"
										src="/images/help-arrow.png"
									/>
								</div>
							</div>
							<div className="message-menu">
								<div className="message-menu-body">
									<button
										className="transparent"
										onClick={() => onDeleteButtonItem(i)}
									>
										<TrashSvg />
									</button>
									<button
										className="transparent"
										onClick={() => onAddButtonItem(i)}
									>
										<PlusSvg />
									</button>
								</div>
							</div>
						</div>
					);
				})}
				<div className="item default none" data-nodeid="NONE">
					Default
					<div className="piece-end-point">
						<HandleSource
							id={handlePortId.default}
							position={Position.Right}
							isTarget={isTarget}
							isConnectable={handleConnectableMax.medium}
						/>
						<div className="piece-end-point__drag-helper">
							<img
								alt=""
								className="piece-end-point__drag-helper-img"
								src="/images/help-arrow.png"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Button;
