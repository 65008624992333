import * as types from "../types";

const defaultState = {
	messageProperties: {
		index: null,
		message: null,
	},
	config: {
		position: {
			x: 2000,
			y: 2000,
		},
	},
	backendstep: {
		step: 0,
		type: null,
		finishedStep: null,
	},
	removedMessages: [],
	isFetchingResponsesStats: true,
	isFetchingResponse: true,
	editMessageErrors: {},
	isFetchingModuleResponses: false,
	moduleResponses: [],
	variables: {},
	flexErrorPath: [],
	imageSizeError: true,
	isHasIntro: false,
	isFetchingDashboard: false,
};

const messageConfig = (state = defaultState, action) => {
	switch (action.type) {
		case types.CREATE_NEW_MESSAGE_ERROR:
			return {
				...state,
				createNewMessageError: action.payload.error,
			};
		case types.MESSAGE_PROPERTIES:
			return {
				...state,
				messageProperties: {
					index: action.payload.index,
					message: action.payload.message,
				},
			};
		case types.START_NODE_CONFIG:
			return {
				...state,
				startNodeConfig: action.payload.config,
			};
		case types.CONFIGURATION:
			return {
				...state,
				config: action.payload.config,
			};
		case types.ON_SELECT_STEP:
			return {
				...state,
				backendstep: {
					step: action.payload.step,
					type: action.payload.type,
					finishedStep: action.payload.finishedStep,
				},
			};
		case types.EDIT_MESSAGE:
			return {
				...state,
				editMessage: action.payload.editMessage,
			};
		case types.REMOVED_MESSAGES:
			return {
				...state,
				removedMessages: action.payload.messages,
			};
		case types.EDIT_MESSAGE_ERRORS:
			return {
				...state,
				editMessageErrors: action.payload.errors,
			};
		case types.ON_EDIT_MAP:
			return {
				...state,
				editMap: action.payload.data,
			};
		case types.ON_CLOSE_EDIT_MAP:
			return {
				...state,
				editMap: null,
			};
		case types.DESIGN:
			return {
				...state,
				design: action.payload.design,
			};
		case types.IS_FETCHING_RESPONSES_STATS:
			return {
				...state,
				isFetchingResponsesStats: action.payload.status,
			};
		case types.RESPONSES_STATS:
			return {
				...state,
				responsesStats: action.payload.responsesStats,
			};
		case types.RESPONSE:
			return {
				...state,
				response: action.payload.response,
			};
		case types.RESPONSES_GROUP_BY_DEVICE:
			return {
				...state,
				responsesGroupByDevice: action.payload.responsesGroupByDevice,
			};
		case types.IS_FETCHING_RESPONSE:
			return {
				...state,
				isFetchingResponse: action.payload.status,
			};
		case types.IS_FETCHING_MODULE_RESPONSES:
			return {
				...state,
				isFetchingModuleResponses: action.payload.status,
			};
		case types.MODULE_RESPONSES:
			return {
				...state,
				moduleResponses: action.payload.moduleResponses,
			};
		case types.VARIABLES:
			return {
				...state,
				variables: action.payload.variables,
			};
		case types.FLEX_ERROR:
			return {
				...state,
				flexErrorPath: action.payload,
			};
		case types.IMAGE_SIZE_ERROR:
			return {
				...state,
				imageSizeError: action.payload,
			};
		case types.IS_HAS_INTRO:
			return {
				...state,
				isHasIntro: action.payload.status,
			};
		case types.IS_FETCHING_DASHBOARD:
			return {
				...state,
				isFetchingDashboard: action.payload.status,
			};
		default:
			return state;
	}
};

export default messageConfig;
