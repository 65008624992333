import * as actions from "../actions";
import * as api from "../apis";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, put, take } from "redux-saga/effects";

function* fetchSaveDiagramHistory() {
	while (true) {
		yield take(types.SAVE_DIAGRAM_HISTORIES_QUERY);
		try {
			eventlog.info("fetch Save Diagram History");
			yield put(actions.isFetchingSaveDiagramHistory(true));
			const { data } = yield call(api.fetchSaveDiagramHistories);
			yield put(actions.saveDiagramHistory(data));
			yield put(actions.isFetchingSaveDiagramHistory(false));
		} catch (err) {
			yield put(actions.isFetchingSaveDiagramHistory(false));
			console.error("[onfetchSaveDiagramHistory] ", err);
			eventlog.error("enter SaveDiagram History", {
				message: err.message,
			});
		}
	}
}

export default fetchSaveDiagramHistory;
