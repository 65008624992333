import * as api from "../apis";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, take } from "redux-saga/effects";

function* saveDiagramHistoryUpdate() {
	while (true) {
		const {
			payload: { id, data },
		} = yield take(types.SAVE_DIAGRAM_HISTORIES_UPDATE);
		try {
			yield call(api.updateSaveDiagramHistories, id, data);
		} catch (err) {
			console.error("[saveDiagramHistoryUpdate] ", err);
			eventlog.error("save Diagram History Update", {
				message: err.message,
			});
		}
	}
}

export default saveDiagramHistoryUpdate;
