import zukeeper from "zukeeper";
import { create } from "zustand";

const storeUser = (set, get) => ({
    users: {},
    userCount: 0,
    addUser: (item) =>
        set((state) => {
            state.users[item.id] = item;

            return {
                users: { ...state.users },
                userCount: Object.entries(state.users).length
            };
        }),
    removeUser: (item) =>
        set((state) => {
            delete state.users[item.id];

            return {
                users: { ...state.users },
                userCount: Object.entries(state.users).length
            };
        }),
});

export const useStoreUser = create(zukeeper(storeUser));

window.storeUser = useStoreUser;
