import Placeholder from "../placeholder";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "@xyflow/react";
import { SHOW_CONTENT_ZOOM_OVER } from "../../../config";

function ContextualZoom(props) {
    const { isTarget, children } = props;

    const isShowContent = useStore(
        (s) => s.transform[2] >= SHOW_CONTENT_ZOOM_OVER
    );

    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);

    useEffect(() => {
        setHeight(Math.ceil(elementRef.current.clientHeight / 15));
    }, []); //empty dependency array so it only runs once at render

    const styleGlobal = {
        transition: "box-shadow 0.3s",
    };

    const styleContent = {
        boxShadow: "rgb(0 245 255) 0px 0px 20px, rgb(0 255 206) 0px 0px 15px",
        borderRadius: "5px",
    };

    return (
        <div
            ref={elementRef}
            style={
                isTarget
                    ? {
                        ...styleContent,
                        ...styleGlobal,
                    }
                    : {
                        ...styleGlobal,
                    }
            }
        >
            {!isShowContent && <Placeholder size={height} />}
            {children}
        </div>
    );
}

export default ContextualZoom;
