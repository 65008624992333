import * as actions from "../actions";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { put, select, take } from "redux-saga/effects";
import { getMessageConfigDiagram } from "../../components/diagram/store/global/diagram";

function* previewSaveDiagramHistory() {
	while (true) {
		const {
			payload: { messageConfig },
		} = yield take(types.PREVIEW_SAVE_DIAGRAM_HISTORY);
		try {
			let messageConfigBackupDiagramHistory = yield select(
				selectors.getMessageConfigBackupDiagramHistory
			);

			if (!messageConfigBackupDiagramHistory) {
				yield put(
					actions.messageConfigBackupDiagramHistory(getMessageConfigDiagram())
				);
			}

			yield put(actions.selectSaveDiagramHistory(messageConfig, true));
		} catch (err) {
			console.error("[previewSaveDiagramHistory] ", err);
			eventlog.error("preview Save Diagram History", {
				message: err.message,
			});
		}
	}
}

export default previewSaveDiagramHistory;
