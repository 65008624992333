export const AMOUNT_EVENT_PER_MESSAGE_COLLABORATIVE = 10;
export const MAX_THROTTLING_TIME_CURSOR_COLLABORATIVE = 200;
export const TIME_PER_AMOUNT_SEND_EVENT_COLLABORATIVE = 1000;

export const NAME_PACK_CURSOR_COLLABORATIVE = "cursors";
export const NAME_PACK_USER_COLLABORATIVE = "users";
export const NAME_PACK_NODE_COLLABORATIVE = "nodes";
export const NAME_PACK_EDGE_COLLABORATIVE = "edges";

export const NODE_MOVE_POSITION_ACTION_COLLABORATIVE = "nodeMovePosition";
export const NODE_ADD_ACTION_COLLABORATIVE = "nodeAdd";
export const NODE_REMOVE_ACTION_COLLABORATIVE = "nodeRemove";
export const NODE_DATA_ACTION_COLLABORATIVE = "nodeData";
export const NODE_REPLACE_ACTION_COLLABORATIVE = "nodeReplace";
export const NODE_SELECT_ACTION_COLLABORATIVE = "nodeSelect";

export const EDGE_ADD_ACTION_COLLABORATIVE = "edgeAdd";
export const EDGE_REMOVE_ACTION_COLLABORATIVE = "edgeRemove";
export const EDGE_RESET_ACTION_COLLABORATIVE = "edgeReset";

export const CURSOR_MOVE_POSITION_ACTION_COLLABORATIVE = "cursorMovePosition";
export const CURSOR_ADD_ACTION_COLLABORATIVE = "cursorAdd";
export const CURSOR_REMOVE_ACTION_COLLABORATIVE = "cursorRemove";
export const CURSOR_HIDE_ACTION_COLLABORATIVE = "cursorHide";
export const CURSOR_SHOW_ACTION_COLLABORATIVE = "cursorShow";

export const USER_ADD_ACTION_COLLABORATIVE = "userAdd";
export const USER_REMOVE_ACTION_COLLABORATIVE = "userRemove";
export const USER_IDLE_ACTION_COLLABORATIVE = "userIdle";
export const USER_ACTIVE_ACTION_COLLABORATIVE = "userActive";

export const IDLE_STATUS_USER_COLLABORATIVE = "Idle";
export const ACTIVE_STATUS_USER_COLLABORATIVE = "Active";