const design = {
    template: {
        KASHMIR: {
            container: {
                style: {
                    backgroundImage: 'linear-gradient(rgb(0, 178, 169), rgb(85, 226, 186))',
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(222, 69, 97)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'gradient',
                        gradient: {
                            from: '#00B2A9',
                            to: '#55E2BA'
                        },
                        color: {
                            color: ''
                        },
                        image: {
                            desktop: '',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'custom',
                        custom: {
                            backgroundColor: 'rgab(0,0,0,0)',
                            textColor: '#FFF'
                        }
                    },
                    chatBubbles: {
                        selected: 'default',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#0B4359'
                            },
                            user: {
                                backgroundColor: '#0B4359',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        CARIBOU: {
            container: {
                style: {
                    backgroundImage: 'linear-gradient(rgb(52, 73, 94), rgb(52, 73, 94))',
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(33, 52, 70)',
                    color: 'rgb(236, 240, 241)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'color',
                        gradient: {
                            from: '#00B2A9',
                            to: '#55E2BA'
                        },
                        color: {
                            color: '#34495E'
                        },
                        image: {
                            desktop: '',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'custom',
                        custom: {
                            backgroundColor: 'rgab(0,0,0,0)',
                            textColor: '#FFF'
                        }
                    },
                    chatBubbles: {
                        selected: 'default',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#0B4359'
                            },
                            user: {
                                backgroundColor: '#0B4359',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        REFLEKTOR: {
            container: {
                style: {
                    backgroundImage: 'linear-gradient(rgb(255, 87, 91), rgb(241, 25, 82))',
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(241, 25, 82)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'gradient',
                        gradient: {
                            from: '#FF575B',
                            to: '#F11952'
                        },
                        color: {
                            color: '#34495E'
                        },
                        image: {
                            desktop: '',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'custom',
                        custom: {
                            backgroundColor: 'rgab(0,0,0,0)',
                            textColor: '#FFF'
                        }
                    },
                    chatBubbles: {
                        selected: 'default',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#0B4359'
                            },
                            user: {
                                backgroundColor: '#0B4359',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        STARMAN: {
            container: {
                style: {
                    backgroundImage: 'linear-gradient(rgb(255, 173, 89), rgb(255, 225, 153))',
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(211, 95, 143)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'gradient',
                        gradient: {
                            from: '#FFAD59',
                            to: '#FFE199'
                        },
                        color: {
                            color: '#34495E'
                        },
                        image: {
                            desktop: '',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'default',
                        custom: {
                            backgroundColor: '#FFFFFF',
                            textColor: '#0B4359'
                        }
                    },
                    chatBubbles: {
                        selected: 'default',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#0B4359'
                            },
                            user: {
                                backgroundColor: '#0B4359',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        GRACE: {
            container: {
                style: {
                    backgroundImage: 'linear-gradient(black, black)',
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(247, 43, 101)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'video',
                        gradient: {
                            from: '#FFAD59',
                            to: '#FFE199'
                        },
                        color: {
                            color: '#34495E'
                        },
                        image: {
                            desktop: '',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'custom',
                        custom: {
                            backgroundColor: 'rgab(0,0,0,0)',
                            textColor: '#FFF'
                        }
                    },
                    chatBubbles: {
                        selected: 'default',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#0B4359'
                            },
                            user: {
                                backgroundColor: '#0B4359',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        DEFAULT: {
            container: {
                style: {
                    backgroundImage: 'linear-gradient(rgb(0, 178, 169), rgb(85, 226, 186))',
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(222, 69, 97)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'gradient',
                        gradient: {
                            from: '#00B2A9',
                            to: '#55E2BA'
                        },
                        color: {
                            color: '#34495E'
                        },
                        image: {
                            desktop: '',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'custom',
                        custom: {
                            backgroundColor: 'rgab(0,0,0,0)',
                            textColor: '#FFF'
                        }
                    },
                    chatBubbles: {
                        selected: 'default',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#0B4359'
                            },
                            user: {
                                backgroundColor: '#0B4359',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        SUPPORT: {
            container: {
                style: {
                    backgroundImage: 'linear-gradient(rgb(223, 225, 237), rgb(223, 225, 237))',
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(239, 0, 117)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'color',
                        gradient: {
                            from: '#00B2A9',
                            to: '#55E2BA'
                        },
                        color: {
                            color: '#DFE1ED'
                        },
                        image: {
                            desktop: '',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'default',
                        custom: {
                            backgroundColor: '#FFFFFF',
                            textColor: '#0B4359'
                        }
                    },
                    chatBubbles: {
                        selected: 'custom',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#110B59'
                            },
                            user: {
                                backgroundColor: '#4454C5',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        LAUNCH: {
            container: {
                style: {
                    backgroundImage: `url('https://storage.googleapis.com/media.helloumi.com/channels/0_cPU3p0L.jpg')`,
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(239, 0, 117)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'image',
                        gradient: {
                            from: '#00B2A9',
                            to: '#55E2BA'
                        },
                        color: {
                            color: '#34495E'
                        },
                        image: {
                            desktop: 'https://storage.googleapis.com/media.helloumi.com/channels/0_cPU3p0L.jpg',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'custom',
                        custom: {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            textColor: '#110B59'
                        }
                    },
                    chatBubbles: {
                        selected: 'default',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#0B4359'
                            },
                            user: {
                                backgroundColor: '#0B4359',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        EVENT: {
            container: {
                style: {
                    backgroundImage: `url('https://storage.googleapis.com/media.helloumi.com/channels/0_iLmviYq.png')`,
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(226, 19, 124)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'image',
                        gradient: {
                            from: '#00B2A9',
                            to: '#55E2BA'
                        },
                        color: {
                            color: '#34495E'
                        },
                        image: {
                            desktop: 'https://storage.googleapis.com/media.helloumi.com/channels/0_iLmviYq.png',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'default',
                        custom: {
                            backgroundColor: '#FFFFFF',
                            textColor: '#0B4359'
                        }
                    },
                    chatBubbles: {
                        selected: 'default',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#0B4359'
                            },
                            user: {
                                backgroundColor: '#0B4359',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        JOB: {
            container: {
                style: {
                    backgroundImage: `url('https://storage.googleapis.com/media.helloumi.com/channels/0_VwXztq0.png')`,
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(41, 178, 188)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'image',
                        gradient: {
                            from: '#00B2A9',
                            to: '#55E2BA'
                        },
                        color: {
                            color: '#34495E'
                        },
                        image: {
                            desktop: 'https://storage.googleapis.com/media.helloumi.com/channels/0_VwXztq0.png',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'default',
                        custom: {
                            backgroundColor: '#FFFFFF',
                            textColor: '#0B4359'
                        }
                    },
                    chatBubbles: {
                        selected: 'custom',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#0B4359'
                            },
                            user: {
                                backgroundColor: '#0B4359',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        SURVEY: {
            container: {
                style: {
                    backgroundImage: 'linear-gradient(rgb(87, 103, 220), rgb(87, 103, 220))',
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(0, 184, 221)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'color',
                        gradient: {
                            from: '#00B2A9',
                            to: '#55E2BA'
                        },
                        color: {
                            color: '#5767DC'
                        },
                        image: {
                            desktop: '',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'custom',
                        custom: {
                            backgroundColor: 'rgab(0,0,0,0)',
                            textColor: '#FFF'
                        }
                    },
                    chatBubbles: {
                        selected: 'custom',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#110B59'
                            },
                            user: {
                                backgroundColor: '#4454C5',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        LEADS: {
            container: {
                style: {
                    backgroundImage: `url('https://storage.googleapis.com/media.helloumi.com/channels/0_1ivKLt6.jpg')`,
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(239, 0, 117)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'image',
                        gradient: {
                            from: '#00B2A9',
                            to: '#55E2BA'
                        },
                        color: {
                            color: '#34495E'
                        },
                        image: {
                            desktop: 'https://storage.googleapis.com/media.helloumi.com/channels/0_1ivKLt6.jpg',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'default',
                        custom: {
                            backgroundColor: '#FFFFFF',
                            textColor: '#0B4359'
                        }
                    },
                    chatBubbles: {
                        selected: 'custom',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#0B4359'
                            },
                            user: {
                                backgroundColor: '#CED8A8',
                                textColor: '#110B59'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        VLAD: {
            container: {
                style: {
                    backgroundImage: 'linear-gradient(rgb(37, 28, 28), rgb(37, 28, 28))',
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(178, 37, 81)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'color',
                        gradient: {
                            from: '#00B2A9',
                            to: '#55E2BA'
                        },
                        color: {
                            color: '#251C1C'
                        },
                        image: {
                            desktop: '',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'custom',
                        custom: {
                            backgroundColor: '#B22551',
                            textColor: '#FFF'
                        }
                    },
                    chatBubbles: {
                        selected: 'custom',
                        custom: {
                            bot: {
                                backgroundColor: '#FFFFFF',
                                textColor: '#0B4359'
                            },
                            user: {
                                backgroundColor: '#0B4359',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        NUCLEUS: {
            container: {
                style: {
                    backgroundImage: 'linear-gradient(rgb(47, 49, 54), rgb(47, 49, 54))',
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(73, 176, 125)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'color',
                        gradient: {
                            from: '#00B2A9',
                            to: '#55E2BA'
                        },
                        color: {
                            color: '#2F3136'
                        },
                        image: {
                            desktop: '',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'default',
                        custom: {
                            backgroundColor: 'rgab(0,0,0,0)',
                            textColor: '#FFF'
                        }
                    },
                    chatBubbles: {
                        selected: 'custom',
                        custom: {
                            bot: {
                                backgroundColor: '#3D4147',
                                textColor: '#DCDCDC'
                            },
                            user: {
                                backgroundColor: '#49B07D',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        },
        'HUMAN-TAKEOVER': {
            container: {
                style: {
                    backgroundImage: 'linear-gradient(rgb(36, 150, 184), rgb(165, 220, 175))',
                    backgroundColor: 'transparent'
                }
            },
            textbox: {
                style: {
                    backgroundColor: 'rgb(29, 146, 186)',
                    color: 'rgb(255, 255, 255)'
                }
            },
            design: {
                colors: {
                    background: {
                        selected: 'gradient',
                        gradient: {
                            from: '#2496B8',
                            to: '#A5DCAF'
                        },
                        color: {
                            color: '#34495E'
                        },
                        image: {
                            desktop: '',
                            mobile: ''
                        },
                        video: {
                            video: 'https://static.landbot.io/landbot/video/video_1.mp4'
                        }
                    },
                    header: {
                        selected: 'custom',
                        custom: {
                            backgroundColor: 'rgab(0,0,0,0)',
                            textColor: '#FFF'
                        }
                    },
                    chatBubbles: {
                        selected: 'custom',
                        custom: {
                            bot: {
                                backgroundColor: '#DAECED',
                                textColor: '#34495E'
                            },
                            user: {
                                backgroundColor: '#1D92BA',
                                textColor: '#FFFFFF'
                            }
                        }
                    },
                    forms: {
                        selected: 'default',
                        custom: {
                            button: {
                                backgroundColor: '#29B2BC',
                                textColor: '#FFFFFF'
                            },
                            textarea: {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#29B2BC',
                                textColor: '#0B4359'
                            }
                        }
                    }
                },
                header: {
                    selected: 'default',
                    custom: {
                        brandName: '',
                        logo: '',
                        brandTagline: ''
                    }
                },
                fonts: {
                    size: '16'
                }
            }
        }
    }
}

export default design