import * as actions from '../actions'
import * as apis from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

function* enterSubscription() {
	while (true) {
		yield take(types.ENTER_SUBSCRIPTION)
		try {
			yield put(actions.isSubscriptionPageLoading(true))
			const { data: allPlans } = yield call(apis.getAllPlans)
			yield put(actions.allPlans(allPlans.plan))
			yield put(actions.storeSummaryPlan({ yearly_plan_discount_percent: allPlans.yearly_plan_discount_percent, vat_percent: allPlans.vat_percent }))

			const { data: cards } = yield call(apis.getCard)
			yield put(actions.currentCards(cards))

			const { data: currentPlan } = yield call(apis.getCurrentPlan)
			yield put(actions.selectedPlan(currentPlan))

			const { data: billingInformation } = yield call(apis.getBillingInformation)
			yield put(actions.storeBillingInformation(billingInformation))

			const { data: invoicesStatusPaidSubscription } = yield call(apis.getInvoicesSubscription, 'paid')
			yield put(actions.storeInvoicesStatusPaidSubscription(invoicesStatusPaidSubscription || []))

			const { data: invoicesStatusUnpaidSubscription } = yield call(apis.getInvoicesSubscription, 'unpaid')
			yield put(actions.storeInvoicesStatusUnpaidSubscription(invoicesStatusUnpaidSubscription || []))

			try {
				const { data: currenteSchedule } = yield call(apis.getCurrenteSchedule)
				yield put(actions.currentSchedule(currenteSchedule))
			} catch (e) {

			}
			yield put(actions.isSubscriptionPageLoading(false))
		} catch (err) {
			yield put(actions.isSubscriptionPageLoading(false))
			console.error('[enterSubscription] ', err)
		}
	}
}

export default enterSubscription
