import { edgeForceLayoutProps } from "../../config";
import { getToolDiagram } from "../global/diagram";

export const addEdgeForceLayout = (set, get, edges) => {
	try {
		const data = edges.map((edgeNow) => {
			return {
				...edgeForceLayoutProps,
				source: edgeNow.source.nodeId,
				target: edgeNow.target.nodeId,
				id: `${edgeNow.source.nodeId}-${edgeNow.source.handleId}|${edgeNow.target.nodeId}-${edgeNow.target.handleId}`,
			};
		});

		getToolDiagram("setEdges")((eds) => [...eds, ...data]);
	} catch (err) {
		console.error("[addEdgeForceLayout] ", err);
	}
};
