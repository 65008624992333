const generateQrCodePayment = (editMessage) => {
	let errors = {}
	if (editMessage.input.payment_type === "mobile_number") {
		const isDigit = /^\d+$/.test(editMessage.input.mobile_number)
		if (!isDigit || editMessage.input.mobile_number.length < 9 || editMessage.input.mobile_number.length > 10) {
			errors.mobileNumber = 'Mobile number must be a number with 9 to 10 digits'
		}
	} 
	if (editMessage.input.payment_type === "national_id_or_tax_id") {
		const isDigit = /^\d+$/.test(editMessage.input.national_id_or_tax_id)
		if (!isDigit || editMessage.input.national_id_or_tax_id.length !== 13) {
			errors.nationalIdOrTaxId = 'Mobile number must be a number with 13 digit'
		}
	} 
	if (editMessage.input.payment_type === "e_wallet_id") {
		const isDigit = /^\d+$/.test(editMessage.input.e_wallet_id)
		if (!isDigit || editMessage.input.e_wallet_id.length !== 15) {
			errors.eWalletId = 'Mobile number must be a number with 15 digit'
		}
	} 

	return errors;
};


export default generateQrCodePayment