import generateID from "../../../../utils/generateID";
import { useStoreDiagram } from "..";
import { nodeProps } from "../../config";
import { transformDiagram } from "../../utils/transform";
import { getMessageConfigDiagram, getToolDiagram } from "../global/diagram";

export const copyNode = (set, get, id) => {
    try {
        const messages = getMessageConfigDiagram();
        const newId = `ms_${generateID()}`;
        
        messages[id].selected = false
        getToolDiagram("setNodes")(transformDiagram(messages));

        const result = {
            ...messages[id],
        }

        delete result["dataOther"];
        delete result["measured"];
        delete result["height"];
        delete result["width"];
        delete result["positionAbsolute"];
        delete result["targetPosition"];
        delete result["sourcePosition"];
        delete result["dragging"];
        delete result["points"];
        delete result["internals"];
        delete result["CLIENT_ID_COLLABORATIVE"];
        delete result["CLIENT_ID_SELECT"];

        const newMessage = {
            ...result,
            ...nodeProps,
            id: newId,
            position: {
                x: window.mousePositionCenterDiagram.current.x,
                y: window.mousePositionCenterDiagram.current.y,
            },
            trigger: null,
        };

        useStoreDiagram.getState().addNewNodeInterface([newMessage]);
    } catch (err) {
        console.error("[copyNode] ", err);
    }
};
