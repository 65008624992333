import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchProducts() {
	while (true) {
		yield take(types.FETCH_PRODUCTS)
		try {
			yield put(actions.isFetchingProducts(true))
			const { data: products } = yield call(api.fetchProducts)
			yield put(actions.products(products || []))
			yield put(actions.isFetchingProducts(false))
		} catch (err) {
			yield put(actions.isFetchingProducts(false))
			yield put(actions.products([]))
			console.error('[fetchProducts] ', err)
			eventlog.error('fetch products', {
				message: err.message
			})
		}
	}
}

export default fetchProducts
