const richmenu = (editMessage) => {
	let errors = {}

	if (!(Object.keys(editMessage.input.menu).length > 0)) {
		errors.template = 'template'
	}

	if (!['image/jpeg', 'image/jpg', 'image/png'].includes(editMessage.input.image.extension)) {
		errors.extension = 'extension'
	}

	if (!(editMessage.input.image.dimension.width >= 800 && editMessage.input.image.dimension.width <= 2500)) {
		errors.width = 'width'
	}

	if ((!editMessage.input.image.dimension.height >= 250)) {
		errors.height = 'height'
	}

	if (!(editMessage.input.image.dimension.width / editMessage.input.image.dimension.height >= 1.45)) {
		errors.ratio = 'ratio'
	}

	if (!(editMessage.input.image.size > 0 && editMessage.input.image.size <= 1000000)) {
		errors.size = 'size'
	}

	return errors
}

export default richmenu