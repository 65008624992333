import * as actions from "../../actions";
import * as api from "../../apis";
import * as selectors from "../../selectors";
import * as types from "../../types";
import eventlog from "../../../modules/eventlog";
import validation from "./validation";
import { call, put, select, take } from "redux-saga/effects";

function* saveBillingInformation() {
	while (true) {
		yield take(types.ON_SAVE_BILLING_INFORMATION);
		try {
			eventlog.info("save billing information");
			const billingInformation = yield select(selectors.getBillingInformation);
			const errors = yield call(validation, billingInformation);

			if (Object.keys(errors).length > 0) {
				yield put(actions.onBillingInformationError(errors));
				continue;
			}
			yield put(actions.onBillingInformationError({}));
			yield call(api.onSaveBillingInformation, billingInformation);
			const { data } = yield call(api.getBillingInformation);
			yield put(actions.storeBillingInformation(data));
			const toastManager = yield select(selectors.getToast);
			toastManager.success("Saved successfully");
		} catch (err) {
			console.error("[saveBillingInformation] ", err);
			const toastManager = yield select(selectors.getToast);
			toastManager.error("Got the error from server");

			eventlog.error("save billing information", {
				message: err.message,
			});
		}
	}
}

export default saveBillingInformation;
