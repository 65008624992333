export const selectedNode = (set, get, nodes, userID) => {
	try {
		let selectedNodesTemp1 = get().selectedNodes;

		nodes.forEach((node) => {
			selectedNodesTemp1[userID] = {
				...selectedNodesTemp1[userID],
				[node.id]: node,
			};
		});

		set({
			selectedNodes: { ...selectedNodesTemp1 },
		});
	} catch (err) {
		console.error("[selectedNode] ", err);
	}
};
