import EdgeForceLayoutModel from "../edgeForceLayout";
import EdgeModel from "../edge";
import NodeForceLayoutModel from "../nodeForceLayout";
import NodeModel from "../node";
import React from "react";
import stylesForceLayout from "../nodeForceLayout/style.scss";

export const DIAGRAM_ID = "diagram";
export const DURATION_VIEW_PORT = 800;
export const DURATION_LOAD = 300;
export const MIN_DISTANCE_PROXIMITY_CONNECT = 150;
export const STROKE_WIDTH = 3;
export const STROKE_COLOR = "rgb(79, 204, 194)";
export const STROKE_COLOR_DRAG = "rgb(144, 157, 253)";
export const RADIUS_DRAG = 6;
export const STROKE_COLOR_SELECTED = "rgb(170, 170, 255)";
export const MIN_ZOOM = 0.2; // 20 %
export const MAX_ZOOM = 2; // 200%
export const SHOW_CONTENT_ZOOM_OVER = 0.4; // 40%

export const DIAGRAM_TYPE_CUSTOM = "custom";
export const DIAGRAM_TYPE_FORCE_LAYOUT = "forceLayout";

export const regexDraftForceLayoutId = /^DRAFT-[0-9]+$/;

export const METHOD_CREATE_FORCE_LAYOUT_DEFAULT = "default";
export const METHOD_CREATE_FORCE_LAYOUT_BUBBLE_FORCE = "bubble-force";

export const POSITION_TYPE_EVENT_DIAGRAM = "position";
export const ADD_TYPE_EVENT_DIAGRAM = "add";
export const REMOVE_TYPE_EVENT_DIAGRAM = "remove";
export const SELECT_TYPE_EVENT_DIAGRAM = "select";
export const RESET_TYPE_EVENT_DIAGRAM = "reset";
export const REPLACE_TYPE_EVENT_DIAGRAM = "replace";

export const fitViewOptions = {
    duration: DURATION_VIEW_PORT,
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
};

export const nodeTypes = {
    custom: NodeModel,
    forceLayout: NodeForceLayoutModel,
};

export const edgeTypes = {
    custom: EdgeModel,
    forceLayout: EdgeForceLayoutModel,
};

export const nodeProps = {
    type: DIAGRAM_TYPE_CUSTOM,
    dragHandle: ".custom-drag-handle",
    handles: {},
};

export const edgeProps = {
    type: DIAGRAM_TYPE_CUSTOM,
    animated: false,
    borderRadius: 20,
};

export const nodeForceLayoutProps = {
    type: DIAGRAM_TYPE_FORCE_LAYOUT,
    className: stylesForceLayout.node,
};

export const edgeForceLayoutProps = {
    type: DIAGRAM_TYPE_FORCE_LAYOUT,
    animated: true,
    style: { stroke: "#ff66aa", strokeWidth: 3 },
};

export const nodeId = {
    START: "START",
    END: "END",
    DRAFT: "DRAFT",
    NONE: "NONE",
};

export const nodeModes = {
    DIAGRAM_GROUP: "DIAGRAM/GROUP",
};

export const nodeForceLayoutModes = {
    MAIN: "MAIN",
    CATEGORY: "CATEGORY",
    CHILD: "CHILD",
};

export const commonOptions = {
    className: "intersection-flow",
    nodeTypes: nodeTypes,
    edgeTypes: edgeTypes,
};

export const edgeOptions = {
    defaultEdgeOptions: {
        type: DIAGRAM_TYPE_CUSTOM,
        animated: false,
    },
    elevateEdgesOnSelect: true,
};

export const proOptions = {
    proOptions: {
        account: "paid-pro",
        hideAttribution: true,
    },
};

export const interactionOptions = {
    panOnDrag: true,
    selectionOnDrag: false,
    panOnScroll: false,
    panOnScrollSpeed: 0.5,
    panOnScrollMode: "free",
    zoomOnScroll: true,
    zoomOnPinch: true,
    zoomOnDoubleClick: true,
    preventScrolling: true,
    zoomActivationKeyCode: "Meta",
    panActivationKeyCode: "Space",
    elevateNodesOnSelect: true,
    selectNodesOnDrag: false,
    connectOnClick: true,
};

export const viewPortOptions = {
    fitView: true,
    fitViewOptions: fitViewOptions,
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
};

export const reactFlowProps = {
    ...commonOptions,
    ...edgeOptions,
    ...interactionOptions,
    ...viewPortOptions,
    ...proOptions,
};

export const viewPortBackgroundGapBig = {
    id: 1,
    variant: "lines",
    color: "rgba(255, 255, 255, 0.1)",
    gap: 75,
};

export const viewPortBackgroundGapSmall = {
    id: 2,
    variant: "lines",
    color: "rgba(255, 255, 255, 0.05)",
    gap: 15,
};

export const viewPortMiniMap = {
    nodeStrokeWidth: 3,
    zoomStep: 5,
    zoomable: true,
    pannable: true,
    ariaLabel: null,
};

export const helperLinesOptions = {
    strokeStyle: "#ffffff",
    lineDash: [10, 10],
};

export const viewPortPanelToolOptions = {
    duration: DURATION_VIEW_PORT,
};

export const diagramNodeLayoutOptions = {
    algorithm: "dagre",
    direction: "LR",
    spacing: [250, 700],
};

export const diagramImageOptions = {
    width: 1024,
    height: 768,
    fileName: "doszy-diagram",
};

export const forceLayoutOptions = {
    strength: -1000,
    distance: 500,
};

export const contextualZoomStyle = {
    width: "100%",
};

export const contextualZoomNodeGroupStyle = {
    width: "100%",
    position: "absolute",
    top: "0",
    left: "0",
};

export const contextualZoomSize = {
    small: 2,
    medium: 5,
    large: 10,
    xtra: 15,
};

export const groupStyle = {
    nodeResizer: {
        borderColor: "white",
    },
    padding: 30,
    width: 400,
    height: 200,
};

export const handlePortId = {
    entry: "entry",
    right: "right",
    left: "left",
    default: "default",
    no: "no",
    yes: "yes",
    true: "true",
    false: "false",
    a: "a",
    b: "b",
    select: "select",
    checkout: "checkout",
    open: "open",
    closed: "closed",
};

export const handleConnectableMax = {
    small: 3,
    medium: 10,
    large: 20,
    xtra: 30,
};

export const arrowSVG = (color = STROKE_COLOR_DRAG) => (
    <defs>
        <marker
            id="arrowSVG"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
        >
            <path d="M 0 0 L 10 5 L 0 10 z" fill={color} />
        </marker>
    </defs>
);
