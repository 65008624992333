import generateID from "../generateID";

const INTERFACE_MESSAGE = (mode) => {
	const numberId = generateID()
	const id = `ms_${numberId}`
	switch (mode) {
		case 'UNION-MESSAGE/END':
			return {
				id: `${id}END`,
				mode: 'UNION-MESSAGE',
				messages: [
					{
						mode: 'MESSAGE',
						message: '',
						trigger: '',
					},
				],
			}
		case 'UNION-MESSAGE/MESSAGE':
			return {
				id: id,
				mode: 'UNION-MESSAGE',
				messages: [
					{
						mode: 'MESSAGE',
						message: '',
						trigger: '',
					},
				],
			}
		case 'UNION-MESSAGE/MEDIA':
			return {
				id: id,
				mode: 'UNION-MESSAGE',
				messages: [
					{
						mode: 'MEDIA',
						image: '',
						trigger: '',
					},
				],
			}
		case 'UNION-MESSAGE/MAP':
			return {
				id: id,
				mode: 'UNION-MESSAGE',
				messages: [
					{
						mode: 'MAP',
						trigger: '',
						location: {
							name: null,
							lat: Number(process.env.REACT_APP_DEFAULT_LOCATION_LAT),
							lng: Number(process.env.REACT_APP_DEFAULT_LOCATION_LNG),
						},
					},
				],
			}
		case 'UNION-MESSAGE/AUDIO':
			return {
				id: id,
				mode: 'UNION-MESSAGE',
				messages: [
					{
						mode: 'AUDIO',
						audio: '',
						trigger: '',
					},
				],
			}
		case 'UNION-MESSAGE/BUTTON':
			return {
				id: id,
				mode: 'UNION-MESSAGE',
				messages: [
					{
						mode: 'BUTTON',
						input: {
							layout: 'vertical',
							options: [
								{
									label: '',
									value: '',
									trigger: '',
									image: 'https://i.ibb.co/3TZkMH7/iconfinder-help-question-293438.png',
									id: 'default-1',
								},
							],
							type: 'ITEM',
						},
					},
				],
			}
		case 'DIAGRAM/GROUP':
			return {
				id: id,
				mode: 'DIAGRAM/GROUP',
			}
		case 'SINGLE-INPUT/NAME':
			return {
				id: id,
				mode: 'SINGLE-INPUT/NAME',
				question: 'What\'s your name?',
				answer: `My name is @ms_${numberId}_text`,
				input: {
					textinput: {
						placeholder: 'What\'s your name?',
						type: 'name',
						error_message: 'Sorry, you entered the wrong name.',
						required: true,
					},
				},
			}
		case 'SINGLE-INPUT/TEXT':
			return {
				id: id,
				mode: 'SINGLE-INPUT/TEXT',
				question: 'What are you thinking?',
				answer: `@ms_${numberId}_text`,
				input: {
					textinput: {
						placeholder: 'What are you thinking?',
						type: '-',
						error_message: 'Sorry, you entered incorrect information.',
						required: true,
					},
				},
			}
		case 'SINGLE-INPUT/EMAIL':
			return {
				id: id,
				mode: 'SINGLE-INPUT/EMAIL',
				question: 'What is your email address?',
				answer: `My email is @ms_${numberId}_text`,
				input: {
					textinput: {
						placeholder: 'What\'s your email?',
						type: 'email',
						error_message: 'Sorry, you entered the wrong email format.',
						required: true,
					},
				},
			}
		case 'SINGLE-INPUT/NUMBER':
			return {
				id: id,
				mode: 'SINGLE-INPUT/NUMBER',
				question: 'How old are you?',
				answer: `I am @ms_${numberId}_text years old.`,
				input: {
					textinput: {
						placeholder: 'Please enter a number',
						type: 'number',
						error_message: 'Sorry, you entered incorrect information.',
						required: true,
						min: '0',
					},
				},
			}
		case 'SINGLE-INPUT/PHONE':
			return {
				id: id,
				mode: 'SINGLE-INPUT/PHONE',
				question: 'What is your phone number?',
				answer: `My phone number is @ms_${numberId}_text`,
				input: {
					textinput: {
						placeholder: 'Type a phone number, please',
						type: 'phone',
						error_message: 'Sorry, you entered the wrong phone number.',
						required: true,
					},
				},
			}
		case 'SINGLE-INPUT/URL':
			return {
				id: id,
				mode: 'SINGLE-INPUT/URL',
				question: 'Please enter your website url.',
				answer: `This is my website url @ms_${numberId}_text`,
				input: {
					textinput: {
						placeholder: 'Please fill in the information',
						type: 'url',
						error_message: 'Sorry, you entered incorrect information.',
						required: true,
					},
				},
			}
		case 'QUICK-REPLY/DEFAULT':
			return {
				id: id,
				mode: 'QUICK-REPLY/DEFAULT',
				question: 'Please type your favorite choice.',
				input: {
					textinput: {
						placeholder: 'Please type your favorite choice.',
						enum: [
							{
								id: generateID(),
								label: '',
							}
						],
						error_message: 'Sorry, you entered incorrect information.',
					},
				},
			}
		case 'QUICK-REPLY/COLOR':
			return {
				id: id,
				mode: 'QUICK-REPLY/COLOR',
				question: 'Please type your favorite color.',
				input: {
					textinput: {
						placeholder: 'Please type your favorite color.',
						enum: [
							{
								id: generateID(),
								label: 'red',
								dimension: {
									height: 480,
									width: 480
								},
								extension: "image/png",
								size: 100000,
								image: 'https://www.colorcombos.com/images/colors/FF0000.png'
							},
							{
								id: generateID(),
								label: 'yellow',
								dimension: {
									height: 480,
									width: 480
								},
								extension: "image/png",
								size: 100000,
								image: 'https://www.colorcombos.com/images/colors/FFFF00.png'
							},
							{
								id: generateID(),
								label: 'blue',
								dimension: {
									height: 480,
									width: 480
								},
								extension: "image/png",
								size: 100000,
								image: 'https://www.colorcombos.com/images/colors/0000FF.png'
							},
							{
								id: generateID(),
								label: 'green',
								dimension: {
									height: 480,
									width: 480
								},
								extension: "image/png",
								size: 100000,
								image: 'https://www.colorcombos.com/images/colors/00FF00.png'
							},
							{
								id: generateID(),
								label: 'black',
								dimension: {
									height: 480,
									width: 480
								},
								extension: "image/png",
								size: 100000,
								image: 'https://www.colorcombos.com/images/colors/000000.png'
							},
							{
								id: generateID(),
								label: 'white',
								dimension: {
									height: 480,
									width: 480
								},
								extension: "image/png",
								size: 100000,
								image: 'https://www.colorcombos.com/images/colors/FFFFFF.png'
							}
						],
						error_message: 'Sorry, you entered incorrect information.',
					},
				},
			}
		case 'QUICK-REPLY/SHIRT-SIZE':
			return {
				id: id,
				mode: 'QUICK-REPLY/SHIRT-SIZE',
				question: 'Please type your fit size.',
				input: {
					textinput: {
						placeholder: 'Please type your fit size.',
						enum: [
							{
								id: generateID(),
								label: 'XS',
								dimension: {
									height: 256,
									width: 256
								},
								extension: "image/png",
								size: 100000,
								image: 'https://cdn.doszy.com/654b26c5d52e0a33175b7e8c/654dcb225ecd2451d75569a9/0ca1e63ccd02400a8e68790f906050eb_20231114_075827.png'
							},
							{
								id: generateID(),
								label: 'S',
								dimension: {
									height: 256,
									width: 256
								},
								extension: "image/png",
								size: 100000,
								image: 'https://cdn.doszy.com/654b26c5d52e0a33175b7e8c/654dcb225ecd2451d75569a9/0ca1e63ccd02400a8e68790f906050eb_20231114_075827.png'
							},
							{
								id: generateID(),
								label: 'M',
								dimension: {
									height: 256,
									width: 256
								},
								extension: "image/png",
								size: 100000,
								image: 'https://cdn.doszy.com/654b26c5d52e0a33175b7e8c/654dcb225ecd2451d75569a9/0ca1e63ccd02400a8e68790f906050eb_20231114_075827.png'
							},
							{
								id: generateID(),
								label: 'L',
								dimension: {
									height: 256,
									width: 256
								},
								extension: "image/png",
								size: 100000,
								image: 'https://cdn.doszy.com/654b26c5d52e0a33175b7e8c/654dcb225ecd2451d75569a9/0ca1e63ccd02400a8e68790f906050eb_20231114_075827.png'
							},
							{
								id: generateID(),
								label: 'XL',
								dimension: {
									height: 256,
									width: 256
								},
								extension: "image/png",
								size: 100000,
								image: 'https://cdn.doszy.com/654b26c5d52e0a33175b7e8c/654dcb225ecd2451d75569a9/0ca1e63ccd02400a8e68790f906050eb_20231114_075827.png'
							},
							{
								id: generateID(),
								label: 'XXL',
								dimension: {
									height: 256,
									width: 256
								},
								extension: "image/png",
								size: 100000,
								image: 'https://cdn.doszy.com/654b26c5d52e0a33175b7e8c/654dcb225ecd2451d75569a9/0ca1e63ccd02400a8e68790f906050eb_20231114_075827.png'
							}
						],
						error_message: 'Sorry, you entered incorrect information.',
					},
				},
			}
		case 'RATING':
			return {
				id: id,
				answer: `My satisfaction is @ms_${numberId}_rating`,
				mode: 'RATING',
				question: 'Please answer your satisfaction.',
				input: {
					description: 'Please answer your satisfaction.',
					submit: 'Confirm',
					max_stars: 5,
				},
			}
		case 'APPROVE':
			return {
				mode: 'APPROVE',
				answer: 'I agree to the terms of service.',
				title: 'Terms Of Service',
				question: 'Terms Of Service',
				id: id,
				input: {
					note: 'As a final step, please read and approve our terms of service.',
					link: 'http://example.com/',
					title: 'Terms Of Service',
					button: {
						text: 'APPROVE',
					},
				},
			}
		case 'AMOUNT-INPUT-LISTS':
			return {
				answer: 'Done.',
				mode: 'AMOUNT-INPUT-LISTS',
				question: 'Please adjust the information that applies to you.',
				id: id,
				input: {
					items: [
						{
							name: '',
							description: '',
							number: 0,
							id: 'default-1',
						},
					],
					button: {
						text: 'CONTINUE',
					},
				},
				db: {
					type: 'custom',
				},
			}
		case 'AUTO-COMPLETE':
			return {
				answer: '',
				mode: 'AUTO-COMPLETE',
				question: 'Please fill in your information.',
				id: id,
				input: {
					options: [
						{
							label: '',
							value: '',
							trigger: '',
							id: id,
						},
					],
					type: 'ITEM',
				},
				db: {
					type: 'custom',
				},
			}
		case 'CALENDAR':
			return {
				answer: '',
				mode: 'CALENDAR',
				question: 'Please choose your suitable time.',
				id: id,
				input: {
					calendar: {
						mode: 'datetime',
					},
					button: {
						text: 'CHOOSE',
					},
				},
			}
		case 'QR-CODE':
			return {
				answer: '',
				mode: 'QR-CODE',
				question: 'Please scan the information from the QRcode.',
				id: id,
			}
		case 'AUDIO-RECORDING':
			return {
				answer: 'Done, please check',
				mode: 'AUDIO-RECORDING',
				question: 'Please record for confirmation.',
				id: id,
			}
		case 'PHOTO-CAMERA':
			return {
				answer: 'Here you go.',
				mode: 'PHOTO-CAMERA',
				question: 'Please send your picture for confirmation.',
				id: id,
			}
		case 'LINE/CAMERA':
			return {
				answer: 'Here you go.',
				mode: 'LINE/CAMERA',
				question: 'Please send your picture for confirmation.',
				id: id,
				input: {
					label: 'Open Camera',
				},
				config: {
					error_message: 'Sorry, please send us the picture.',
				},
			}
		case 'OMISE':
			return {
				mode: 'OMISE',
				question: 'The system will enter the payment process.',
				answer: 'The payment is completed.',
				id: id,
				input: {
					public_key: '',
					secret_key: '',
					amount: '',
					frame_label: 'Payment',
					frame_description: 'Invoice #3847 in THB',
					currency: 'THB',
					error_message: 'An error occurred during payment. Please contact the system administrator. If it continues to repeat',
					button_label: 'Confirm',
				},
			}
		case 'GENERATE-QR-CODE-PAYMENT':
			return {
				mode: 'GENERATE-QR-CODE-PAYMENT',
				question: 'Please scan for payment.',
				id: id,
				input: {
					payment_type: 'mobile_number',
					reusable: true,
					calling_code: '66',
					mobile_number: '',
					fi_code: '004',
					bank_account: '',
					national_id_or_tax_id: '',
					e_wallet_id: '',
					amount: 0.00,
					currency_code: 'THB',
					country_code: 'TH',
				},
			}
		case 'VERIFY-TRANSACTION':
			return {
				mode: 'VERIFY-TRANSACTION',
				question: 'Please send your slip.',
				answer: 'Verify slip success.',
				id: id,
				input: {
					bank: 'KBANK',
					confirmation_message: 'Waiting for transaction confirmation.',
					error_message: 'Slip invalid, please send slip again.',
				},
			}
		case 'GOOGLE-SPREADSHEET':
			return {
				mode: 'GOOGLE-SPREADSHEET',
				id: id,
				input: {},
			}
		case 'VIDEO-RECORDING':
			return {
				answer: 'Done, please check.',
				mode: 'VIDEO-RECORDING',
				question: 'Please send your video for confirmation.',
				id: id,
			}
		case 'SIGNATURE':
			return {
				answer: 'Here is my sigature.',
				mode: 'SIGNATURE',
				question: 'Please enter your signature.',
				id: id,
				input: {
					date_format: 'MMM Do YYYY',
				},
			}
		case 'CHECKBOX':
			return {
				mode: 'CHECKBOX',
				answer: 'I chose what I like.',
				id: id,
				input: {
					enabled_image: false,
					button: {
						text: 'DONE',
					},
					options: [
						{
							label: '',
							value: '',
							image: '',
							id: 'default-1',
						},
					],
					min: null,
					max: null,
					type: 'ITEM',
				},
				question: 'Please choose what you like.',
				db: {
					type: 'custom',
				},
			}
		case 'ITEM-LISTS':
			return {
				mode: 'ITEM-LISTS',
				answer: `@ms_${numberId}_number`,
				id: id,
				input: {
					placeholder: 'Search',
					no_item_found: 'No data found',
					items: [
						{
							name: '',
							number: '',
							id: 'default-1',
						},
					],
					type: 'ITEM',
				},
				question: 'Choose what you like best.',
				db: {
					type: 'custom',
				},
			}
		case 'LOGIN':
			return {
				id: id,
				question: 'Please login.',
				answer: 'Login complete',
				mode: 'LOGIN',
				input: {
					options: {
						FACEBOOK: {
							enable: true,
						},
						GOOGLE: {
							enable: true,
						},
					},
				},
			}
		case 'WEB-CARD':
			return {
				id: id,
				mode: 'WEB-CARD',
				question: '',
				input: {
					button: {
						text: 'CHOOSE',
					},
					items: [
						{
							id: 'default-1',
						},
					],
				},
				db: {
					type: 'custom',
				},
			}
		case 'MULTI-INPUT':
			return {
				id: id,
				question: 'Please fill out all information.',
				answer: `@ms_${numberId}_return`,
				mode: 'MULTI-INPUT',
				input: {
					title: '',
					description: '',
					inputs: [
						{
							placeholder: 'What do you think?',
							type: '-',
							error_message: 'You entered the wrong information.',
							required: true,
							id: 'default-1',
						},
					],
					button: {
						text: 'TAKE IT',
					},
				},
			}
		case 'MAP':
			return {
				id: id,
				question: 'Please select a delivery location',
				answer: '',
				mode: 'MAP',
				input: {},
				place_types: ['restaurant'],
				api_key: '',
				search_header: 'Search',
				redius: 15000,
			}
		case 'BOT-PROFILE':
			return {
				mode: 'BOT-PROFILE',
				id: id,
				input: {
					bot: {
						name: '',
						avatar: '',
						rating: 5,
						role: '',
					},
				},
			}
		case 'CONTACT-INFO':
			return {
				mode: 'CONTACT-INFO',
				question: 'You can contact us from the following information.',
				id: id,
				input: {
					name: '',
					description: '',
					web: '',
					email: '',
					tel: '',
					avatar: '',
					title: '',
					location: {
						name: null,
						lat: Number(process.env.REACT_APP_DEFAULT_LOCATION_LAT),
						lng: Number(process.env.REACT_APP_DEFAULT_LOCATION_LNG),
					},
				},
			}
		case 'MAP-DIRECTION':
			return {
				id: id,
				question: 'Please select the route from the store to your location.',
				answer: '',
				mode: 'MAP-DIRECTION',
				input: {},
				place_types: ['restaurant'],
				api_key: '',
				search_header: 'Search',
				redius: 15000,
			}
		case 'CODE':
			return {
				mode: 'CODE',
				id: id,
				input: {
					data: [
						{
							field: '',
							value: '',
						},
					],
					code: `output = [{id: 123, hello: 'world'}];`,
				},
			}
		case 'YESNO':
			return {
				mode: 'YESNO',
				answer: '',
				id: id,
				input: {
					layout: 'vertical',
					enabled_image: false,
					options: [
						{
							label: 'Yes',
							value: 'yes',
							image: '',
						},
						{
							label: 'No',
							value: 'no',
							image: '',
						},
					],
				},
				question: 'Are you confirm?',
			}
		case 'SCALE':
			return {
				mode: 'SCALE',
				answer: `I like the number @ms_${numberId}_number.`,
				id: id,
				input: {
					from: 0,
					to: 5,
					left_label: 'Worst',
					right_label: 'Best',
				},
				question: 'Please select the number you like best.',
			}
		case 'PICTURE-CHOICE':
			return {
				mode: 'PICTURE-CHOICE',
				answer: `I like this picture the most @ms_${numberId}_label`,
				id: id,
				input: {
					options: [
						{
							label: '',
							link: '',
							image: '',
							id: 'default-1',
						},
					],
					select_label: 'SELECT',
				},
				question: 'Please choose the one you like best.',
				db: {
					type: 'custom',
				},
			}
		case 'AB-TEST':
			return {
				mode: 'AB-TEST',
				id: id,
				input: {
					a: 50,
					b: 50,
				},
			}
		case 'GOOD-BYE':
			return {
				mode: 'GOOD-BYE',
				id: id,
				question: 'A farewell for your users',
				input: {
					message: '',
					social: {
						enable: false,
						url: '',
					},
					start_again_button: {
						enable: false,
						text: 'Start again',
					},
					redirect: {
						enable: false,
						url: '',
						countdown: 3,
						message: 'Redirect your in {timeout}',
					},
				},
			}
		case 'LINE/SHARE-LOCATION':
			return {
				mode: 'LINE/SHARE-LOCATION',
				id: id,
				question: 'Please share your location',
				input: {
					label: 'Share location',
					error_message: 'Sorry, please select a location.',
				},
			}
		case 'LINE/IMAGE':
			return {
				mode: 'LINE/IMAGE',
				id: id,
				question: 'Please send the image',
				config: {
					error_message: 'Sorry, please send us the image.',
				},
			}
		case 'LINE/DATETIME-PICKER':
			return {
				mode: 'LINE/DATETIME-PICKER',
				id: id,
				question: 'Please select your available time',
				input: {
					label: 'Select datetime',
					error_message: 'Sorry, please select datetime.',
					initial: '',
					min: '',
					max: '',
					mode: 'datetime',
				},
			}
		case 'FILE':
			return {
				mode: 'FILE',
				id: id,
				question: 'Upload a file',
				answer: 'Uploaded',
			}
		case 'WEBHOOK':
			return {
				mode: 'WEBHOOK',
				id: id,
				question: 'Call the http request',
				input: {
					data: [
						{
							field: '',
							value: '',
							id: 'default-1',
						},
					],
					headers: [
						{
							field: '',
							value: '',
							id: 'default-1',
						},
					],
					method: 'GET',
					payload_type: 'application/json',
					url: 'https://jsonplaceholder.typicode.com/todos/1',
					attachment: '',
					basic_auth: '',
				},
			}
		case 'SMTP':
			return {
				mode: 'SMTP',
				id: id,
				question: 'Send an email',
				input: {
					host: '',
					credential: '',
					password: '',
					tls: false,
					port: 25,
					from_email: '',
					to: '',
					subject: '',
					body: '',
					from_name: '',
					attachment: '',
					reply_to: '',
					cc: '',
					bcc: '',
				},
			}
		case 'ANALYTIC':
			return {
				mode: 'ANALYTIC',
				id: id,
				question: 'Analytic user answer',
				input: {
					title: 'ANALYZING YOUR DATA',
					loading_message: 'Analyzing data',
					complete_message: 'Analysis Complete',
					conditions: [
						{
							conditions: [
								{
									type: 'or',
									point: 5,
								},
								{
									type: 'and',
									message: null,
									condition: '-',
									value: '',
								},
							],
							name: 'test',
							description: '',
							image: '',
							total: 0,
							unit: '',
						},
					],
					expression: {
						html: '@condition_1 === 5',
						text: '', // window.$(this.state.html).text()
					},
					type: 'star',
				},
			}
		case 'CONDITION':
			return {
				mode: 'CONDITION',
				id: id,
				question: 'Go to different node for different result',
				input: {
					conditions: [
						{
							type: 'or',
							point: 5,
						},
						{
							type: 'and',
							message: null,
							condition: 'Equal to',
							value: '',
						},
					],
				},
			}
		case 'JUMP-TO':
			return {
				mode: 'JUMP-TO',
				id: id,
				question: '',
				input: {},
			}
		case 'INTRO':
			return {
				mode: 'INTRO',
				id: 'INTRO',
				input: {
					pages: [
						{
							title: '',
							text: '',
							image: '',
							colors: ['#63E2FF', '#B066FE'],
							id: generateID(),
						},
					],
				},
			}
		case 'LINE/RICH-MENU':
			return {
				mode: 'LINE/RICH-MENU',
				id: id,
				input: {
					chat_bar_text: 'Click open',
					coordinates: [],
					menu: {},
					image: {
						dimension: {
							height: 615,
							width: 913
						},
						extension: "image/png",
						size: 513136,
						url: "https://cdn.doszy.com/doszy/files/4d777fd80dfee6df6b5e7426a68db11fb511b1e271322dd05783118bd7221782.png",
						virtual_demension: {
							height: 201.40625,
							width: 299
						}
					},
				},
			}
		case 'LINE/IMAGE-MAP':
			return {
				mode: 'LINE/IMAGE-MAP',
				id: id,
				input: {
					alt_text: 'Image doesn\'t exist',
					coordinates: [],
					menu: {},
					image: {
						dimension: {
							height: 1040,
							width: 1040
						},
						extension: "image/png",
						size: 997143,
						url: "https://cdn.doszy.com/doszy/files/cac8a4bff6d77455481d0585a6b0e56b14a8c64214bfce0c6ba5f8eae2f34d1a.png?w=auto",
						virtual_demension: {
							height: 299,
							width: 299
						}
					}
				},
			}
		case 'LINE/STICKER':
			return {
				mode: 'LINE/STICKER',
				id: id,
				config: {
					type: 'sticker',
					packageId: '11537',
					stickerId: '52002734',
				},
			}
		case 'LINE/BUTTON-TEMPLATE':
			return {
				mode: 'LINE/BUTTON-TEMPLATE',
				id: id,
				config: {
					thumbnailImageUrl: '',
					imageBackgroundColor: '#FFFFFF',
					size: 0,
					dimension: {
						height: 0,
						width: 0
					},
					extension: '',
					title: 'Menu',
					text: 'Please select',
					actions: [
						{
							type: 'postback',
							label: 'Buy this order',
							text: 'Buy this order',
						},
						{
							type: 'uri',
							label: 'View detail',
							uri: 'http://example.com/page/123',
						},
					],
				},
			}
		case 'LINE/CONFIRM-TEMPLATE':
			return {
				mode: 'LINE/CONFIRM-TEMPLATE',
				id: id,
				config: {
					type: 'confirm',
					text: 'Are you sure?',
					actions: [
						{
							type: 'postback',
							label: 'Yes',
							text: 'yes',
						},
						{
							type: 'postback',
							label: 'No',
							text: 'no',
						},
					],
				},
			}
		case 'LINE/CAROUSEL-TEMPLATE':
			return {
				mode: 'LINE/CAROUSEL-TEMPLATE',
				id: id,
				config: {
					type: 'carousel',
					columns: [
						{
							thumbnailImageUrl: '',
							imageBackgroundColor: '#FFFFFF',
							title: 'Menu',
							text: 'Please select',
							actions: [
								{
									type: 'postback',
									label: 'Buy this order',
									text: 'Buy this order',
								},
								{
									type: 'uri',
									label: 'View detail',
									uri: 'http://example.com/page/123',
								},
							],
						},
						{
							thumbnailImageUrl: '',
							imageBackgroundColor: '#FFFFFF',
							title: 'Menu',
							text: 'Please select',
							actions: [
								{
									type: 'postback',
									label: 'Buy this order',
									text: 'Buy this order',
								},
								{
									type: 'uri',
									label: 'View detail',
									uri: 'http://example.com/page/123',
								},
							],
						}
					]
				},
			}
		case 'LINE/IMAGE-CAROUSEL-TEMPLATE':
			return {
				mode: 'LINE/IMAGE-CAROUSEL-TEMPLATE',
				id: id,
				config: {
					type: 'image_carousel',
					columns: [
						{
							imageUrl: 'https://cdn.doszy.com/654b26c5d52e0a33175b7e8c/654dcb225ecd2451d75569a9/724ba1ea9b46452c908085cb38c4280d_20231114_061754.png',
							size: 1066,
							dimension: {
								height: 200,
								width: 200
							},
							extension: 'image/jpeg',
							action: {
								type: 'postback',
								label: 'Yes',
								text: 'yes',
							},
						},
						{
							imageUrl: 'https://cdn.doszy.com/654b26c5d52e0a33175b7e8c/654dcb225ecd2451d75569a9/724ba1ea9b46452c908085cb38c4280d_20231114_061754.png',
							size: 1066,
							dimension: {
								height: 200,
								width: 200
							},
							extension: 'image/jpeg',
							action: {
								type: 'uri',
								label: 'View detail',
								uri: 'http://example.com/page/222',
							},
						},
					],
				},
			}
		case 'PRODUCT-CAROUSEL-TEMPLATE':
			return {
				mode: 'PRODUCT-CAROUSEL-TEMPLATE',
				id: id,
				config: {
					products: [],
					add_label: 'ADD',
					remove_label: 'REMOVE',
					add_display_text: 'Added {{name}} to cart',
					remove_display_text: 'Removed {{name}} to cart'
				},
			}
		case 'FLEX/RESTAURANT':
			return {
				mode: 'FLEX/RESTAURANT',
				id: id,
				config: {
					type: 'carousel',
					contents: [
						{
							type: 'bubble',
							hero: {
								type: 'image',
								url:
									'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png',
								size: 'full',
								aspectRatio: '20:13',
								aspectMode: 'cover',
								action: {
									type: 'uri',
									uri: 'http://linecorp.com/',
								},
							},
							body: {
								type: 'box',
								layout: 'vertical',
								contents: [
									{
										type: 'text',
										text: 'Brown Cafe',
										weight: 'bold',
										size: 'xl',
									},
									{
										type: 'box',
										layout: 'baseline',
										margin: 'md',
										contents: [
											{
												type: 'icon',
												size: 'sm',
												url:
													'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
											},
											{
												type: 'icon',
												size: 'sm',
												url:
													'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
											},
											{
												type: 'icon',
												size: 'sm',
												url:
													'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
											},
											{
												type: 'icon',
												size: 'sm',
												url:
													'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
											},
											{
												type: 'icon',
												size: 'sm',
												url:
													'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png',
											},
											{
												type: 'text',
												text: '4.0',
												size: 'sm',
												color: '#999999',
												margin: 'md',
												flex: 0,
											},
										],
									},
									{
										type: 'box',
										layout: 'vertical',
										margin: 'lg',
										spacing: 'sm',
										contents: [
											{
												type: 'box',
												layout: 'baseline',
												spacing: 'sm',
												contents: [
													{
														type: 'text',
														text: 'Place',
														color: '#aaaaaa',
														size: 'sm',
														flex: 1,
													},
													{
														type: 'text',
														text:
															'Miraina Tower, 4-1-6 Shinjuku, Tokyo',
														wrap: true,
														color: '#666666',
														size: 'sm',
														flex: 5,
													},
												],
											},
											{
												type: 'box',
												layout: 'baseline',
												spacing: 'sm',
												contents: [
													{
														type: 'text',
														text: 'Time',
														color: '#aaaaaa',
														size: 'sm',
														flex: 1,
													},
													{
														type: 'text',
														text: '10:00 - 23:00',
														wrap: true,
														color: '#666666',
														size: 'sm',
														flex: 5,
													},
												],
											},
										],
									},
								],
							},
							footer: {
								type: 'box',
								layout: 'vertical',
								spacing: 'sm',
								contents: [
									{
										type: 'button',
										style: 'link',
										height: 'sm',
										action: {
											type: 'uri',
											label: 'CALL',
											uri: 'https://linecorp.com',
										},
									},
									{
										type: 'button',
										style: 'link',
										height: 'sm',
										action: {
											type: 'uri',
											label: 'WEBSITE',
											uri: 'https://linecorp.com',
										},
									},
									{
										type: "box",
										layout: "vertical",
										contents: [],
										margin: "sm"
									},
								],
								flex: 0,
							},
						},
					],
				},
			}
		case 'FLEX/HOTEL':
			return {
				mode: 'FLEX/HOTEL',
				id: id,
				config: {
					type: 'carousel',
					contents: [
						{
							type: 'bubble',
							body: {
								type: 'box',
								layout: 'vertical',
								contents: [
									{
										type: 'image',
										url:
											'https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip3.jpg',
										size: 'full',
										aspectMode: 'cover',
										aspectRatio: '1:1',
										gravity: 'center',
									},
									{
										type: "box",
										layout: "vertical",
										contents: [],
										position: "absolute",
										background: {
											type: "linearGradient",
											angle: "0deg",
											endColor: "#00000000",
											startColor: "#00000099"
										},
										width: "100%",
										height: "40%",
										offsetBottom: "0px",
										offsetStart: "0px",
										offsetEnd: "0px"
									},
									{
										type: 'box',
										layout: 'horizontal',
										contents: [
											{
												type: 'box',
												layout: 'vertical',
												contents: [
													{
														type: 'box',
														layout: 'horizontal',
														contents: [
															{
																type: 'text',
																text: 'Brown Grand Hotel',
																size: 'xl',
																color: '#ffffff',
															},
														],
													},
													{
														type: 'box',
														layout: 'baseline',
														contents: [
															{
																type: 'icon',
																url:
																	'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
															},
															{
																type: 'icon',
																url:
																	'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
															},
															{
																type: 'icon',
																url:
																	'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
															},
															{
																type: 'icon',
																url:
																	'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
															},
															{
																type: 'icon',
																url:
																	'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png',
															},
															{
																type: 'text',
																text: '4.0',
																color: '#a9a9a9',
															},
														],
														spacing: 'xs',
													},
													{
														type: 'box',
														layout: 'horizontal',
														contents: [
															{
																type: 'box',
																layout: 'baseline',
																contents: [
																	{
																		type: 'text',
																		text: '¥62,000',
																		color: '#ffffff',
																		size: 'md',
																		flex: 0,
																		align: 'end',
																	},
																	{
																		type: 'text',
																		text: '¥82,000',
																		color: '#a9a9a9',
																		decoration: 'line-through',
																		size: 'sm',
																		align: 'end',
																	},
																],
																flex: 0,
																spacing: 'lg',
															},
														],
													},
												],
												spacing: 'xs',
											},
										],
										position: 'absolute',
										offsetBottom: '0px',
										offsetStart: '0px',
										offsetEnd: '0px',
										paddingAll: '20px',
									},
								],
								paddingAll: '0px',
							},
						},
					],
				},
			}
		case 'FLEX/REAL_ESTATE':
			return {
				mode: 'FLEX/REAL_ESTATE',
				id: id,
				config: {
					type: 'carousel',
					contents: [
						{
							type: 'bubble',
							header: {
								type: 'box',
								layout: 'vertical',
								contents: [
									{
										type: 'box',
										layout: 'horizontal',
										contents: [
											{
												type: 'image',
												url:
													'https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip4.jpg',
												size: 'full',
												aspectMode: 'cover',
												aspectRatio: '150:196',
												gravity: 'center',
												flex: 1,
											},
											{
												type: 'box',
												layout: 'vertical',
												contents: [
													{
														type: 'image',
														url:
															'https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip5.jpg',
														size: 'full',
														aspectMode: 'cover',
														aspectRatio: '150:98',
														gravity: 'center',
													},
													{
														type: 'image',
														url:
															'https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip6.jpg',
														size: 'full',
														aspectMode: 'cover',
														aspectRatio: '150:98',
														gravity: 'center',
													},
												],
												flex: 1,
											},
											{
												type: 'box',
												layout: 'horizontal',
												contents: [
													{
														type: 'text',
														text: 'NEW',
														size: 'xs',
														color: '#ffffff',
														align: 'center',
														gravity: 'center',
													},
												],
												backgroundColor: '#EC3D44',
												paddingAll: '2px',
												paddingStart: '4px',
												paddingEnd: '4px',
												flex: 0,
												position: 'absolute',
												offsetStart: '18px',
												offsetTop: '18px',
												cornerRadius: '100px',
												width: '48px',
												height: '25px',
											},
										],
									},
								],
								paddingAll: '0px',
							},
							body: {
								type: 'box',
								layout: 'vertical',
								contents: [
									{
										type: 'box',
										layout: 'vertical',
										contents: [
											{
												type: 'box',
												layout: 'vertical',
												contents: [
													{
														type: 'text',
														contents: [],
														size: 'xl',
														wrap: true,
														text: 'Cony Residence',
														color: '#ffffff',
														weight: 'bold',
													},
													{
														type: 'text',
														text: '3 Bedrooms, ¥35,000',
														color: '#ffffffcc',
														size: 'sm',
													},
												],
												spacing: 'sm',
											},
											{
												type: 'box',
												layout: 'vertical',
												contents: [
													{
														type: 'box',
														layout: 'vertical',
														contents: [
															{
																type: 'text',
																contents: [],
																size: 'sm',
																wrap: true,
																margin: 'lg',
																color: '#ffffffde',
																text:
																	'Private Pool, Delivery box, Floor heating, Private Cinema',
															},
														],
													},
												],
												paddingAll: '13px',
												backgroundColor: '#ffffff1A',
												cornerRadius: '2px',
												margin: 'xl',
											},
										],
									},
								],
								paddingAll: '20px',
								backgroundColor: '#464F69',
							},
						},
					],
				},
			}
		case 'FLEX/RECEIPT':
			return {
				mode: 'FLEX/RECEIPT',
				id: id,
				config: {
					type: 'carousel',
					contents: [
						{
							type: 'bubble',
							body: {
								type: 'box',
								layout: 'vertical',
								contents: [
									{
										type: 'text',
										text: 'RECEIPT',
										weight: 'bold',
										color: '#1DB446',
										size: 'sm',
									},
									{
										type: 'text',
										text: 'Brown Store',
										weight: 'bold',
										size: 'xxl',
										margin: 'md',
									},
									{
										type: 'text',
										text: 'Miraina Tower, 4-1-6 Shinjuku, Tokyo',
										size: 'xs',
										color: '#aaaaaa',
										wrap: true,
									},
									{
										type: 'separator',
										margin: 'xxl',
									},
									{
										type: 'box',
										layout: 'vertical',
										margin: 'xxl',
										spacing: 'sm',
										contents: [
											{
												type: 'box',
												layout: 'horizontal',
												contents: [
													{
														type: 'text',
														text: 'Energy Drink',
														size: 'sm',
														color: '#555555',
														flex: 0,
													},
													{
														type: 'text',
														text: '$2.99',
														size: 'sm',
														color: '#111111',
														align: 'end',
													},
												],
											},
											{
												type: 'box',
												layout: 'horizontal',
												contents: [
													{
														type: 'text',
														text: 'Chewing Gum',
														size: 'sm',
														color: '#555555',
														flex: 0,
													},
													{
														type: 'text',
														text: '$0.99',
														size: 'sm',
														color: '#111111',
														align: 'end',
													},
												],
											},
											{
												type: 'box',
												layout: 'horizontal',
												contents: [
													{
														type: 'text',
														text: 'Bottled Water',
														size: 'sm',
														color: '#555555',
														flex: 0,
													},
													{
														type: 'text',
														text: '$3.33',
														size: 'sm',
														color: '#111111',
														align: 'end',
													},
												],
											},
											{
												type: 'separator',
												margin: 'xxl',
											},
											{
												type: 'box',
												layout: 'horizontal',
												margin: 'xxl',
												contents: [
													{
														type: 'text',
														text: 'ITEMS',
														size: 'sm',
														color: '#555555',
													},
													{
														type: 'text',
														text: '3',
														size: 'sm',
														color: '#111111',
														align: 'end',
													},
												],
											},
											{
												type: 'box',
												layout: 'horizontal',
												contents: [
													{
														type: 'text',
														text: 'TOTAL',
														size: 'sm',
														color: '#555555',
													},
													{
														type: 'text',
														text: '$7.31',
														size: 'sm',
														color: '#111111',
														align: 'end',
													},
												],
											},
											{
												type: 'box',
												layout: 'horizontal',
												contents: [
													{
														type: 'text',
														text: 'CASH',
														size: 'sm',
														color: '#555555',
													},
													{
														type: 'text',
														text: '$8.0',
														size: 'sm',
														color: '#111111',
														align: 'end',
													},
												],
											},
											{
												type: 'box',
												layout: 'horizontal',
												contents: [
													{
														type: 'text',
														text: 'CHANGE',
														size: 'sm',
														color: '#555555',
													},
													{
														type: 'text',
														text: '$0.69',
														size: 'sm',
														color: '#111111',
														align: 'end',
													},
												],
											},
										],
									},
									{
										type: 'separator',
										margin: 'xxl',
									},
									{
										type: 'box',
										layout: 'horizontal',
										margin: 'md',
										contents: [
											{
												type: 'text',
												text: 'PAYMENT ID',
												size: 'xs',
												color: '#aaaaaa',
												flex: 0,
											},
											{
												type: 'text',
												text: '#743289384279',
												color: '#aaaaaa',
												size: 'xs',
												align: 'end',
											},
										],
									},
								],
							},
							styles: {
								footer: {
									separator: true,
								},
							},
						},
					],
				},
			}
		case 'FLEX/TICKET':
			return {
				mode: 'FLEX/TICKET',
				id: id,
				config: {
					type: 'carousel',
					contents: [
						{
							type: 'bubble',
							hero: {
								type: 'image',
								url:
									'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_3_movie.png',
								size: 'full',
								aspectRatio: '20:13',
								aspectMode: 'cover',
								action: {
									type: 'uri',
									uri: 'http://linecorp.com/',
								},
							},
							body: {
								type: 'box',
								layout: 'vertical',
								spacing: 'md',
								contents: [
									{
										type: 'text',
										text: 'BROWN\'S ADVENTURE\nIN MOVIE',
										wrap: true,
										weight: 'bold',
										gravity: 'center',
										size: 'xl',
									},
									{
										type: 'box',
										layout: 'baseline',
										margin: 'md',
										contents: [
											{
												type: 'icon',
												size: 'sm',
												url:
													'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
											},
											{
												type: 'icon',
												size: 'sm',
												url:
													'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
											},
											{
												type: 'icon',
												size: 'sm',
												url:
													'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
											},
											{
												type: 'icon',
												size: 'sm',
												url:
													'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
											},
											{
												type: 'icon',
												size: 'sm',
												url:
													'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png',
											},
											{
												type: 'text',
												text: '4.0',
												size: 'sm',
												color: '#999999',
												margin: 'md',
												flex: 0,
											},
										],
									},
									{
										type: 'box',
										layout: 'vertical',
										margin: 'lg',
										spacing: 'sm',
										contents: [
											{
												type: 'box',
												layout: 'baseline',
												spacing: 'sm',
												contents: [
													{
														type: 'text',
														text: 'Date',
														color: '#aaaaaa',
														size: 'sm',
														flex: 1,
													},
													{
														type: 'text',
														text: 'Monday 25, 9:00PM',
														wrap: true,
														size: 'sm',
														color: '#666666',
														flex: 4,
													},
												],
											},
											{
												type: 'box',
												layout: 'baseline',
												spacing: 'sm',
												contents: [
													{
														type: 'text',
														text: 'Place',
														color: '#aaaaaa',
														size: 'sm',
														flex: 1,
													},
													{
														type: 'text',
														text: '7 Floor, No.3',
														wrap: true,
														color: '#666666',
														size: 'sm',
														flex: 4,
													},
												],
											},
											{
												type: 'box',
												layout: 'baseline',
												spacing: 'sm',
												contents: [
													{
														type: 'text',
														text: 'Seats',
														color: '#aaaaaa',
														size: 'sm',
														flex: 1,
													},
													{
														type: 'text',
														text: 'C Row, 18 Seat',
														wrap: true,
														color: '#666666',
														size: 'sm',
														flex: 4,
													},
												],
											},
										],
									},
									{
										type: 'box',
										layout: 'vertical',
										margin: 'xxl',
										contents: [
											{
												type: "image",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/linecorp_code_withborder.png",
												aspectMode: "cover",
												size: "xl",
												margin: "md"
											},
											{
												type: "text",
												text: "You can enter the theater by using this code instead of a ticket",
												color: "#aaaaaa",
												wrap: true,
												margin: "xxl",
												size: "xs"
											},
										],
									},
								],
							},
						},
					],
				},
			}
		case 'FLEX/APPAREL':
			return {
				mode: 'FLEX/APPAREL',
				id: id,
				config: {
					type: "carousel",
					contents: [
						{
							type: "bubble",
							body: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "image",
										url: "https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip1.jpg",
										size: "full",
										aspectMode: "cover",
										aspectRatio: "2:3",
										gravity: "top"
									},
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "text",
														text: "Brown's T-shirts",
														size: "xl",
														color: "#ffffff",
														weight: "bold"
													}
												]
											},
											{
												type: "box",
												layout: "baseline",
												contents: [
													{
														type: "text",
														text: "¥35,800",
														color: "#ebebeb",
														size: "sm",
														flex: 0
													},
													{
														type: "text",
														text: "¥75,000",
														color: "#ffffffcc",
														decoration: "line-through",
														gravity: "bottom",
														flex: 0,
														size: "sm"
													}
												],
												spacing: "lg"
											},
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "filler"
													},
													{
														type: "box",
														layout: "baseline",
														contents: [
															{
																type: "filler"
															},
															{
																type: "icon",
																url: "https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip14.png"
															},
															{
																type: "text",
																text: "Add to cart",
																color: "#ffffff",
																flex: 0,
																offsetTop: "-2px"
															},
															{
																type: "filler"
															}
														],
														spacing: "sm"
													},
													{
														type: "filler"
													}
												],
												borderWidth: "1px",
												cornerRadius: "4px",
												spacing: "sm",
												borderColor: "#ffffff",
												margin: "xxl",
												height: "40px"
											}
										],
										position: "absolute",
										offsetBottom: "0px",
										offsetStart: "0px",
										offsetEnd: "0px",
										backgroundColor: "#03303Acc",
										paddingAll: "20px",
										paddingTop: "18px"
									},
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "text",
												text: "SALE",
												color: "#ffffff",
												align: "center",
												size: "xs",
												offsetTop: "3px"
											}
										],
										position: "absolute",
										cornerRadius: "20px",
										offsetTop: "18px",
										backgroundColor: "#ff334b",
										offsetStart: "18px",
										height: "25px",
										width: "53px"
									}
								],
								paddingAll: "0px"
							}
						},
						{
							type: "bubble",
							body: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "image",
										url: "https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip2.jpg",
										size: "full",
										aspectMode: "cover",
										aspectRatio: "2:3",
										gravity: "top"
									},
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "text",
														text: "Cony's T-shirts",
														size: "xl",
														color: "#ffffff",
														weight: "bold"
													}
												]
											},
											{
												type: "box",
												layout: "baseline",
												contents: [
													{
														type: "text",
														text: "¥35,800",
														color: "#ebebeb",
														size: "sm",
														flex: 0
													},
													{
														type: "text",
														text: "¥75,000",
														color: "#ffffffcc",
														decoration: "line-through",
														gravity: "bottom",
														flex: 0,
														size: "sm"
													}
												],
												spacing: "lg"
											},
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "filler"
													},
													{
														type: "box",
														layout: "baseline",
														contents: [
															{
																type: "filler"
															},
															{
																type: "icon",
																url: "https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip14.png"
															},
															{
																type: "text",
																text: "Add to cart",
																color: "#ffffff",
																flex: 0,
																offsetTop: "-2px"
															},
															{
																type: "filler"
															}
														],
														spacing: "sm"
													},
													{
														type: "filler"
													}
												],
												borderWidth: "1px",
												cornerRadius: "4px",
												spacing: "sm",
												borderColor: "#ffffff",
												margin: "xxl",
												height: "40px"
											}
										],
										position: "absolute",
										offsetBottom: "0px",
										offsetStart: "0px",
										offsetEnd: "0px",
										backgroundColor: "#9C8E7Ecc",
										paddingAll: "20px",
										paddingTop: "18px"
									},
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "text",
												text: "SALE",
												color: "#ffffff",
												align: "center",
												size: "xs",
												offsetTop: "3px"
											}
										],
										position: "absolute",
										cornerRadius: "20px",
										offsetTop: "18px",
										backgroundColor: "#ff334b",
										offsetStart: "18px",
										height: "25px",
										width: "53px"
									}
								],
								paddingAll: "0px"
							}
						}
					]
				}
			}
		case 'FLEX/LOCAL_SEARCH':
			return {
				mode: 'FLEX/LOCAL_SEARCH',
				id: id,
				config: {
					type: "carousel",
					contents: [
						{
							type: "bubble",
							size: "micro",
							hero: {
								type: "image",
								url: "https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip10.jpg",
								size: "full",
								aspectMode: "cover",
								aspectRatio: "320:213"
							},
							body: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "text",
										text: "Brown Cafe",
										weight: "bold",
										size: "sm",
										wrap: true
									},
									{
										type: "box",
										layout: "baseline",
										contents: [
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png"
											},
											{
												type: "text",
												text: "4.0",
												size: "xs",
												color: "#8c8c8c",
												margin: "md",
												flex: 0
											}
										]
									},
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "box",
												layout: "baseline",
												spacing: "sm",
												contents: [
													{
														type: "text",
														text: "東京旅行",
														wrap: true,
														color: "#8c8c8c",
														size: "xs",
														flex: 5
													}
												]
											}
										]
									}
								],
								spacing: "sm",
								paddingAll: "13px"
							}
						},
						{
							type: "bubble",
							size: "micro",
							hero: {
								type: "image",
								url: "https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip11.jpg",
								size: "full",
								aspectMode: "cover",
								aspectRatio: "320:213"
							},
							body: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "text",
										text: "Brow&Cony's Restaurant",
										weight: "bold",
										size: "sm",
										wrap: true
									},
									{
										type: "box",
										layout: "baseline",
										contents: [
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png"
											},
											{
												type: "text",
												text: "4.0",
												size: "sm",
												color: "#8c8c8c",
												margin: "md",
												flex: 0
											}
										]
									},
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "box",
												layout: "baseline",
												spacing: "sm",
												contents: [
													{
														type: "text",
														text: "東京旅行",
														wrap: true,
														color: "#8c8c8c",
														size: "xs",
														flex: 5
													}
												]
											}
										]
									}
								],
								spacing: "sm",
								paddingAll: "13px"
							}
						},
						{
							type: "bubble",
							size: "micro",
							hero: {
								type: "image",
								url: "https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip12.jpg",
								size: "full",
								aspectMode: "cover",
								aspectRatio: "320:213"
							},
							body: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "text",
										text: "Tata",
										weight: "bold",
										size: "sm"
									},
									{
										type: "box",
										layout: "baseline",
										contents: [
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png"
											},
											{
												type: "icon",
												size: "xs",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png"
											},
											{
												type: "text",
												text: "4.0",
												size: "sm",
												color: "#8c8c8c",
												margin: "md",
												flex: 0
											}
										]
									},
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "box",
												layout: "baseline",
												spacing: "sm",
												contents: [
													{
														type: "text",
														text: "東京旅行",
														wrap: true,
														color: "#8c8c8c",
														size: "xs",
														flex: 5
													}
												]
											}
										]
									}
								],
								spacing: "sm",
								paddingAll: "13px"
							}
						}
					]
				}
			}
		case 'FLEX/SOCIAL':
			return {
				mode: 'FLEX/SOCIAL',
				id: id,
				config: {
					type: "carousel",
					contents: [
						{
							type: "bubble",
							body: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "box",
										layout: "horizontal",
										contents: [
											{
												type: "image",
												url: "https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip7.jpg",
												size: "5xl",
												aspectMode: "cover",
												aspectRatio: "150:196",
												gravity: "center",
												flex: 1
											},
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "image",
														url: "https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip8.jpg",
														size: "full",
														aspectMode: "cover",
														aspectRatio: "150:98",
														gravity: "center"
													},
													{
														type: "image",
														url: "https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip9.jpg",
														size: "full",
														aspectMode: "cover",
														aspectRatio: "150:98",
														gravity: "center"
													}
												],
												flex: 1
											}
										]
									},
									{
										type: "box",
										layout: "horizontal",
										contents: [
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "image",
														url: "https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip13.jpg",
														aspectMode: "cover",
														size: "full"
													}
												],
												cornerRadius: "100px",
												width: "72px",
												height: "72px"
											},
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "text",
														contents: [
															{
																type: "span",
																text: "brown_05",
																weight: "bold",
																color: "#000000"
															},
															{
																type: "span",
																text: "     "
															},
															{
																type: "span",
																text: "I went to the Brown&Cony cafe in Tokyo and took a picture"
															}
														],
														size: "sm",
														wrap: true
													},
													{
														type: "box",
														layout: "baseline",
														contents: [
															{
																type: "text",
																text: "1,140,753 Like",
																size: "sm",
																color: "#bcbcbc"
															}
														],
														spacing: "sm",
														margin: "md"
													}
												]
											}
										],
										spacing: "xl",
										paddingAll: "20px"
									}
								],
								paddingAll: "0px"
							}
						}
					]
				}
			}
		case 'FLEX/TODO_APP':
			return {
				mode: 'FLEX/TODO_APP',
				id: id,
				config: {
					type: "carousel",
					contents: [
						{
							type: "bubble",
							size: "nano",
							header: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "text",
										text: "In Progress",
										color: "#ffffff",
										align: "start",
										size: "md",
										gravity: "center"
									},
									{
										type: "text",
										text: "70%",
										color: "#ffffff",
										align: "start",
										size: "xs",
										gravity: "center",
										margin: "lg"
									},
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "filler"
													}
												],
												width: "70%",
												backgroundColor: "#0D8186",
												height: "6px"
											}
										],
										backgroundColor: "#9FD8E36E",
										height: "6px",
										margin: "sm"
									}
								],
								backgroundColor: "#27ACB2",
								paddingTop: "19px",
								paddingAll: "12px",
								paddingBottom: "16px"
							},
							body: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "box",
										layout: "horizontal",
										contents: [
											{
												type: "text",
												text: "Buy milk and lettuce before class",
												color: "#8C8C8C",
												size: "sm",
												wrap: true
											}
										],
										flex: 1
									}
								],
								spacing: "md",
								paddingAll: "12px"
							},
							styles: {
								footer: {
									separator: false
								}
							}
						},
						{
							type: "bubble",
							size: "nano",
							header: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "text",
										text: "Pending",
										color: "#ffffff",
										align: "start",
										size: "md",
										gravity: "center"
									},
									{
										type: "text",
										text: "30%",
										color: "#ffffff",
										align: "start",
										size: "xs",
										gravity: "center",
										margin: "lg"
									},
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "filler"
													}
												],
												width: "30%",
												backgroundColor: "#DE5658",
												height: "6px"
											}
										],
										backgroundColor: "#FAD2A76E",
										height: "6px",
										margin: "sm"
									}
								],
								backgroundColor: "#FF6B6E",
								paddingTop: "19px",
								paddingAll: "12px",
								paddingBottom: "16px"
							},
							body: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "box",
										layout: "horizontal",
										contents: [
											{
												type: "text",
												text: "Wash my car",
												color: "#8C8C8C",
												size: "sm",
												wrap: true
											}
										],
										flex: 1
									}
								],
								spacing: "md",
								paddingAll: "12px"
							},
							styles: {
								footer: {
									separator: false
								}
							}
						},
						{
							type: "bubble",
							size: "nano",
							header: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "text",
										text: "In Progress",
										color: "#ffffff",
										align: "start",
										size: "md",
										gravity: "center"
									},
									{
										type: "text",
										text: "100%",
										color: "#ffffff",
										align: "start",
										size: "xs",
										gravity: "center",
										margin: "lg"
									},
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "filler"
													}
												],
												width: "100%",
												backgroundColor: "#7D51E4",
												height: "6px"
											}
										],
										backgroundColor: "#9FD8E36E",
										height: "6px",
										margin: "sm"
									}
								],
								backgroundColor: "#A17DF5",
								paddingTop: "19px",
								paddingAll: "12px",
								paddingBottom: "16px"
							},
							body: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "box",
										layout: "horizontal",
										contents: [
											{
												type: "text",
												text: "Buy milk and lettuce before class",
												color: "#8C8C8C",
												size: "sm",
												wrap: true
											}
										],
										flex: 1
									}
								],
								spacing: "md",
								paddingAll: "12px"
							},
							styles: {
								footer: {
									separator: false
								}
							}
						}
					]
				}
			}
		case 'FLEX/TRANSIT':
			return {
				mode: 'FLEX/TRANSIT',
				id: id,
				config: {
					type: 'carousel',
					contents: [
						{
							type: "bubble",
							size: "mega",
							header: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "text",
												text: "FROM",
												color: "#ffffff66",
												size: "sm"
											},
											{
												type: "text",
												text: "Akihabara",
												color: "#ffffff",
												size: "xl",
												flex: 4,
												weight: "bold"
											}
										]
									},
									{
										type: "box",
										layout: "vertical",
										contents: [
											{
												type: "text",
												text: "TO",
												color: "#ffffff66",
												size: "sm"
											},
											{
												type: "text",
												text: "Shinjuku",
												color: "#ffffff",
												size: "xl",
												flex: 4,
												weight: "bold"
											}
										]
									}
								],
								paddingAll: "20px",
								backgroundColor: "#0367D3",
								spacing: "md",
								height: "154px",
								paddingTop: "22px"
							},
							body: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "text",
										text: "Total: 1 hour",
										color: "#b7b7b7",
										size: "xs"
									},
									{
										type: "box",
										layout: "horizontal",
										contents: [
											{
												type: "text",
												text: "20:30",
												size: "sm",
												gravity: "center"
											},
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "filler"
													},
													{
														type: "box",
														layout: "vertical",
														contents: [],
														cornerRadius: "30px",
														height: "12px",
														width: "12px",
														borderColor: "#EF454D",
														borderWidth: "2px"
													},
													{
														type: "filler"
													}
												],
												flex: 0
											},
											{
												type: "text",
												text: "Akihabara",
												gravity: "center",
												flex: 4,
												size: "sm"
											}
										],
										spacing: "lg",
										cornerRadius: "30px",
										margin: "xl"
									},
									{
										type: "box",
										layout: "horizontal",
										contents: [
											{
												type: "box",
												layout: "baseline",
												contents: [
													{
														type: "filler"
													}
												],
												flex: 1
											},
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "box",
														layout: "horizontal",
														contents: [
															{
																type: "filler"
															},
															{
																type: "box",
																layout: "vertical",
																contents: [],
																width: "2px",
																backgroundColor: "#B7B7B7"
															},
															{
																type: "filler"
															}
														],
														flex: 1
													}
												],
												width: "12px"
											},
											{
												type: "text",
												text: "Walk 4min",
												gravity: "center",
												flex: 4,
												size: "xs",
												color: "#8c8c8c"
											}
										],
										spacing: "lg",
										height: "64px"
									},
									{
										type: "box",
										layout: "horizontal",
										contents: [
											{
												type: "box",
												layout: "horizontal",
												contents: [
													{
														type: "text",
														text: "20:34",
														gravity: "center",
														size: "sm"
													}
												],
												flex: 1
											},
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "filler"
													},
													{
														type: "box",
														layout: "vertical",
														contents: [],
														cornerRadius: "30px",
														width: "12px",
														height: "12px",
														borderWidth: "2px",
														borderColor: "#6486E3"
													},
													{
														type: "filler"
													}
												],
												flex: 0
											},
											{
												type: "text",
												text: "Ochanomizu",
												gravity: "center",
												flex: 4,
												size: "sm"
											}
										],
										spacing: "lg",
										cornerRadius: "30px"
									},
									{
										type: "box",
										layout: "horizontal",
										contents: [
											{
												type: "box",
												layout: "baseline",
												contents: [
													{
														type: "filler"
													}
												],
												flex: 1
											},
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "box",
														layout: "horizontal",
														contents: [
															{
																type: "filler"
															},
															{
																type: "box",
																layout: "vertical",
																contents: [],
																width: "2px",
																backgroundColor: "#6486E3"
															},
															{
																type: "filler"
															}
														],
														flex: 1
													}
												],
												width: "12px"
											},
											{
												type: "text",
												text: "Metro 1hr",
												gravity: "center",
												flex: 4,
												size: "xs",
												color: "#8c8c8c"
											}
										],
										spacing: "lg",
										height: "64px"
									},
									{
										type: "box",
										layout: "horizontal",
										contents: [
											{
												type: "text",
												text: "20:40",
												gravity: "center",
												size: "sm"
											},
											{
												type: "box",
												layout: "vertical",
												contents: [
													{
														type: "filler"
													},
													{
														type: "box",
														layout: "vertical",
														contents: [],
														cornerRadius: "30px",
														width: "12px",
														height: "12px",
														borderColor: "#6486E3",
														borderWidth: "2px"
													},
													{
														type: "filler"
													}
												],
												flex: 0
											},
											{
												type: "text",
												text: "Shinjuku",
												gravity: "center",
												flex: 4,
												size: "sm"
											}
										],
										spacing: "lg",
										cornerRadius: "30px"
									}
								]
							}
						}
					]
				}
			}
		case 'FLEX/SHOPPING':
			return {
				mode: 'FLEX/SHOPPING',
				id: id,
				config: {
					type: "carousel",
					contents: [
						{
							type: "bubble",
							hero: {
								type: "image",
								size: "full",
								aspectRatio: "20:13",
								aspectMode: "cover",
								url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_5_carousel.png"
							},
							body: {
								type: "box",
								layout: "vertical",
								spacing: "sm",
								contents: [
									{
										type: "text",
										text: "Arm Chair, White",
										wrap: true,
										weight: "bold",
										size: "xl"
									},
									{
										type: "box",
										layout: "baseline",
										contents: [
											{
												type: "text",
												text: "$49",
												wrap: true,
												weight: "bold",
												size: "xl",
												flex: 0
											},
											{
												type: "text",
												text: ".99",
												wrap: true,
												weight: "bold",
												size: "sm",
												flex: 0
											}
										]
									}
								]
							},
							footer: {
								type: "box",
								layout: "vertical",
								spacing: "sm",
								contents: [
									{
										type: "button",
										style: "primary",
										action: {
											type: "uri",
											label: "Add to Cart",
											uri: "https://linecorp.com"
										}
									},
									{
										type: "button",
										action: {
											type: "uri",
											label: "Add to wishlist",
											uri: "https://linecorp.com"
										}
									}
								]
							}
						},
						{
							type: "bubble",
							hero: {
								type: "image",
								size: "full",
								aspectRatio: "20:13",
								aspectMode: "cover",
								url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_6_carousel.png"
							},
							body: {
								type: "box",
								layout: "vertical",
								spacing: "sm",
								contents: [
									{
										type: "text",
										text: "Metal Desk Lamp",
										wrap: true,
										weight: "bold",
										size: "xl"
									},
									{
										type: "box",
										layout: "baseline",
										flex: 0,
										contents: [
											{
												type: "text",
												text: "$11",
												wrap: true,
												weight: "bold",
												size: "xl",
												flex: 0
											},
											{
												type: "text",
												text: ".99",
												wrap: true,
												weight: "bold",
												size: "sm",
												flex: 0
											}
										]
									},
									{
										type: "text",
										text: "Temporarily out of stock",
										wrap: true,
										size: "xxs",
										margin: "md",
										color: "#ff5551",
										flex: 0
									}
								]
							},
							footer: {
								type: "box",
								layout: "vertical",
								spacing: "sm",
								contents: [
									{
										type: "button",
										flex: 2,
										style: "primary",
										color: "#aaaaaa",
										action: {
											type: "uri",
											label: "Add to Cart",
											uri: "https://linecorp.com"
										}
									},
									{
										type: "button",
										action: {
											type: "uri",
											label: "Add to wish list",
											uri: "https://linecorp.com"
										}
									}
								]
							}
						},
						{
							type: "bubble",
							body: {
								type: "box",
								layout: "vertical",
								spacing: "sm",
								contents: [
									{
										type: "button",
										flex: 1,
										gravity: "center",
										action: {
											type: "uri",
											label: "See more",
											uri: "https://linecorp.com"
										}
									}
								]
							}
						}
					]
				}
			}
		case 'FLEX/MENU':
			return {
				mode: 'FLEX/MENU',
				id: id,
				config: {
					type: 'carousel',
					contents: [
						{
							type: "bubble",
							hero: {
								type: "image",
								url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_2_restaurant.png",
								size: "full",
								aspectRatio: "20:13",
								aspectMode: "cover",
								action: {
									type: "uri",
									uri: "https://linecorp.com"
								}
							},
							body: {
								type: "box",
								layout: "vertical",
								spacing: "md",
								action: {
									type: "uri",
									uri: "https://linecorp.com"
								},
								contents: [
									{
										type: "text",
										text: "Brown's Burger",
										size: "xl",
										weight: "bold"
									},
									{
										type: "box",
										layout: "vertical",
										spacing: "sm",
										contents: [
											{
												type: "box",
												layout: "baseline",
												contents: [
													{
														type: "icon",
														url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/restaurant_regular_32.png"
													},
													{
														type: "text",
														text: "$10.5",
														weight: "bold",
														margin: "sm",
														flex: 0
													},
													{
														type: "text",
														text: "400kcl",
														size: "sm",
														align: "end",
														color: "#aaaaaa"
													}
												]
											},
											{
												type: "box",
												layout: "baseline",
												contents: [
													{
														type: "icon",
														url: "https://scdn.line-apps.com/n/channel_devcenter/img/fx/restaurant_large_32.png"
													},
													{
														type: "text",
														text: "$15.5",
														weight: "bold",
														margin: "sm",
														flex: 0
													},
													{
														type: "text",
														text: "550kcl",
														size: "sm",
														align: "end",
														color: "#aaaaaa"
													}
												]
											}
										]
									},
									{
										type: "text",
										text: "Sauce, Onions, Pickles, Lettuce & Cheese",
										wrap: true,
										color: "#aaaaaa",
										size: "xxs"
									}
								]
							},
							footer: {
								type: "box",
								layout: "vertical",
								contents: [
									{
										type: "button",
										style: "primary",
										color: "#905c44",
										margin: "xxl",
										action: {
											type: "uri",
											label: "Add to Cart",
											uri: "https://linecorp.com"
										}
									}
								]
							}
						}
					]
				}
			}
		case 'FLEX/CUSTOM':
			return {
				mode: 'FLEX/CUSTOM',
				id: id,
				config: {
					type: 'carousel',
					contents: [
						{
							type: 'bubble',
							body: {
								type: 'box',
								layout: 'vertical',
								contents: [
									{
										type: 'text',
										text: 'hello, world',
									},
								],
							},
						},
						{
							type: 'bubble',
							body: {
								type: 'box',
								layout: 'vertical',
								contents: [
									{
										type: 'text',
										text: 'hello, world',
									},
								],
							},
						},
					],
				},
			}
		case 'ADD-TO-CART':
			return {
				mode: 'ADD-TO-CART',
				id: id,
				config: {
					product_id: '',
				},
			}
		case 'REMOVE-FROM-CART':
			return {
				mode: 'REMOVE-FROM-CART',
				id: id,
				config: {
					product_id: '',
				},
			}
		case 'CLEAR-CART':
			return {
				mode: 'CLEAR-CART',
				id: id,
				config: {},
			}
		case 'HUMAN-TAKEOVER':
			return {
				mode: 'HUMAN-TAKEOVER',
				id: id,
				config: {},
			}
		case 'BUSINESS-HOURS':
			return {
				mode: 'BUSINESS-HOURS',
				id: id,
				input: {
					time_zone: 'Asia/Bangkok',
					business_hours: [
						{
							day: "monday",
							is_enabled: false,
							time: [
								{
									from: "",
									to: ""
								}
							]
						},
						{
							day: "tuesday",
							is_enabled: false,
							time: [
								{
									from: "",
									to: ""
								}
							]
						},
						{
							day: "wednesday",
							is_enabled: false,
							time: [
								{
									from: "",
									to: ""
								}
							]
						},
						{
							day: "thursday",
							is_enabled: false,
							time: [
								{
									from: "",
									to: ""
								}
							]
						},
						{
							day: "friday",
							is_enabled: false,
							time: [
								{
									from: "",
									to: ""
								}
							]
						},
						{
							day: "saturday",
							is_enabled: false,
							time: [
								{
									from: "",
									to: ""
								}
							]
						},
						{
							day: "sunday",
							is_enabled: false,
							time: [
								{
									from: "",
									to: ""
								}
							]
						},
					],
					closed_days: [],
					special_days: [],
				}
			}
		case 'ORDERS-TEMPLATE':
			return {
				mode: 'ORDERS-TEMPLATE',
				id: id,
				config: {
					checkout_label: 'Check Out'
				},
			}
		case 'MODULE-RESPONSE':
			return {
				mode: 'MODULE-RESPONSE',
				id: id,
				config: {
					title: '',
					description: '',
					columns: [
						{
							id: generateID(),
							name: 'Full Name',
							value: '',
						},
					],
				},
			}
		default:
			return {}
	}
}

export default INTERFACE_MESSAGE
