const inputMode = {
	'GROBAL': {
		price: {
			label: 'Total Price',
			value: 2,
			type: 'number',
			format: 'number',
			onlyForPicker: true,
		},
		orders: {
			label: 'Orders',
			value: {
				name: 'Adidas ultra boost',
				quantity: 2,
				price: 20
			},
			type: 'object',
			format: 'orders',
			onlyForPicker: false,
		}
	},
	'AMOUNT-INPUT-LISTS': {
		answer: {
			label: 'Set of number',
			value: [10, 12, 5],
			type: 'array',
			format: 'array',
			onlyForPicker: false,
		},
	},
	APPROVE: {
		status: {
			label: 'Status',
			value: true,
			type: 'boolean',
			format: 'boolean',
			onlyForPicker: false,
		},
		text: {
			label: 'Text',
			value: 'approve',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
	},
	'AUTO-COMPLETE': {
		label: {
			column: 'Answer', // only using for response table
			label: 'Name',
			value: 'Superman',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
		value: {
			label: 'Value',
			value: 'spm-001',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
	},
	CODE: {
		output: {
			label: 'Output',
			value: '',
			type: 'object',
			format: 'object',
			onlyForPicker: false,
		},
	},
	CALENDAR: {
		default: {
			label: 'Default',
			value: 'Sat Dec 15 2018 17:15:46 GMT+0700 (+07)',
			type: 'datetime',
			format: 'datetime',
			onlyForPicker: true,
		},
		datetime: {
			label: 'Date time',
			value: 'Sunday, February 14th 2010, 3:25:50 pm',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	CHECKBOX: {
		selected_number: {
			label: 'Number of selected options',
			value: 2,
			type: 'number',
			format: 'number',
			onlyForPicker: true,
		},
		selected_value: {
			label: 'Selected values',
			value: ['CM', 'BKK'],
			type: 'array',
			format: 'array',
			onlyForPicker: true,
		},
		selected_label: {
			column: 'Answers', // only using for response table
			label: 'Selected labels',
			value: ['CHIANG MAI', 'BANGKOK'],
			type: 'array',
			format: 'array',
			onlyForPicker: false,
		},
	},
	MAP: {
		latitude: {
			label: 'Latitude',
			value: '13.7244416',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
		longitude: {
			label: 'Longitude',
			value: '100.3529157',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
		coordinate: {
			label: 'Coordinate',
			value: '13.7244416,100.3529157',
			type: 'string',
			format: 'location',
			onlyForPicker: false,
		},
		location_name: {
			label: 'Location name',
			value: 'Bangkok',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	'CIRCLE-CARD': {
		// no need
	},
	'ITEM-LISTS': {
		name: {
			label: 'Name',
			value: 'Pizza',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
		price: {
			label: 'Price',
			value: 200,
			type: 'number',
			format: 'number',
			onlyForPicker: false,
		},
	},
	'MAP-DIRECTION': {
		source_latitude: {
			label: 'Source latitude',
			value: '13.7244416',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
		source_longitude: {
			label: 'Source longitude',
			value: '100.3529157',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
		source_coordinate: {
			label: 'Source coordinate ',
			value: '13.7244416,100.3529157',
			type: 'string',
			format: 'location',
			onlyForPicker: false,
		},
		source_location_name: {
			label: 'Source location name',
			value: 'Bangkok',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
		destination_latitude: {
			label: 'Destination latitude',
			value: '18.6916446',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
		destination_longitude: {
			label: 'Destination longitude ',
			value: '97.6657312',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
		destination_coordinate: {
			label: 'Destination coordinate',
			value: '18.6916446,97.6657312',
			type: 'string',
			format: 'location',
			onlyForPicker: false,
		},
		destination_location_name: {
			label: 'Destination location name',
			value: 'Chiang Mai',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
		distance: {
			label: 'Distance',
			value: '13 km',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	'MULTI-INPUT': {
		value: {
			label: 'Answers',
			value: `['Taylor', 22, 'january']`,
			type: 'array',
			format: 'multi-input',
			onlyForPicker: false,
		},
	},
	'OPTION-FEATURE': {
		// no need
	},
	ANALYTIC: {
		status: {
			label: 'Status',
			value: true,
			type: 'boolean',
			format: 'condition',
			onlyForPicker: false,
		},
	},
	VALIDATION: {
		status: {
			label: 'Status',
			value: true,
			type: 'boolean',
			format: 'condition',
			onlyForPicker: false,
		},
	},
	'PRODUCT-CARD': {
		// no need
	},
	'AUDIO-RECORDING': {
		url: {
			label: 'Url',
			value: 'https://example.com/audio.mp3',
			type: 'string',
			format: 'audio',
			onlyForPicker: false,
		},
	},
	'PHOTO-CAMERA': {
		url: {
			label: 'Url',
			value: 'https://example.com/image.png',
			type: 'string',
			format: 'image',
			onlyForPicker: false,
		},
	},
	'LINE/CAMERA': {
		label: {
			label: 'Url',
			value: 'https://example.com/image.png',
			type: 'string',
			format: 'image',
			onlyForPicker: false,
		},
	},
	'QR-CODE': {
		data: {
			column: 'Data',
			label: 'the decode text from barcode',
			value: '1234-5689-9999',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	SIGNATURE: {
		url: {
			label: 'Url',
			value: 'https://example.com/image.png',
			type: 'string',
			format: 'image',
			onlyForPicker: false,
		},
	},
	'VIDEO-RECORDING': {
		url: {
			label: 'Url',
			value: 'https://example.com/video.mp4',
			type: 'string',
			format: 'video',
			onlyForPicker: false,
		},
	},
	VOTE: {
		rating: {
			label: 'rating',
			value: 5,
			type: 'number',
			format: 'rating',
			onlyForPicker: false,
		},
	},
	LOGIN: {
		name: {
			label: 'name',
			value: 'St James, Michael',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
		email: {
			label: 'email',
			value: 'st.michael@example.com',
			type: 'string',
			format: 'email',
			onlyForPicker: false,
		},
		image: {
			label: 'image',
			value: 'https://example.com/profile.png',
			type: 'string',
			format: 'image',
			onlyForPicker: false,
		},
	},
	INPUT: {
		text: {
			label: 'Text',
			value: 'Abbeville',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	YESNO: {
		label: {
			column: 'Answer', // only using for response table
			label: 'Label',
			value: 'Yes',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
		value: {
			label: 'Value',
			value: 'yes',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
	},
	'SINGLE-INPUT/NAME': {
		text: {
			label: 'Name',
			value: 'Taylor Swift',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	'SINGLE-INPUT/TEXT': {
		text: {
			label: 'Text',
			value: "I'm fine.",
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	'SINGLE-INPUT/EMAIL': {
		text: {
			label: 'Email',
			value: 'taylor@example.com',
			type: 'string',
			format: 'email',
			onlyForPicker: false,
		},
	},
	'SINGLE-INPUT/NUMBER': {
		text: {
			label: 'Number',
			value: '12',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	'SINGLE-INPUT/PHONE': {
		text: {
			label: 'Phone',
			value: '0914556445',
			type: 'string',
			format: 'phone',
			onlyForPicker: false,
		},
	},
	'SINGLE-INPUT/URL': {
		text: {
			label: 'URL',
			value: 'https://example.com',
			type: 'string',
			format: 'url',
			onlyForPicker: false,
		},
	},
	'QUICK-REPLY/DEFAULT': {
		value: {
			label: 'Value',
			value: 'Chiang Mai',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	'QUICK-REPLY/COLOR': {
		value: {
			label: 'Value',
			value: 'red',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	'QUICK-REPLY/SHIRT-SIZE': {
		value: {
			label: 'Value',
			value: 'XS',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	RATING: {
		rating: {
			label: 'Rating',
			value: '5',
			type: 'string',
			format: 'rating',
			onlyForPicker: false,
		},
	},
	FILE: {
		url: {
			label: 'Url',
			value: 'https://example.com/file.txt',
			type: 'string',
			format: 'file',
			onlyForPicker: false,
		},
	},
	'AB-TEST': {
		value: {
			label: 'Value',
			value: 'a',
			type: 'string',
			format: 'ab-test',
			onlyForPicker: false,
		},
	},
	CONDITION: {
		status: {
			label: 'Status',
			value: true,
			type: 'boolean',
			format: 'condition',
			onlyForPicker: false,
		},
	},
	WEBHOOK: {
		response: {
			label: 'Data',
			value: '',
			type: 'object',
			format: 'object',
			onlyForPicker: false,
		},
	},
	SCALE: {
		number: {
			label: 'Number',
			value: 5,
			type: 'number',
			format: 'number',
			onlyForPicker: false,
		},
	},
	'LINE/IMAGE': {
		url: {
			label: 'Url',
			value: 'https://example.com/image.jpg',
			type: 'string',
			format: 'image',
			onlyForPicker: false,
		},
	},
	'PICTURE-CHOICE': {
		label: {
			column: 'Answer', // only using for response table
			label: 'Answer',
			value: 'Choice 1',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	OMISE: {
		response: {
			label: 'Submit response',
			value: 'Payment description',
			type: 'object',
			format: 'object',
			onlyForPicker: false,
		},
	},
	'GENERATE-QR-CODE-PAYMENT': {
		response: {
			label: 'Qr code promptpay response',
			value: '',
			type: 'object',
			format: 'object',
			onlyForPicker: false,
		},
	},
	'VERIFY-TRANSACTION': {
		response: {
			label: 'Verify transaction response',
			value: '',
			type: 'object',
			format: 'object',
			onlyForPicker: false,
		},
	},
	'JUMP-TO': {
		response_id: {
			label: 'Response id',
			value: '5e61723640701f034e734206',
			type: 'string',
			format: 'jump-to',
			onlyForPicker: false,
		},
	},
	'LINE/SHARE-LOCATION': {
		latitude: {
			label: 'Latitude',
			value: '13.7244416',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
		longitude: {
			label: 'Longitude',
			value: '100.3529157',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
		coordinate: {
			label: 'Coordinate',
			value: '13.7244416,100.3529157',
			type: 'string',
			format: 'location',
			onlyForPicker: false,
		},
		location_name: {
			label: 'Location name',
			value: 'Bangkok',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	'LINE/DATETIME-PICKER': {
		value: {
			label: 'Date and time format',
			value: '2017-06-18',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
	},
	'UNION-MESSAGE': {
		label: {
			column: 'Answer', // only using for response table
			label: 'Label',
			value: 'Spider man',
			type: 'string',
			format: 'string',
			onlyForPicker: false,
		},
		value: {
			label: 'Value',
			value: 'spi-002',
			type: 'string',
			format: 'string',
			onlyForPicker: true,
		},
	},
}

export default inputMode
