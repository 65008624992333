const lineImageCarouselTemplate = (editMessage) => {
	const errors = new Array(10);

	editMessage.config.columns.forEach((configItem, index) => {
		const error = {};
		if (!(configItem.size > 0 && configItem.size <= 10000000)) {
			error.size = 'size'
		}

		if (!['image/x-png', 'image/png', 'image/jpeg'].includes(configItem.extension)) {
			error.extension = 'extension'
		}

		if (!(configItem.dimension.width <= 1024)) {
			error.width = 'width'
		}

		if (!(configItem.dimension.width / configItem.dimension.height === 1)) {
			error.ratio = 'ratio'
		}

		if (Object.keys(error).length > 0) {
			errors[index] = error
		}
	})

	return errors
}

export default lineImageCarouselTemplate