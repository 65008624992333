const billingCountry = [
    {
        "iso2": "AF",
        "iso3": "AFG",
        "calling_code": "93",
        "slug": "afghanistan",
        "name": "Afghanistan"
    },
    {
        "iso2": "AX",
        "iso3": "ALA",
        "calling_code": "35818",
        "slug": "åland_islands",
        "name": "Åland Islands"
    },
    {
        "iso2": "AL",
        "iso3": "ALB",
        "calling_code": "355",
        "slug": "albania",
        "name": "Albania"
    },
    {
        "iso2": "DZ",
        "iso3": "DZA",
        "calling_code": "213",
        "slug": "algeria",
        "name": "Algeria"
    },
    {
        "iso2": "AS",
        "iso3": "ASM",
        "calling_code": "1684",
        "slug": "american_samoa",
        "name": "American Samoa"
    },
    {
        "iso2": "AD",
        "iso3": "AND",
        "calling_code": "376",
        "slug": "andorra",
        "name": "Andorra"
    },
    {
        "iso2": "AO",
        "iso3": "AGO",
        "calling_code": "244",
        "slug": "angola",
        "name": "Angola"
    },
    {
        "iso2": "AI",
        "iso3": "AIA",
        "calling_code": "1264",
        "slug": "anguilla",
        "name": "Anguilla"
    },
    {
        "iso2": "AQ",
        "iso3": "ATA",
        "calling_code": "",
        "slug": "antarctica",
        "name": "Antarctica"
    },
    {
        "iso2": "AG",
        "iso3": "ATG",
        "calling_code": "1268",
        "slug": "antigua_and_barbuda",
        "name": "Antigua and Barbuda"
    },
    {
        "iso2": "AR",
        "iso3": "ARG",
        "calling_code": "54",
        "slug": "argentina",
        "name": "Argentina"
    },
    {
        "iso2": "AM",
        "iso3": "ARM",
        "calling_code": "374",
        "slug": "armenia",
        "name": "Armenia"
    },
    {
        "iso2": "AW",
        "iso3": "ABW",
        "calling_code": "297",
        "slug": "aruba",
        "name": "Aruba"
    },
    {
        "iso2": "AU",
        "iso3": "AUS",
        "calling_code": "61",
        "slug": "australia",
        "name": "Australia"
    },
    {
        "iso2": "AT",
        "iso3": "AUT",
        "calling_code": "43",
        "slug": "austria",
        "name": "Austria"
    },
    {
        "iso2": "AZ",
        "iso3": "AZE",
        "calling_code": "994",
        "slug": "azerbaijan",
        "name": "Azerbaijan"
    },
    {
        "iso2": "BS",
        "iso3": "BHS",
        "calling_code": "1242",
        "slug": "bahamas",
        "name": "Bahamas"
    },
    {
        "iso2": "BH",
        "iso3": "BHR",
        "calling_code": "973",
        "slug": "bahrain",
        "name": "Bahrain"
    },
    {
        "iso2": "BD",
        "iso3": "BGD",
        "calling_code": "880",
        "slug": "bangladesh",
        "name": "Bangladesh"
    },
    {
        "iso2": "BB",
        "iso3": "BRB",
        "calling_code": "1246",
        "slug": "barbados",
        "name": "Barbados"
    },
    {
        "iso2": "BY",
        "iso3": "BLR",
        "calling_code": "375",
        "slug": "belarus",
        "name": "Belarus"
    },
    {
        "iso2": "BE",
        "iso3": "BEL",
        "calling_code": "32",
        "slug": "belgium",
        "name": "Belgium"
    },
    {
        "iso2": "BZ",
        "iso3": "BLZ",
        "calling_code": "501",
        "slug": "belize",
        "name": "Belize"
    },
    {
        "iso2": "BJ",
        "iso3": "BEN",
        "calling_code": "229",
        "slug": "benin",
        "name": "Benin"
    },
    {
        "iso2": "BM",
        "iso3": "BMU",
        "calling_code": "1441",
        "slug": "bermuda",
        "name": "Bermuda"
    },
    {
        "iso2": "BT",
        "iso3": "BTN",
        "calling_code": "975",
        "slug": "bhutan",
        "name": "Bhutan"
    },
    {
        "iso2": "BO",
        "iso3": "BOL",
        "calling_code": "591",
        "slug": "bolivia",
        "name": "Bolivia"
    },
    {
        "iso2": "BA",
        "iso3": "BIH",
        "calling_code": "387",
        "slug": "bosnia_and_herzegovina",
        "name": "Bosnia and Herzegovina"
    },
    {
        "iso2": "BW",
        "iso3": "BWA",
        "calling_code": "267",
        "slug": "botswana",
        "name": "Botswana"
    },
    {
        "iso2": "BV",
        "iso3": "BVT",
        "calling_code": "47",
        "slug": "bouvet_island",
        "name": "Bouvet Island"
    },
    {
        "iso2": "BR",
        "iso3": "BRA",
        "calling_code": "55",
        "slug": "brazil",
        "name": "Brazil"
    },
    {
        "iso2": "IO",
        "iso3": "IOT",
        "calling_code": "246",
        "slug": "british_indian_ocean_territory",
        "name": "British Indian Ocean Territory"
    },
    {
        "iso2": "VG",
        "iso3": "VGB",
        "calling_code": "1284",
        "slug": "british_virgin_islands",
        "name": "British Virgin Islands"
    },
    {
        "iso2": "BN",
        "iso3": "BRN",
        "calling_code": "673",
        "slug": "brunei",
        "name": "Brunei"
    },
    {
        "iso2": "BG",
        "iso3": "BGR",
        "calling_code": "359",
        "slug": "bulgaria",
        "name": "Bulgaria"
    },
    {
        "iso2": "BF",
        "iso3": "BFA",
        "calling_code": "226",
        "slug": "burkina_faso",
        "name": "Burkina Faso"
    },
    {
        "iso2": "BI",
        "iso3": "BDI",
        "calling_code": "257",
        "slug": "burundi",
        "name": "Burundi"
    },
    {
        "iso2": "KH",
        "iso3": "KHM",
        "calling_code": "855",
        "slug": "cambodia",
        "name": "Cambodia"
    },
    {
        "iso2": "CM",
        "iso3": "CMR",
        "calling_code": "237",
        "slug": "cameroon",
        "name": "Cameroon"
    },
    {
        "iso2": "CA",
        "iso3": "CAN",
        "calling_code": "1",
        "slug": "canada",
        "name": "Canada"
    },
    {
        "iso2": "CV",
        "iso3": "CPV",
        "calling_code": "238",
        "slug": "cape_verde",
        "name": "Cape Verde"
    },
    {
        "iso2": "BQ",
        "iso3": "BES",
        "calling_code": "599",
        "slug": "caribbean_netherlands",
        "name": "Caribbean Netherlands"
    },
    {
        "iso2": "KY",
        "iso3": "CYM",
        "calling_code": "1345",
        "slug": "cayman_islands",
        "name": "Cayman Islands"
    },
    {
        "iso2": "CF",
        "iso3": "CAF",
        "calling_code": "236",
        "slug": "central_african_republic",
        "name": "Central African Republic"
    },
    {
        "iso2": "TD",
        "iso3": "TCD",
        "calling_code": "235",
        "slug": "chad",
        "name": "Chad"
    },
    {
        "iso2": "CL",
        "iso3": "CHL",
        "calling_code": "56",
        "slug": "chile",
        "name": "Chile"
    },
    {
        "iso2": "CN",
        "iso3": "CHN",
        "calling_code": "86",
        "slug": "china",
        "name": "China"
    },
    {
        "iso2": "CX",
        "iso3": "CXR",
        "calling_code": "61",
        "slug": "christmas_island",
        "name": "Christmas Island"
    },
    {
        "iso2": "CC",
        "iso3": "CCK",
        "calling_code": "61",
        "slug": "cocos_(keeling)_islands",
        "name": "Cocos (Keeling) Islands"
    },
    {
        "iso2": "CO",
        "iso3": "COL",
        "calling_code": "57",
        "slug": "colombia",
        "name": "Colombia"
    },
    {
        "iso2": "KM",
        "iso3": "COM",
        "calling_code": "269",
        "slug": "comoros",
        "name": "Comoros"
    },
    {
        "iso2": "CK",
        "iso3": "COK",
        "calling_code": "682",
        "slug": "cook_islands",
        "name": "Cook Islands"
    },
    {
        "iso2": "CR",
        "iso3": "CRI",
        "calling_code": "506",
        "slug": "costa_rica",
        "name": "Costa Rica"
    },
    {
        "iso2": "HR",
        "iso3": "HRV",
        "calling_code": "385",
        "slug": "croatia",
        "name": "Croatia"
    },
    {
        "iso2": "CU",
        "iso3": "CUB",
        "calling_code": "53",
        "slug": "cuba",
        "name": "Cuba"
    },
    {
        "iso2": "CW",
        "iso3": "CUW",
        "calling_code": "599",
        "slug": "curaçao",
        "name": "Curaçao"
    },
    {
        "iso2": "CY",
        "iso3": "CYP",
        "calling_code": "357",
        "slug": "cyprus",
        "name": "Cyprus"
    },
    {
        "iso2": "CZ",
        "iso3": "CZE",
        "calling_code": "420",
        "slug": "czechia",
        "name": "Czechia"
    },
    {
        "iso2": "DK",
        "iso3": "DNK",
        "calling_code": "45",
        "slug": "denmark",
        "name": "Denmark"
    },
    {
        "iso2": "DJ",
        "iso3": "DJI",
        "calling_code": "253",
        "slug": "djibouti",
        "name": "Djibouti"
    },
    {
        "iso2": "DM",
        "iso3": "DMA",
        "calling_code": "1767",
        "slug": "dominica",
        "name": "Dominica"
    },
    {
        "iso2": "DO",
        "iso3": "DOM",
        "calling_code": "1",
        "slug": "dominican_republic",
        "name": "Dominican Republic"
    },
    {
        "iso2": "CD",
        "iso3": "COD",
        "calling_code": "243",
        "slug": "dr_congo",
        "name": "DR Congo"
    },
    {
        "iso2": "EC",
        "iso3": "ECU",
        "calling_code": "593",
        "slug": "ecuador",
        "name": "Ecuador"
    },
    {
        "iso2": "EG",
        "iso3": "EGY",
        "calling_code": "20",
        "slug": "egypt",
        "name": "Egypt"
    },
    {
        "iso2": "SV",
        "iso3": "SLV",
        "calling_code": "503",
        "slug": "el_salvador",
        "name": "El Salvador"
    },
    {
        "iso2": "GQ",
        "iso3": "GNQ",
        "calling_code": "240",
        "slug": "equatorial_guinea",
        "name": "Equatorial Guinea"
    },
    {
        "iso2": "ER",
        "iso3": "ERI",
        "calling_code": "291",
        "slug": "eritrea",
        "name": "Eritrea"
    },
    {
        "iso2": "EE",
        "iso3": "EST",
        "calling_code": "372",
        "slug": "estonia",
        "name": "Estonia"
    },
    {
        "iso2": "SZ",
        "iso3": "SWZ",
        "calling_code": "268",
        "slug": "eswatini",
        "name": "Eswatini"
    },
    {
        "iso2": "ET",
        "iso3": "ETH",
        "calling_code": "251",
        "slug": "ethiopia",
        "name": "Ethiopia"
    },
    {
        "iso2": "FK",
        "iso3": "FLK",
        "calling_code": "500",
        "slug": "falkland_islands",
        "name": "Falkland Islands"
    },
    {
        "iso2": "FO",
        "iso3": "FRO",
        "calling_code": "298",
        "slug": "faroe_islands",
        "name": "Faroe Islands"
    },
    {
        "iso2": "FJ",
        "iso3": "FJI",
        "calling_code": "679",
        "slug": "fiji",
        "name": "Fiji"
    },
    {
        "iso2": "FI",
        "iso3": "FIN",
        "calling_code": "358",
        "slug": "finland",
        "name": "Finland"
    },
    {
        "iso2": "FR",
        "iso3": "FRA",
        "calling_code": "33",
        "slug": "france",
        "name": "France"
    },
    {
        "iso2": "GF",
        "iso3": "GUF",
        "calling_code": "594",
        "slug": "french_guiana",
        "name": "French Guiana"
    },
    {
        "iso2": "PF",
        "iso3": "PYF",
        "calling_code": "689",
        "slug": "french_polynesia",
        "name": "French Polynesia"
    },
    {
        "iso2": "TF",
        "iso3": "ATF",
        "calling_code": "262",
        "slug": "french_southern_and_antarctic_lands",
        "name": "French Southern and Antarctic Lands"
    },
    {
        "iso2": "GA",
        "iso3": "GAB",
        "calling_code": "241",
        "slug": "gabon",
        "name": "Gabon"
    },
    {
        "iso2": "GM",
        "iso3": "GMB",
        "calling_code": "220",
        "slug": "gambia",
        "name": "Gambia"
    },
    {
        "iso2": "GE",
        "iso3": "GEO",
        "calling_code": "995",
        "slug": "georgia",
        "name": "Georgia"
    },
    {
        "iso2": "DE",
        "iso3": "DEU",
        "calling_code": "49",
        "slug": "germany",
        "name": "Germany"
    },
    {
        "iso2": "GH",
        "iso3": "GHA",
        "calling_code": "233",
        "slug": "ghana",
        "name": "Ghana"
    },
    {
        "iso2": "GI",
        "iso3": "GIB",
        "calling_code": "350",
        "slug": "gibraltar",
        "name": "Gibraltar"
    },
    {
        "iso2": "GR",
        "iso3": "GRC",
        "calling_code": "30",
        "slug": "greece",
        "name": "Greece"
    },
    {
        "iso2": "GL",
        "iso3": "GRL",
        "calling_code": "299",
        "slug": "greenland",
        "name": "Greenland"
    },
    {
        "iso2": "GD",
        "iso3": "GRD",
        "calling_code": "1473",
        "slug": "grenada",
        "name": "Grenada"
    },
    {
        "iso2": "GP",
        "iso3": "GLP",
        "calling_code": "590",
        "slug": "guadeloupe",
        "name": "Guadeloupe"
    },
    {
        "iso2": "GU",
        "iso3": "GUM",
        "calling_code": "1671",
        "slug": "guam",
        "name": "Guam"
    },
    {
        "iso2": "GT",
        "iso3": "GTM",
        "calling_code": "502",
        "slug": "guatemala",
        "name": "Guatemala"
    },
    {
        "iso2": "GG",
        "iso3": "GGY",
        "calling_code": "44",
        "slug": "guernsey",
        "name": "Guernsey"
    },
    {
        "iso2": "GN",
        "iso3": "GIN",
        "calling_code": "224",
        "slug": "guinea",
        "name": "Guinea"
    },
    {
        "iso2": "GW",
        "iso3": "GNB",
        "calling_code": "245",
        "slug": "guinea-bissau",
        "name": "Guinea-Bissau"
    },
    {
        "iso2": "GY",
        "iso3": "GUY",
        "calling_code": "592",
        "slug": "guyana",
        "name": "Guyana"
    },
    {
        "iso2": "HT",
        "iso3": "HTI",
        "calling_code": "509",
        "slug": "haiti",
        "name": "Haiti"
    },
    {
        "iso2": "HM",
        "iso3": "HMD",
        "calling_code": "",
        "slug": "heard_island_and_mcdonald_islands",
        "name": "Heard Island and McDonald Islands"
    },
    {
        "iso2": "HN",
        "iso3": "HND",
        "calling_code": "504",
        "slug": "honduras",
        "name": "Honduras"
    },
    {
        "iso2": "HK",
        "iso3": "HKG",
        "calling_code": "852",
        "slug": "hong_kong",
        "name": "Hong Kong"
    },
    {
        "iso2": "HU",
        "iso3": "HUN",
        "calling_code": "36",
        "slug": "hungary",
        "name": "Hungary"
    },
    {
        "iso2": "IS",
        "iso3": "ISL",
        "calling_code": "354",
        "slug": "iceland",
        "name": "Iceland"
    },
    {
        "iso2": "IN",
        "iso3": "IND",
        "calling_code": "91",
        "slug": "india",
        "name": "India"
    },
    {
        "iso2": "ID",
        "iso3": "IDN",
        "calling_code": "62",
        "slug": "indonesia",
        "name": "Indonesia"
    },
    {
        "iso2": "IR",
        "iso3": "IRN",
        "calling_code": "98",
        "slug": "iran",
        "name": "Iran"
    },
    {
        "iso2": "IQ",
        "iso3": "IRQ",
        "calling_code": "964",
        "slug": "iraq",
        "name": "Iraq"
    },
    {
        "iso2": "IE",
        "iso3": "IRL",
        "calling_code": "353",
        "slug": "ireland",
        "name": "Ireland"
    },
    {
        "iso2": "IM",
        "iso3": "IMN",
        "calling_code": "44",
        "slug": "isle_of_man",
        "name": "Isle of Man"
    },
    {
        "iso2": "IL",
        "iso3": "ISR",
        "calling_code": "972",
        "slug": "israel",
        "name": "Israel"
    },
    {
        "iso2": "IT",
        "iso3": "ITA",
        "calling_code": "39",
        "slug": "italy",
        "name": "Italy"
    },
    {
        "iso2": "CI",
        "iso3": "CIV",
        "calling_code": "225",
        "slug": "ivory_coast",
        "name": "Ivory Coast"
    },
    {
        "iso2": "JM",
        "iso3": "JAM",
        "calling_code": "1876",
        "slug": "jamaica",
        "name": "Jamaica"
    },
    {
        "iso2": "JP",
        "iso3": "JPN",
        "calling_code": "81",
        "slug": "japan",
        "name": "Japan"
    },
    {
        "iso2": "JE",
        "iso3": "JEY",
        "calling_code": "44",
        "slug": "jersey",
        "name": "Jersey"
    },
    {
        "iso2": "JO",
        "iso3": "JOR",
        "calling_code": "962",
        "slug": "jordan",
        "name": "Jordan"
    },
    {
        "iso2": "KZ",
        "iso3": "KAZ",
        "calling_code": "7",
        "slug": "kazakhstan",
        "name": "Kazakhstan"
    },
    {
        "iso2": "KE",
        "iso3": "KEN",
        "calling_code": "254",
        "slug": "kenya",
        "name": "Kenya"
    },
    {
        "iso2": "KI",
        "iso3": "KIR",
        "calling_code": "686",
        "slug": "kiribati",
        "name": "Kiribati"
    },
    {
        "iso2": "XK",
        "iso3": "UNK",
        "calling_code": "383",
        "slug": "kosovo",
        "name": "Kosovo"
    },
    {
        "iso2": "KW",
        "iso3": "KWT",
        "calling_code": "965",
        "slug": "kuwait",
        "name": "Kuwait"
    },
    {
        "iso2": "KG",
        "iso3": "KGZ",
        "calling_code": "996",
        "slug": "kyrgyzstan",
        "name": "Kyrgyzstan"
    },
    {
        "iso2": "LA",
        "iso3": "LAO",
        "calling_code": "856",
        "slug": "laos",
        "name": "Laos"
    },
    {
        "iso2": "LV",
        "iso3": "LVA",
        "calling_code": "371",
        "slug": "latvia",
        "name": "Latvia"
    },
    {
        "iso2": "LB",
        "iso3": "LBN",
        "calling_code": "961",
        "slug": "lebanon",
        "name": "Lebanon"
    },
    {
        "iso2": "LS",
        "iso3": "LSO",
        "calling_code": "266",
        "slug": "lesotho",
        "name": "Lesotho"
    },
    {
        "iso2": "LR",
        "iso3": "LBR",
        "calling_code": "231",
        "slug": "liberia",
        "name": "Liberia"
    },
    {
        "iso2": "LY",
        "iso3": "LBY",
        "calling_code": "218",
        "slug": "libya",
        "name": "Libya"
    },
    {
        "iso2": "LI",
        "iso3": "LIE",
        "calling_code": "423",
        "slug": "liechtenstein",
        "name": "Liechtenstein"
    },
    {
        "iso2": "LT",
        "iso3": "LTU",
        "calling_code": "370",
        "slug": "lithuania",
        "name": "Lithuania"
    },
    {
        "iso2": "LU",
        "iso3": "LUX",
        "calling_code": "352",
        "slug": "luxembourg",
        "name": "Luxembourg"
    },
    {
        "iso2": "MO",
        "iso3": "MAC",
        "calling_code": "853",
        "slug": "macau",
        "name": "Macau"
    },
    {
        "iso2": "MG",
        "iso3": "MDG",
        "calling_code": "261",
        "slug": "madagascar",
        "name": "Madagascar"
    },
    {
        "iso2": "MW",
        "iso3": "MWI",
        "calling_code": "265",
        "slug": "malawi",
        "name": "Malawi"
    },
    {
        "iso2": "MY",
        "iso3": "MYS",
        "calling_code": "60",
        "slug": "malaysia",
        "name": "Malaysia"
    },
    {
        "iso2": "MV",
        "iso3": "MDV",
        "calling_code": "960",
        "slug": "maldives",
        "name": "Maldives"
    },
    {
        "iso2": "ML",
        "iso3": "MLI",
        "calling_code": "223",
        "slug": "mali",
        "name": "Mali"
    },
    {
        "iso2": "MT",
        "iso3": "MLT",
        "calling_code": "356",
        "slug": "malta",
        "name": "Malta"
    },
    {
        "iso2": "MH",
        "iso3": "MHL",
        "calling_code": "692",
        "slug": "marshall_islands",
        "name": "Marshall Islands"
    },
    {
        "iso2": "MQ",
        "iso3": "MTQ",
        "calling_code": "596",
        "slug": "martinique",
        "name": "Martinique"
    },
    {
        "iso2": "MR",
        "iso3": "MRT",
        "calling_code": "222",
        "slug": "mauritania",
        "name": "Mauritania"
    },
    {
        "iso2": "MU",
        "iso3": "MUS",
        "calling_code": "230",
        "slug": "mauritius",
        "name": "Mauritius"
    },
    {
        "iso2": "YT",
        "iso3": "MYT",
        "calling_code": "262",
        "slug": "mayotte",
        "name": "Mayotte"
    },
    {
        "iso2": "MX",
        "iso3": "MEX",
        "calling_code": "52",
        "slug": "mexico",
        "name": "Mexico"
    },
    {
        "iso2": "FM",
        "iso3": "FSM",
        "calling_code": "691",
        "slug": "micronesia",
        "name": "Micronesia"
    },
    {
        "iso2": "MD",
        "iso3": "MDA",
        "calling_code": "373",
        "slug": "moldova",
        "name": "Moldova"
    },
    {
        "iso2": "MC",
        "iso3": "MCO",
        "calling_code": "377",
        "slug": "monaco",
        "name": "Monaco"
    },
    {
        "iso2": "MN",
        "iso3": "MNG",
        "calling_code": "976",
        "slug": "mongolia",
        "name": "Mongolia"
    },
    {
        "iso2": "ME",
        "iso3": "MNE",
        "calling_code": "382",
        "slug": "montenegro",
        "name": "Montenegro"
    },
    {
        "iso2": "MS",
        "iso3": "MSR",
        "calling_code": "1664",
        "slug": "montserrat",
        "name": "Montserrat"
    },
    {
        "iso2": "MA",
        "iso3": "MAR",
        "calling_code": "212",
        "slug": "morocco",
        "name": "Morocco"
    },
    {
        "iso2": "MZ",
        "iso3": "MOZ",
        "calling_code": "258",
        "slug": "mozambique",
        "name": "Mozambique"
    },
    {
        "iso2": "MM",
        "iso3": "MMR",
        "calling_code": "95",
        "slug": "myanmar",
        "name": "Myanmar"
    },
    {
        "iso2": "NA",
        "iso3": "NAM",
        "calling_code": "264",
        "slug": "namibia",
        "name": "Namibia"
    },
    {
        "iso2": "NR",
        "iso3": "NRU",
        "calling_code": "674",
        "slug": "nauru",
        "name": "Nauru"
    },
    {
        "iso2": "NP",
        "iso3": "NPL",
        "calling_code": "977",
        "slug": "nepal",
        "name": "Nepal"
    },
    {
        "iso2": "NL",
        "iso3": "NLD",
        "calling_code": "31",
        "slug": "netherlands",
        "name": "Netherlands"
    },
    {
        "iso2": "NC",
        "iso3": "NCL",
        "calling_code": "687",
        "slug": "new_caledonia",
        "name": "New Caledonia"
    },
    {
        "iso2": "NZ",
        "iso3": "NZL",
        "calling_code": "64",
        "slug": "new_zealand",
        "name": "New Zealand"
    },
    {
        "iso2": "NI",
        "iso3": "NIC",
        "calling_code": "505",
        "slug": "nicaragua",
        "name": "Nicaragua"
    },
    {
        "iso2": "NE",
        "iso3": "NER",
        "calling_code": "227",
        "slug": "niger",
        "name": "Niger"
    },
    {
        "iso2": "NG",
        "iso3": "NGA",
        "calling_code": "234",
        "slug": "nigeria",
        "name": "Nigeria"
    },
    {
        "iso2": "NU",
        "iso3": "NIU",
        "calling_code": "683",
        "slug": "niue",
        "name": "Niue"
    },
    {
        "iso2": "NF",
        "iso3": "NFK",
        "calling_code": "672",
        "slug": "norfolk_island",
        "name": "Norfolk Island"
    },
    {
        "iso2": "KP",
        "iso3": "PRK",
        "calling_code": "850",
        "slug": "north_korea",
        "name": "North Korea"
    },
    {
        "iso2": "MK",
        "iso3": "MKD",
        "calling_code": "389",
        "slug": "north_macedonia",
        "name": "North Macedonia"
    },
    {
        "iso2": "MP",
        "iso3": "MNP",
        "calling_code": "1670",
        "slug": "northern_mariana_islands",
        "name": "Northern Mariana Islands"
    },
    {
        "iso2": "NO",
        "iso3": "NOR",
        "calling_code": "47",
        "slug": "norway",
        "name": "Norway"
    },
    {
        "iso2": "OM",
        "iso3": "OMN",
        "calling_code": "968",
        "slug": "oman",
        "name": "Oman"
    },
    {
        "iso2": "PK",
        "iso3": "PAK",
        "calling_code": "92",
        "slug": "pakistan",
        "name": "Pakistan"
    },
    {
        "iso2": "PW",
        "iso3": "PLW",
        "calling_code": "680",
        "slug": "palau",
        "name": "Palau"
    },
    {
        "iso2": "PS",
        "iso3": "PSE",
        "calling_code": "970",
        "slug": "palestine",
        "name": "Palestine"
    },
    {
        "iso2": "PA",
        "iso3": "PAN",
        "calling_code": "507",
        "slug": "panama",
        "name": "Panama"
    },
    {
        "iso2": "PG",
        "iso3": "PNG",
        "calling_code": "675",
        "slug": "papua_new_guinea",
        "name": "Papua New Guinea"
    },
    {
        "iso2": "PY",
        "iso3": "PRY",
        "calling_code": "595",
        "slug": "paraguay",
        "name": "Paraguay"
    },
    {
        "iso2": "PE",
        "iso3": "PER",
        "calling_code": "51",
        "slug": "peru",
        "name": "Peru"
    },
    {
        "iso2": "PH",
        "iso3": "PHL",
        "calling_code": "63",
        "slug": "philippines",
        "name": "Philippines"
    },
    {
        "iso2": "PN",
        "iso3": "PCN",
        "calling_code": "64",
        "slug": "pitcairn_islands",
        "name": "Pitcairn Islands"
    },
    {
        "iso2": "PL",
        "iso3": "POL",
        "calling_code": "48",
        "slug": "poland",
        "name": "Poland"
    },
    {
        "iso2": "PT",
        "iso3": "PRT",
        "calling_code": "351",
        "slug": "portugal",
        "name": "Portugal"
    },
    {
        "iso2": "PR",
        "iso3": "PRI",
        "calling_code": "1",
        "slug": "puerto_rico",
        "name": "Puerto Rico"
    },
    {
        "iso2": "QA",
        "iso3": "QAT",
        "calling_code": "974",
        "slug": "qatar",
        "name": "Qatar"
    },
    {
        "iso2": "CG",
        "iso3": "COG",
        "calling_code": "242",
        "slug": "republic_of_the_congo",
        "name": "Republic of the Congo"
    },
    {
        "iso2": "RE",
        "iso3": "REU",
        "calling_code": "262",
        "slug": "réunion",
        "name": "Réunion"
    },
    {
        "iso2": "RO",
        "iso3": "ROU",
        "calling_code": "40",
        "slug": "romania",
        "name": "Romania"
    },
    {
        "iso2": "RU",
        "iso3": "RUS",
        "calling_code": "7",
        "slug": "russia",
        "name": "Russia"
    },
    {
        "iso2": "RW",
        "iso3": "RWA",
        "calling_code": "250",
        "slug": "rwanda",
        "name": "Rwanda"
    },
    {
        "iso2": "BL",
        "iso3": "BLM",
        "calling_code": "590",
        "slug": "saint_barthélemy",
        "name": "Saint Barthélemy"
    },
    {
        "iso2": "SH",
        "iso3": "SHN",
        "calling_code": "2",
        "slug": "saint_helena,_ascension_and_tristan_da_cunha",
        "name": "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
        "iso2": "KN",
        "iso3": "KNA",
        "calling_code": "1869",
        "slug": "saint_kitts_and_nevis",
        "name": "Saint Kitts and Nevis"
    },
    {
        "iso2": "LC",
        "iso3": "LCA",
        "calling_code": "1758",
        "slug": "saint_lucia",
        "name": "Saint Lucia"
    },
    {
        "iso2": "MF",
        "iso3": "MAF",
        "calling_code": "590",
        "slug": "saint_martin",
        "name": "Saint Martin"
    },
    {
        "iso2": "PM",
        "iso3": "SPM",
        "calling_code": "508",
        "slug": "saint_pierre_and_miquelon",
        "name": "Saint Pierre and Miquelon"
    },
    {
        "iso2": "VC",
        "iso3": "VCT",
        "calling_code": "1784",
        "slug": "saint_vincent_and_the_grenadines",
        "name": "Saint Vincent and the Grenadines"
    },
    {
        "iso2": "WS",
        "iso3": "WSM",
        "calling_code": "685",
        "slug": "samoa",
        "name": "Samoa"
    },
    {
        "iso2": "SM",
        "iso3": "SMR",
        "calling_code": "378",
        "slug": "san_marino",
        "name": "San Marino"
    },
    {
        "iso2": "ST",
        "iso3": "STP",
        "calling_code": "239",
        "slug": "são_tomé_and_príncipe",
        "name": "São Tomé and Príncipe"
    },
    {
        "iso2": "SA",
        "iso3": "SAU",
        "calling_code": "966",
        "slug": "saudi_arabia",
        "name": "Saudi Arabia"
    },
    {
        "iso2": "SN",
        "iso3": "SEN",
        "calling_code": "221",
        "slug": "senegal",
        "name": "Senegal"
    },
    {
        "iso2": "RS",
        "iso3": "SRB",
        "calling_code": "381",
        "slug": "serbia",
        "name": "Serbia"
    },
    {
        "iso2": "SC",
        "iso3": "SYC",
        "calling_code": "248",
        "slug": "seychelles",
        "name": "Seychelles"
    },
    {
        "iso2": "SL",
        "iso3": "SLE",
        "calling_code": "232",
        "slug": "sierra_leone",
        "name": "Sierra Leone"
    },
    {
        "iso2": "SG",
        "iso3": "SGP",
        "calling_code": "65",
        "slug": "singapore",
        "name": "Singapore"
    },
    {
        "iso2": "SX",
        "iso3": "SXM",
        "calling_code": "1721",
        "slug": "sint_maarten",
        "name": "Sint Maarten"
    },
    {
        "iso2": "SK",
        "iso3": "SVK",
        "calling_code": "421",
        "slug": "slovakia",
        "name": "Slovakia"
    },
    {
        "iso2": "SI",
        "iso3": "SVN",
        "calling_code": "386",
        "slug": "slovenia",
        "name": "Slovenia"
    },
    {
        "iso2": "SB",
        "iso3": "SLB",
        "calling_code": "677",
        "slug": "solomon_islands",
        "name": "Solomon Islands"
    },
    {
        "iso2": "SO",
        "iso3": "SOM",
        "calling_code": "252",
        "slug": "somalia",
        "name": "Somalia"
    },
    {
        "iso2": "ZA",
        "iso3": "ZAF",
        "calling_code": "27",
        "slug": "south_africa",
        "name": "South Africa"
    },
    {
        "iso2": "GS",
        "iso3": "SGS",
        "calling_code": "500",
        "slug": "south_georgia",
        "name": "South Georgia"
    },
    {
        "iso2": "KR",
        "iso3": "KOR",
        "calling_code": "82",
        "slug": "south_korea",
        "name": "South Korea"
    },
    {
        "iso2": "SS",
        "iso3": "SSD",
        "calling_code": "211",
        "slug": "south_sudan",
        "name": "South Sudan"
    },
    {
        "iso2": "ES",
        "iso3": "ESP",
        "calling_code": "34",
        "slug": "spain",
        "name": "Spain"
    },
    {
        "iso2": "LK",
        "iso3": "LKA",
        "calling_code": "94",
        "slug": "sri_lanka",
        "name": "Sri Lanka"
    },
    {
        "iso2": "SD",
        "iso3": "SDN",
        "calling_code": "249",
        "slug": "sudan",
        "name": "Sudan"
    },
    {
        "iso2": "SR",
        "iso3": "SUR",
        "calling_code": "597",
        "slug": "suriname",
        "name": "Suriname"
    },
    {
        "iso2": "SJ",
        "iso3": "SJM",
        "calling_code": "4779",
        "slug": "svalbard_and_jan_mayen",
        "name": "Svalbard and Jan Mayen"
    },
    {
        "iso2": "SE",
        "iso3": "SWE",
        "calling_code": "46",
        "slug": "sweden",
        "name": "Sweden"
    },
    {
        "iso2": "CH",
        "iso3": "CHE",
        "calling_code": "41",
        "slug": "switzerland",
        "name": "Switzerland"
    },
    {
        "iso2": "SY",
        "iso3": "SYR",
        "calling_code": "963",
        "slug": "syria",
        "name": "Syria"
    },
    {
        "iso2": "TW",
        "iso3": "TWN",
        "calling_code": "886",
        "slug": "taiwan",
        "name": "Taiwan"
    },
    {
        "iso2": "TJ",
        "iso3": "TJK",
        "calling_code": "992",
        "slug": "tajikistan",
        "name": "Tajikistan"
    },
    {
        "iso2": "TZ",
        "iso3": "TZA",
        "calling_code": "255",
        "slug": "tanzania",
        "name": "Tanzania"
    },
    {
        "iso2": "TH",
        "iso3": "THA",
        "calling_code": "66",
        "slug": "thailand",
        "name": "Thailand"
    },
    {
        "iso2": "TL",
        "iso3": "TLS",
        "calling_code": "670",
        "slug": "timor-leste",
        "name": "Timor-Leste"
    },
    {
        "iso2": "TG",
        "iso3": "TGO",
        "calling_code": "228",
        "slug": "togo",
        "name": "Togo"
    },
    {
        "iso2": "TK",
        "iso3": "TKL",
        "calling_code": "690",
        "slug": "tokelau",
        "name": "Tokelau"
    },
    {
        "iso2": "TO",
        "iso3": "TON",
        "calling_code": "676",
        "slug": "tonga",
        "name": "Tonga"
    },
    {
        "iso2": "TT",
        "iso3": "TTO",
        "calling_code": "1868",
        "slug": "trinidad_and_tobago",
        "name": "Trinidad and Tobago"
    },
    {
        "iso2": "TN",
        "iso3": "TUN",
        "calling_code": "216",
        "slug": "tunisia",
        "name": "Tunisia"
    },
    {
        "iso2": "TR",
        "iso3": "TUR",
        "calling_code": "90",
        "slug": "turkey",
        "name": "Turkey"
    },
    {
        "iso2": "TM",
        "iso3": "TKM",
        "calling_code": "993",
        "slug": "turkmenistan",
        "name": "Turkmenistan"
    },
    {
        "iso2": "TC",
        "iso3": "TCA",
        "calling_code": "1649",
        "slug": "turks_and_caicos_islands",
        "name": "Turks and Caicos Islands"
    },
    {
        "iso2": "TV",
        "iso3": "TUV",
        "calling_code": "688",
        "slug": "tuvalu",
        "name": "Tuvalu"
    },
    {
        "iso2": "UG",
        "iso3": "UGA",
        "calling_code": "256",
        "slug": "uganda",
        "name": "Uganda"
    },
    {
        "iso2": "UA",
        "iso3": "UKR",
        "calling_code": "380",
        "slug": "ukraine",
        "name": "Ukraine"
    },
    {
        "iso2": "AE",
        "iso3": "ARE",
        "calling_code": "971",
        "slug": "united_arab_emirates",
        "name": "United Arab Emirates"
    },
    {
        "iso2": "GB",
        "iso3": "GBR",
        "calling_code": "44",
        "slug": "united_kingdom",
        "name": "United Kingdom"
    },
    {
        "iso2": "US",
        "iso3": "USA",
        "calling_code": "1",
        "slug": "united_states",
        "name": "United States"
    },
    {
        "iso2": "UM",
        "iso3": "UMI",
        "calling_code": "268",
        "slug": "united_states_minor_outlying_islands",
        "name": "United States Minor Outlying Islands"
    },
    {
        "iso2": "VI",
        "iso3": "VIR",
        "calling_code": "1340",
        "slug": "united_states_virgin_islands",
        "name": "United States Virgin Islands"
    },
    {
        "iso2": "UY",
        "iso3": "URY",
        "calling_code": "598",
        "slug": "uruguay",
        "name": "Uruguay"
    },
    {
        "iso2": "UZ",
        "iso3": "UZB",
        "calling_code": "998",
        "slug": "uzbekistan",
        "name": "Uzbekistan"
    },
    {
        "iso2": "VU",
        "iso3": "VUT",
        "calling_code": "678",
        "slug": "vanuatu",
        "name": "Vanuatu"
    },
    {
        "iso2": "VA",
        "iso3": "VAT",
        "calling_code": "3",
        "slug": "vatican_city",
        "name": "Vatican City"
    },
    {
        "iso2": "VE",
        "iso3": "VEN",
        "calling_code": "58",
        "slug": "venezuela",
        "name": "Venezuela"
    },
    {
        "iso2": "VN",
        "iso3": "VNM",
        "calling_code": "84",
        "slug": "vietnam",
        "name": "Vietnam"
    },
    {
        "iso2": "WF",
        "iso3": "WLF",
        "calling_code": "681",
        "slug": "wallis_and_futuna",
        "name": "Wallis and Futuna"
    },
    {
        "iso2": "EH",
        "iso3": "ESH",
        "calling_code": "2",
        "slug": "western_sahara",
        "name": "Western Sahara"
    },
    {
        "iso2": "YE",
        "iso3": "YEM",
        "calling_code": "967",
        "slug": "yemen",
        "name": "Yemen"
    },
    {
        "iso2": "ZM",
        "iso3": "ZMB",
        "calling_code": "260",
        "slug": "zambia",
        "name": "Zambia"
    },
    {
        "iso2": "ZW",
        "iso3": "ZWE",
        "calling_code": "263",
        "slug": "zimbabwe",
        "name": "Zimbabwe"
    }
]

export default billingCountry