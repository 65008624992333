import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchLineCustomerWithQuery() {
	while (true) {
		const { payload: { limit, offset, search } } = yield take(types.LINE_CUSTOMER_QUERY)
		try {
			eventlog.info('fetch line customer')
			yield put(actions.isFetchingLineCustomer(true))
			const { data } = yield call(api.fetchLineCustomerWithQuery, limit, offset, search)

			yield put(actions.lineCustomer(data))
			yield put(actions.isFetchingLineCustomer(false))
		} catch (err) {
			yield put(actions.isFetchingLineCustomer(false))
			console.error('[onFetchLineCustomer] ', err)
			eventlog.error('enter line customer', {
				message: err.message
			})
		}
	}
}

export default fetchLineCustomerWithQuery
