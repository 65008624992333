import { useStoreDiagram } from "..";
import { INTERFACE_MESSAGE } from "../../../../utils/config";
import { nodeProps } from "../../config";
import { getMessageConfigDiagram } from "../global/diagram";

export const createNodeByDragAndDrop = (set, get, mode, position) => {
    try {
        const messages = getMessageConfigDiagram();

        /** If already have intro, do not add a new one */
        if (mode === "INTRO" && messages["INTRO"]) {
            return;
        }

        const newMessage = {
            ...INTERFACE_MESSAGE(mode),
            ...nodeProps,
            position: {
                x: position.x,
                y: position.y,
            },
        };

        useStoreDiagram.getState().addNewNodeInterface([newMessage]);
    } catch (err) {
        console.error("[createNodeByDragAndDrop] ", err);
    }
};
