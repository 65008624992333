import './style.scss'

import React, { useCallback, useEffect, useRef, useState } from 'react'

const VideoPlayer = ({ width, height, src }) => {
	const videoRef = useRef(null)
	const [isPlaying, setIsPlaying] = useState(false)
	const [isSrc, setIsSrc] = useState("")

	const onClickPlay = useCallback(() => {
		setIsPlaying(true)
	}, [])

	useEffect(() => {
		if (src) {
			if (isSrc !== "" && isSrc !== src) {
				setIsPlaying(false)
				videoRef.current.load()
			}
			setIsSrc(src)
			setIsPlaying(true)
		} else {
			setIsPlaying(false)
		}
	}, [src, isSrc])

	if (!isPlaying) {
		return (
			<div
				style={{
					width,
					height,
					backgroundImage: `url('/images/video-thumbnails.png')`,
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
				}}
				onClick={onClickPlay}
			/>
		)
	}

	return (
		<video ref={videoRef} controls width={width} height={height} preload='false'>
			<source src={src} />
		</video>
	)
}

export default VideoPlayer
