import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* requestResetPassword() {
	while (true) {
		yield take(types.REQUEST_RESET_PASSWORD)
		try {
			const form = yield select(selectors.getForgotPasswordForm)
			if (form.email) {
				yield put(actions.isRequestingResetPassword(true))
				yield put(actions.forgotPasswordError(null))
				yield call(api.requestResetPassword, form.email)
				yield put(actions.isRequestingResetPassword(false))
				yield put(actions.isForgotPasswordSuccess(true))
			}
		} catch (err) {
			console.error('[requestResetPassword] ', err)
			yield put(actions.isRequestingResetPassword(false))
			yield put(actions.forgotPasswordError(err.response.data))
			eventlog.error('request reset password', {
				message: err.message
			})
		}
	}
}

export default requestResetPassword
