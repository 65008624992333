import * as actions from "../actions";
import * as api from "../apis";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import getMessageWithTriggers from "./getMessageWithTriggers";
import { call, put, select, take } from "redux-saga/effects";
import { getToolDiagram } from "../../components/diagram/store/global/diagram";

function* savePreviewInBackground() {
	while (true) {
		yield take(types.SAVE_PREVIEW_IN_BACKGROUND);
		try {
			eventlog.info("save config in backgroud");
			yield put(actions.isSavingPreviewInBackground(true));

			const variables = yield select(selectors.getVariables);

			const messagesWithTriggers = getMessageWithTriggers(
				getToolDiagram("getNodes")(),
				getToolDiagram("getEdges")()
			);

			yield call(api.savePreviewConfig, messagesWithTriggers);
			yield call(api.savePreviewVariables, variables);

			yield put(actions.isSavingPreviewInBackground(false));
		} catch (err) {
			const toastManager = yield select(selectors.getToast);
			toastManager.error("Got the error from server");
			yield put(actions.isSavingPreviewInBackground(false));
			console.error("[savePreviewInBackground] ", err);
			eventlog.error("save preview in background", {
				message: err.message,
			});
		}
	}
}

export default savePreviewInBackground;
