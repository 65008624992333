import { getToolDiagram } from "../global/diagram";

export const removeNode = (set, get, id) => {
	try {
		const node = getToolDiagram("getNode")(id);
		if (node) {
			getToolDiagram("deleteElements")({ nodes: [{ id: id }] });
		}
	} catch (err) {
		console.error("[removeNode] ", err);
	}
};
