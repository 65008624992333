import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* onFetchOlderMessages() {
	while (true) {
		const {
			payload: { visitor, oldestMessageId, appId },
		} = yield take(types.ON_FETCH_OLDER_MESSAGES)
		try {
			yield put(actions.isLoadingOlderMessages(true))

			const apps = yield select(selectors.getApps)
			const appSlug = apps.find((app) => app._id === appId).slug

			const { data } = yield call(api.fetctChatMessages, appSlug, visitor._id, oldestMessageId)
			const messages = data || []
			const currentMessages = yield select(selectors.getMessengerMessages)
			const newMessages = [...(currentMessages[visitor._id] || []), ...messages]
			yield put(
				actions.visitorMessages({
					...currentMessages,
					[visitor._id]: newMessages,
				})
			)
			
			yield put(actions.hasMoreMessages(visitor.messages_number !== newMessages.length))
			yield put(actions.isLoadingOlderMessages(false))
		} catch (err) {
			yield put(actions.isLoadingOlderMessages(false))
			console.error('[onFetchOlderMessages] ', err)
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			eventlog.error('fetch older messages', {
				message: err.message
			})
		}
	}
}

export default onFetchOlderMessages
