import * as types from '../types'

const messenger = (
	state = {
		visitors: {},
		messages: {},
		failedMessages: {},
		humantakeover: {}
	},
	action
) => {
	switch (action.type) {
		case types.HUMAN_TAKEOVER:
			return {
				...state,
				humantakeover: action.payload,
			}
		case types.MESSENGER_MANAGER:
			return {
				...state,
				manager: action.payload.manager,
			}
		case types.VISITORS:
			return {
				...state,
				visitors: action.payload.visitors,
			}
		case types.PRESENCE_VISITORS:
			return {
				...state,
				presence: action.payload.presence,
			}
		case types.VISITOR_MESSAGES:
			return {
				...state,
				messages: action.payload.messages,
			}
		case types.SELECTED_VISITOR:
			return {
				...state,
				selectedVisitor: action.payload.visitor,
			}
		case types.IS_LOADING_OLDER_MESSAGES:
			return {
				...state,
				isLoadingOlderMessages: action.payload.status,
			}
		case types.HAS_MORE_MESSAGES:
			return {
				...state,
				hasMoreMessages: action.payload.status,
			}
		case types.HAS_MORE_VISITORS:
			return {
				...state,
				hasMoreVisitors: action.payload.status,
			}
		case types.IS_LOADING_MESSAGES:
			return {
				...state,
				isLoadingMessages: action.payload.status,
			}
		case types.IS_LOADING_VISITORS:
			return {
				...state,
				isLoadingVisitors: action.payload.status,
			}
		case types.IS_LOADING_MORE_VISITORS:
			return {
				...state,
				isLoadingMoreVisitors: action.payload.status,
			}
		case types.FAILED_MESSAGES:
			return {
				...state,
				failedMessages: action.payload.messages,
			}
		default:
			return state
	}
}

export default messenger
