import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* onEnterCollaboratorsPage() {
	while (true) {
		yield take(types.ON_ENTER_COLLABORATORS_PAGE)
		try {
			eventlog.info('enter collaborator page')
			yield put(actions.isFetchingCollaborators(true))
			const { data } = yield call(api.fetchCollaborators)
			yield put(actions.collaborators(data))
			yield put(actions.isFetchingCollaborators(false))
		} catch (err) {
			yield put(actions.isFetchingCollaborators(false))
			console.error('[onEnterCollaboratorsPage] ', err)
			eventlog.error('enter collaborators', {
				message: err.message
			})
		}
	}
}

export default onEnterCollaboratorsPage
