const fiCode = [
    {
        id: 1,
        fi_code: "001",
        name: "BANK OF THAILAND",
    },
    {
        id: 2,
        fi_code: "002",
        name: "BANGKOK BANK PUBLIC COMPANY LTD.",
    },
    {
        id: 3,
        fi_code: "004",
        name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    },
    {
        id: 4,
        fi_code: "006",
        name: "KRUNG THAI BANK PUBLIC COMPANY LTD.",
    },
    {
        id: 5,
        fi_code: "008",
        name: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
    },
    {
        id: 6,
        fi_code: "009",
        name: "OVER SEA-CHINESE BANKING CORPORATION LIMITED",
    },
    {
        id: 7,
        fi_code: "011",
        name: "TMBTHANACHART BANK PUBLIC COMPANY LIMITED",
    },
    {
        id: 8,
        fi_code: "014",
        name: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD.",
    },
    {
        id: 9,
        fi_code: "017",
        name: "CITIBANK, N.A.",
    },
    {
        id: 10,
        fi_code: "018",
        name: "SUMITOMO MITSUI BANKING CORPORATION",
    },
    {
        id: 11,
        fi_code: "020",
        name: "STANDARD CHARTERED BANK (THAI) PUBLIC COMPANY LIMITED",
    },
    {
        id: 12,
        fi_code: "022",
        name: "CIMB THAI BANK PUBLIC COMPANY LIMITED",
    },
    {
        id: 13,
        fi_code: "023",
        name: "RHB BANK BERHAD",
    },
    {
        id: 14,
        fi_code: "024",
        name: "UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED",
    },
    {
        id: 15,
        fi_code: "025",
        name: "BANK OF AYUDHYA PUBLIC COMPANY LTD.",
    },
    {
        id: 16,
        fi_code: "026",
        name: "MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED",
    },
    {
        id: 17,
        fi_code: "027",
        name: "BANK OF AMERICA, NATIONAL ASSOCIATION",
    },
    {
        id: 18,
        fi_code: "029",
        name: "INDIAN OVERSEA BANK",
    },
    {
        id: 19,
        fi_code: "030",
        name: "GOVERNMENT SAVINGS BANK",
    },
    {
        id: 20,
        fi_code: "031",
        name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD.",
    },
    {
        id: 21,
        fi_code: "032",
        name: "DEUTSCHE BANK AG.",
    },
    {
        id: 22,
        fi_code: "033",
        name: "THE GOVERNMENT HOUSING BANK",
    },
    {
        id: 23,
        fi_code: "034",
        name: "BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES",
    },
    {
        id: 24,
        fi_code: "035",
        name: "EXPORT-IMPORT BANK OF THAILAND",
    },
    {
        id: 25,
        fi_code: "039",
        name: "MIZUHO BANK, LTD. BANGKOK BRANCH",
    },
    {
        id: 26,
        fi_code: "045",
        name: "BNP PARIBAS",
    },
    {
        id: 27,
        fi_code: "052",
        name: "BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED",
    },
    {
        id: 28,
        fi_code: "066",
        name: "ISLAMIC BANK OF THAILAND",
    },
    {
        id: 29,
        fi_code: "067",
        name: "TISCO BANK PUBLIC COMPANY LIMITED",
    },
    {
        id: 30,
        fi_code: "069",
        name: "KIATNAKIN PHATRA BANK PUBLIC COMPANY LIMITED",
    },
    {
        id: 31,
        fi_code: "070",
        name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED",
    },
    {
        id: 32,
        fi_code: "071",
        name: "THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED",
    },
    {
        id: 33,
        fi_code: "073",
        name: "LAND AND HOUSES BANK PUBLIC COMPANY LIMITED",
    },
    {
        id: 34,
        fi_code: "080",
        name: "SUMITOMO MITSUI TRUST BANK (THAI) PUBLIC COMPANY LIMITED",
    },
    {
        id: 35,
        fi_code: "093",
        name: "THAI CREDIT GUARANTEE CORPORATION",
    },
    {
        id: 36,
        fi_code: "098",
        name: "SMALL AND MEDIUM ENTERPRISE DEVELOPMENT BANK OF THAILAND",
    },
];

export default fiCode;
