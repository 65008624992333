import * as actions from "../actions";
import * as api from "../apis";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, put, select, take } from "redux-saga/effects";

export function* deployMessageConfig() {
	yield call(api.deployMessageConfig);
}

function* deployMessageConfigSaga() {
	while (true) {
		yield take(types.DEPLOY_MESSAGE_CONFIG);
		try {
			eventlog.info("deploy message config");
			yield put(actions.isDeploying(true));

			yield* deployMessageConfig();

			const toastManager = yield select(selectors.getToast);
			toastManager.success("Deployed Successfully");

			yield put(actions.isDeploying(false));
		} catch (err) {
			const toastManager = yield select(selectors.getToast);
			if (err.message === "Request failed with status code 802") {
				toastManager.error("Current plan feature not support");
			}
			if (err.message === "Request failed with status code 803") {
				toastManager.error("Rich menu exceeded");
			} else {
				toastManager.error("Got the error from server");
			}
			yield put(actions.isDeploying(false));
			console.error("[deployMessageConfig] ", err);
			eventlog.error("deplo message config", {
				message: err.message,
			});
		}
	}
}

export default deployMessageConfigSaga;
