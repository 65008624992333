import INTERFACE_MESSAGE from './config/interfaceMessage'
import advancedMessages from './config/advancedMessages'
import calendar from './config/calendar'
import conditionOperation from './config/conditionOperation'
import design from './config/design'
import input from './config/input'
import inputMode from './config/inputMode'
import messages from './config/messages'
import omiseCurrencies from './config/omiseCurrencies'
import placetypes from './config/placetypes'
import platforms from './config/platforms'
import simpleMessages from './config/simpleMessages'
import country from './config/country'
import fiCode from './config/fiCode'
import genQrPaymentCurrencies from './config/genQrPaymentCurrencies'
import paymentTypes from './config/paymentTypes'
import paymentConfirmationGatewayBank from './config/paymentConfirmationGatewayBank'
import permissions from './config/permissions'
import daysOfWeek from './config/daysOfWeek'
import timeZone from './config/timeZone'
import timeRange from './config/timeRange'
import billingCountry from './config/billingCountry'

const VISITORS_AMOUNT_LOADING_PER_TIME = 15

export {
	input,
	calendar,
	placetypes,
	conditionOperation,
	inputMode,
	simpleMessages,
	advancedMessages,
	messages,
	design,
	INTERFACE_MESSAGE,
	omiseCurrencies,
	VISITORS_AMOUNT_LOADING_PER_TIME,
	platforms,
	country,
	fiCode,
	genQrPaymentCurrencies,
	paymentTypes,
	paymentConfirmationGatewayBank,
	permissions,
	daysOfWeek,
	timeZone,
	timeRange,
	billingCountry,
}
