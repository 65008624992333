export const endDragEdge = (
	set,
	get,
	nodeIdSource,
	handleIdSource,
	nodeIdTarget,
	handleIdTarget
) => {
	try {
		set(() => ({
			isDragingLink: false,
			draftSource: {
				nodeId: nodeIdSource,
				handleId: handleIdSource,
			},
			draftTarget: {
				nodeId: nodeIdTarget,
				handleId: handleIdTarget,
			},
		}));
	} catch (err) {
		console.error("[endDragEdge] ", err);
	}
};
