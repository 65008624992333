import * as actions from '../actions'
import * as apis from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* updateProduct() {
	while (true) {
		try {
			const {
				payload: { product },
			} = yield take(types.UPDATE_PRODUCT)
			eventlog.info('update the product',  product)
			yield call(
				apis.updateProduct,
				product._id,
				product.name,
				product.image,
				parseFloat(product.price),
				product.description,
				product.status
			)
			const { data: products } = yield call(apis.fetchProducts)
			yield put(actions.products(products || []))
		} catch (err) {
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			console.error('[updateProduct] ', err)
			eventlog.error('update product', {
				message: err.message
			})
		}
	}
}

export default updateProduct
