import {
	getMessageConfigDiagram,
	getToolDiagram,
	setMessageConfigDiagram,
} from "../global/diagram";

export const removeHandles = (set, get, id, handleIds) => {
	try {
		const messages = getMessageConfigDiagram();

		handleIds.forEach((handleId) => {
			delete messages[id].handles[handleId];
		});

		setMessageConfigDiagram({
			...messages,
		});

		handleIds.forEach((handleId) => {
			getToolDiagram("setEdges")((eds) =>
				eds.filter(
					(edge) => !(edge.source === id && edge.sourceHandle === handleId)
				)
			);
		});

		getToolDiagram("updateNodeInternals")(id);
	} catch (err) {
		console.error("[removeHandles] ", err);
	}
};
