import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* saveStartOver() {
	while (true) {
		yield take(types.SAVE_START_OVER)
		try {
			eventlog.info('save start over config')
			yield put(actions.updateSaveConfigLoading(true))
			const config = yield select(selectors.getStartOver)
			const response = yield call(api.saveStartOver, config)
			yield put(actions.startOver(response.data))
			const toastManager = yield select(selectors.getToast)
			toastManager.success('Saved successfully')
			yield put(actions.updateSaveConfigLoading(false))
		} catch (err) {
			console.error('[saveStartOver] ', err)
			yield put(actions.updateSaveConfigLoading(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			eventlog.error('save start over', {
				message: err.message
			})
		}
	}
}

export default saveStartOver
