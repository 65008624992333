import * as actions from '../actions'
import * as types from '../types'

import { put, take } from 'redux-saga/effects'

import cloneDeep from '../../utils/cloneDeep'
import eventlog from '../../modules/eventlog'

function* onSelectNode() {
	while (true) {
		const {
			payload: { message },
		} = yield take(types.ON_SELECT_NODE)
		try {
			eventlog.info('select message')
			if (message.mode !== 'UNION-MESSAGE') {
				yield put(actions.editMessage(cloneDeep(message)))
			}
		} catch (err) {
			console.error('[onSelectNode] ', err)
			eventlog.error('select node', {
				message: err.message
			})
		}
	}
}

export default onSelectNode
