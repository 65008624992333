import * as actions from "../actions";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { put, select, take } from "redux-saga/effects";

import {
	getMessageConfigDiagram,
	setMessageConfigDiagram,
} from "../../components/diagram/store/global/diagram";

function* onConfirmEditMap() {
	while (true) {
		const {
			payload: { location },
		} = yield take(types.ON_CONFIRM_EDIT_MAP);
		try {
			const editMap = yield select(selectors.getEditMap);
			const messages = getMessageConfigDiagram();
			const newMessage = {
				...messages[editMap.nodeId],
			};
			newMessage.messages[editMap.messageIndex].location = location;

			setMessageConfigDiagram({
				...messages,
				[editMap.nodeId]: {
					...newMessage,
				},
			});

			yield put(actions.onCloseEditMap(null));
		} catch (err) {
			console.error("[onConfirmEditMap] ", err);
			eventlog.error("confirm edit map", {
				message: err.message,
			});
		}
	}
}

export default onConfirmEditMap;
