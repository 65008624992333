import * as actions from '../actions'
import * as apis from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

function* addCard() {
	while (true) {
		try {
			yield take(types.ADD_CARD)
			yield put(actions.isAddingCard(true))
			const draftCard = yield select(selectors.getDraftCard)
			const { cvc, number, expired, name } = draftCard
			if (!cvc || !number || !expired) continue

			if (!name || name === '') {
				yield put(actions.isAddingCard(false))
				yield put(actions.onCardError({ name: "Name is required" }))
				continue
			}
			actions.onCardError({})

			const [month, year] = expired.split('/').map((e) => e.trim())
			yield call(
				apis.addCard,
				number,
				parseInt(month),
				parseInt('20' + year),
				cvc,
				name,
			)

			const { data: cards } = yield call(apis.getCard)
			yield put(actions.currentCards(cards))
			yield put(actions.draftCard({}))
			yield put(actions.isAddingCard(false))
			yield put(actions.isShowingAddPaymentMethod(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.success('Add card success')
			//yield put(actions.redirect(`/subscription`))
		} catch (err) {
			yield put(actions.isAddingCard(false))
			yield put(actions.addCardError(err.message || err.response.data.detail))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
		}
	}
}

export default addCard
