import * as actions from '../actions'
import * as apis from '../apis'
import * as constants from '../../constants'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* enableFeatureFlag() {
	while (true) {
		const {
			payload: { flag },
		} = yield take(types.ENABLE_FEATURE_FLAG)
		const enablingFeatureFlags = yield select(selectors.getEnablingFeatureFlags)
		try {
			if (flag === constants.featureFlags.chat) {
				yield put(
					actions.enablingFeatureFlag({
						...enablingFeatureFlags,
						chat: true,
					})
				)
				
				yield call(apis.enableChatFeature)
				const { data: featureFlags } = yield call(apis.featureFlags)
				yield put(actions.featureFlags(featureFlags))
				yield put(
					actions.enablingFeatureFlag({
						...enablingFeatureFlags,
						chat: false,
					})
				)
				yield put(actions.startAppChat())
			}
		} catch (err) {
			yield put(
				actions.enablingFeatureFlag({
					...enablingFeatureFlags,
					chat: false,
				})
			)
			console.error('[enableFeatureFlag] ', err)
			eventlog.error('enable feature flag', {
				message: err.message
			})
		}
	}
}

export default enableFeatureFlag
