import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchDatafeedWithQuery() {
	while (true) {
		const { payload: { limit, offset, search } } = yield take(types.DATA_FEED_QUERY)
		try {
			eventlog.info('fetch datafeed')
			yield put(actions.isFetchingDataFeed(true))
			const { data } = yield call(api.fetchDataFeedWithQuery, limit, offset, search)

			yield put(actions.dataFeed(data))
			yield put(actions.isFetchingDataFeed(false))
		} catch (err) {
			yield put(actions.isFetchingDataFeed(false))
			console.error('[onFetchDatafeed] ', err)
			eventlog.error('enter datafeed', {
				message: err.message
			})
		}
	}
}

export default fetchDatafeedWithQuery
