const omiseCurrencies = [
	{
		value: 'AUD',
		label: 'Australian Dollar',
	},
	{
		value: 'CAD',
		label: 'Canadian Dollar',
	},
	{
		value: 'CHF',
		label: 'Swiss Franc',
	},
	{
		value: 'CNY',
		label: 'Yuan Renminbi (Chinese Yuan)',
	},
	{
		value: 'DKK',
		label: 'Danish Krone',
	},
	{
		value: 'EUR',
		label: 'Euro',
	},
	{
		value: 'GBP',
		label: 'Pound Sterling (British Pound)',
	},
	{
		value: 'HKD',
		label: 'Hong Kong Dollar',
	},
	{
		value: 'JPY',
		label: 'Japanese Yen',
	},
	{
		value: 'MYR',
		label: 'Malaysian Ringgit',
	},
	{
		value: 'SGD',
		label: 'Singapore Dollar',
	},
	{
		value: 'THB',
		label: 'Thai Baht',
	},
	{
		value: 'USD',
		label: 'United States Dollar',
	},
]

export default omiseCurrencies
