import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* resetPassword() {
	while (true) {
		yield take(types.RESET_PASSWORD)
		try {
			eventlog.info('reset the password')
			const { password, confirmPassword } = yield select(selectors.getResetPasswordForm)
			if (password !== confirmPassword) {
				yield put(
					actions.resetPasswordError({
						message: 'Password and Confirm password does not match',
					})
				)
				continue
			}
			yield put(actions.isResettingPassword(true))
			yield put(actions.resetPasswordError(null))
			const query = new URLSearchParams(window.location.search)
			yield call(api.resetPassword, password, query.get('access_token'))
			yield put(actions.isResettingPassword(false))
			yield put(actions.isResetPasswordSuccess(true))

			// if redirect is not null, should redirect to that url
			if (query.get('redirect')) {
				window.location.href = query.get('redirect')
			}
		} catch (err) {
			console.error('[requestResetPassword] ', err)
			yield put(actions.isResettingPassword(false))
			yield put(actions.resetPasswordError(err.response.data))
			eventlog.error('resert password', {
				message: err.message
			})
		}
	}
}

export default resetPassword
