import React, { useEffect, useRef, useState } from "react"

import styles from './map.module.scss'
import { useMapsLibrary } from "@vis.gl/react-google-maps"

// This is an example of the classic "Place Autocomplete" widget.
// https://developers.google.com/maps/documentation/javascript/place-autocomplete
export const PlaceAutocompleteClassic = ({ onPlaceSelect }) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null)
  const inputRef = useRef(null)
  const places = useMapsLibrary("places")

  useEffect(() => {
    if (!places || !inputRef.current) return

    const options = {
      fields: ["geometry", "name", "formatted_address"]
    }

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
  }, [places])

  useEffect(() => {
    if (!placeAutocomplete) return

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace())
    })
  }, [onPlaceSelect, placeAutocomplete])

  return (
    <div className="autocomplete-container">
      <input ref={inputRef} className={styles.searchInput} />
    </div>
  )
}
