import * as types from '../types'

const previewConfig = (
	state = {
		previewTimer: {
			period: 5,
			timeLeft: 5,
		},
		isSavingPreview: false,
	},
	action
) => {
	switch (action.type) {
		case types.PREVIEW_TIME_LEFT_CHANGE:
			return {
				...state,
				previewTimer: {
					...state.previewTimer,
					timeLeft: action.payload.timeLeft,
				},
			}
		case types.PREVIEW_PERIOD_TIMER_CHANGE:
			return {
				...state,
				previewTimer: {
					...state.previewTimer,
					period: action.payload.period,
				},
			}
		case types.IS_SAVING_PREVIEW:
			return {
				...state,
				isSavingPreview: action.payload.status,
			}
		case types.IS_SAVING_PREVIEW_IN_BACKGROUND:
			return {
				...state,
				isSavingPreviewInBackground: action.payload.status,
			}
		default:
			return state
	}
}

export default previewConfig
