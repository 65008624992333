import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* linkWithGoogle() {
	while (true) {
		yield take(types.LINK_WITH_GOOGLE)
		try {
			eventlog.info('link app with google account')
			const { data } = yield call(api.linkWithGoogle)
			yield put(actions.googleLinkingUrl(data.google.auth_code_url))
		} catch (err) {
			console.error('[linkWithGoogle] ', err)
			eventlog.error('link with google', {
				message: err.message
			})
		}
	}
}

export default linkWithGoogle
