import * as actions from '../actions'
import * as apis from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

const isBeforeCurrentDate = (mm, yy) => {
	// Create a Date object for the current date
	const currentDate = new Date();

	// Adjust the month value to be zero-based (January is 0, February is 1, etc.)
	const adjustedMonth = mm - 1;

	// Create a Date object for the provided mm/yy combination
	const inputDate = new Date(yy + 2000, adjustedMonth, 1);

	// Compare the two dates
	return inputDate < currentDate;
};

function* editCard() {
	while (true) {
		const {
			payload: { name, year, month },
		} = yield take(types.EDIT_CARD)
		try {
			yield put(actions.isAddingCard(true))

			const errors = {}
			if (!name || name === '') {
				errors.name = "Name is required"
			}

			if (isBeforeCurrentDate(month, year)) {
				errors.date = "The provided date is before the current date."
			}

			if (Object.keys(errors).length > 0) {
				yield put(actions.onCardError(errors))
				yield put(actions.isAddingCard(false))
				continue
			}

			yield put(actions.onCardError({}))

			yield call(
				apis.editCard,
				parseInt(month),
				parseInt('20' + year),
				name,
			)

			const { data: cards } = yield call(apis.getCard)
			yield put(actions.currentCards(cards))

			yield put(actions.isAddingCard(false))
			yield put(actions.isShowingEditPaymentMethod(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.success('Update card success')
			//yield put(actions.redirect(`/subscription`))
		} catch (err) {
			yield put(actions.isAddingCard(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
		}
	}
}

export default editCard
