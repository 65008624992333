import { getToolDiagram } from "../global/diagram";

export const removeNodesDraft = (set, get, ids) => {
	try {
		getToolDiagram("setNodes")((nodes) =>
			nodes.filter((node) => !ids.find((rm) => rm.id === node.id))
		);
	} catch (err) {
		console.error("[removeNodesDraft] ", err);
	}
};
