const linesticker = {
	446: {
		image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1988/android/sticker.png",
		sticker: {
			1988: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1988/android/sticker.png"
			},
			1989: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1989/android/sticker.png"
			},
			1990: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1990/android/sticker.png"
			},
			1991: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1991/android/sticker.png"
			},
			1992: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1992/android/sticker.png"
			},
			1993: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1993/android/sticker.png"
			},
			1994: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1994/android/sticker.png"
			},
			1995: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1995/android/sticker.png"
			},
			1996: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1996/android/sticker.png"
			},
			1997: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1997/android/sticker.png"
			},
			1998: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1998/android/sticker.png"
			},
			1999: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/1999/android/sticker.png"
			},
			2000: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2000/android/sticker.png"
			},
			2001: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2001/android/sticker.png"
			},
			2002: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2002/android/sticker.png"
			},
			2003: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2003/android/sticker.png"
			},
			2004: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2004/android/sticker.png"
			},
			2005: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2005/android/sticker.png"
			},
			2006: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2006/android/sticker.png"
			},
			2007: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2007/android/sticker.png"
			},
			2008: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2008/android/sticker.png"
			},
			2009: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2009/android/sticker.png"
			},
			2010: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2010/android/sticker.png"
			},
			2011: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2011/android/sticker.png"
			},
			2012: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2012/android/sticker.png"
			},
			2013: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2013/android/sticker.png"
			},
			2014: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2014/android/sticker.png"
			},
			2015: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2015/android/sticker.png"
			},
			2016: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2016/android/sticker.png"
			},
			2017: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2017/android/sticker.png"
			},
			2018: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2018/android/sticker.png"
			},
			2019: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2019/android/sticker.png"
			},
			2020: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2020/android/sticker.png"
			},
			2021: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2021/android/sticker.png"
			},
			2022: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2022/android/sticker.png"
			},
			2023: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2023/android/sticker.png"
			},
			2024: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2024/android/sticker.png"
			},
			2025: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2025/android/sticker.png"
			},
			2026: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2026/android/sticker.png"
			},
			2027: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/2027/android/sticker.png"
			}
		}
	},
	789: {
		image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10855/android/sticker.png",
		sticker: {
			10855: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10855/android/sticker.png"
			},
			10856: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10856/android/sticker.png"
			},
			10857: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10857/android/sticker.png"
			},
			10858: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10858/android/sticker.png"
			},
			10859: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10859/android/sticker.png"
			},
			10860: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10860/android/sticker.png"
			},
			10861: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10861/android/sticker.png"
			},
			10862: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10862/android/sticker.png"
			},
			10863: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10863/android/sticker.png"
			},
			10864: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10864/android/sticker.png"
			},
			10865: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10865/android/sticker.png"
			},
			10866: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10866/android/sticker.png"
			},
			10867: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10867/android/sticker.png"
			},
			10868: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10868/android/sticker.png"
			},
			10869: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10869/android/sticker.png"
			},
			10870: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10870/android/sticker.png"
			},
			10871: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10871/android/sticker.png"
			},
			10872: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10872/android/sticker.png"
			},
			10873: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10873/android/sticker.png"
			},
			10874: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10874/android/sticker.png"
			},
			10875: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10875/android/sticker.png"
			},
			10876: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10876/android/sticker.png"
			},
			10877: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10877/android/sticker.png"
			},
			10878: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10878/android/sticker.png"
			},
			10879: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10879/android/sticker.png"
			},
			10880: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10880/android/sticker.png"
			},
			10881: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10881/android/sticker.png"
			},
			10882: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10882/android/sticker.png"
			},
			10883: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10883/android/sticker.png"
			},
			10884: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10884/android/sticker.png"
			},
			10885: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10885/android/sticker.png"
			},
			10886: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10886/android/sticker.png"
			},
			10887: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10887/android/sticker.png"
			},
			10888: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10888/android/sticker.png"
			},
			10889: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10889/android/sticker.png"
			},
			10890: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10890/android/sticker.png"
			},
			10891: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10891/android/sticker.png"
			},
			10892: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10892/android/sticker.png"
			},
			10893: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10893/android/sticker.png"
			},
			10894: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10894/android/sticker.png"
			}
		}
	},
	6136: {
		image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551378/android/sticker.png",
		sticker: {
			10551376: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551376/android/sticker.png"
			},
			10551377: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551377/android/sticker.png"
			},
			10551378: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551378/android/sticker.png"
			},
			10551379: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551379/android/sticker.png"
			},
			10551380: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551380/android/sticker.png"
			},
			10551381: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551381/android/sticker.png"
			},
			10551382: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551382/android/sticker.png"
			},
			10551383: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551383/android/sticker.png"
			},
			10551384: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551384/android/sticker.png"
			},
			10551385: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551385/android/sticker.png"
			},
			10551386: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551386/android/sticker.png"
			},
			10551387: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551387/android/sticker.png"
			},
			10551388: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551388/android/sticker.png"
			},
			10551389: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551389/android/sticker.png"
			},
			10551390: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551390/android/sticker.png"
			},
			10551391: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551391/android/sticker.png"
			},
			10551392: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551392/android/sticker.png"
			},
			10551393: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551393/android/sticker.png"
			},
			10551394: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551394/android/sticker.png"
			},
			10551395: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551395/android/sticker.png"
			},
			10551396: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551396/android/sticker.png"
			},
			10551397: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551397/android/sticker.png"
			},
			10551398: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551398/android/sticker.png"
			},
			10551399: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/10551399/android/sticker.png"
			}
		}
	},
	6370: {
		image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088019/android/sticker.png",
		sticker: {
			11088016: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088016/android/sticker.png"
			},
			11088017: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088017/android/sticker.png"
			},
			11088018: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088018/android/sticker.png"
			},
			11088019: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088019/android/sticker.png"
			},
			11088020: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088020/android/sticker.png"
			},
			11088021: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088021/android/sticker.png"
			},
			11088022: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088022/android/sticker.png"
			},
			11088023: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088023/android/sticker.png"
			},
			11088024: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088024/android/sticker.png"
			},
			11088025: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088025/android/sticker.png"
			},
			11088026: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088026/android/sticker.png"
			},
			11088027: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088027/android/sticker.png"
			},
			11088028: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088028/android/sticker.png"
			},
			11088029: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088029/android/sticker.png"
			},
			11088030: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088030/android/sticker.png"
			},
			11088031: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088031/android/sticker.png"
			},
			11088032: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088032/android/sticker.png"
			},
			11088033: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088033/android/sticker.png"
			},
			11088034: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088034/android/sticker.png"
			},
			11088035: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088035/android/sticker.png"
			},
			11088036: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088036/android/sticker.png"
			},
			11088037: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088037/android/sticker.png"
			},
			11088038: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088038/android/sticker.png"
			},
			11088039: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/11088039/android/sticker.png"
			}
		}
	},
	8522: {
		image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581266/android/sticker.png",
		sticker: {
			16581266: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581266/android/sticker.png"
			},
			16581267: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581267/android/sticker.png"
			},
			16581268: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581268/android/sticker.png"
			},
			16581269: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581269/android/sticker.png"
			},
			16581270: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581270/android/sticker.png"
			},
			16581271: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581271/android/sticker.png"
			},
			16581272: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581272/android/sticker.png"
			},
			16581273: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581273/android/sticker.png"
			},
			16581274: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581274/android/sticker.png"
			},
			16581275: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581275/android/sticker.png"
			},
			16581276: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581276/android/sticker.png"
			},
			16581277: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581277/android/sticker.png"
			},
			16581278: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581278/android/sticker.png"
			},
			16581279: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581279/android/sticker.png"
			},
			16581280: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581280/android/sticker.png"
			},
			16581281: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581281/android/sticker.png"
			},
			16581282: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581282/android/sticker.png"
			},
			16581283: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581283/android/sticker.png"
			},
			16581284: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581284/android/sticker.png"
			},
			16581285: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581285/android/sticker.png"
			},
			16581286: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581286/android/sticker.png"
			},
			16581287: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581287/android/sticker.png"
			},
			16581288: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581288/android/sticker.png"
			},
			16581289: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/16581289/android/sticker.png"
			}
		}
	},
	11537: {
		image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002734/android/sticker.png",
		sticker: {
			52002734: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002734/android/sticker.png"
			},
			52002735: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002735/android/sticker.png"
			},
			52002736: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002736/android/sticker.png"
			},
			52002737: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002737/android/sticker.png"
			},
			52002738: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002738/android/sticker.png"
			},
			52002739: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002739/android/sticker.png"
			},
			52002740: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002740/android/sticker.png"
			},
			52002741: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002741/android/sticker.png"
			},
			52002742: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002742/android/sticker.png"
			},
			52002743: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002743/android/sticker.png"
			},
			52002744: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002744/android/sticker.png"
			},
			52002745: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002745/android/sticker.png"
			},
			52002746: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002746/android/sticker.png"
			},
			52002747: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002747/android/sticker.png"
			},
			52002748: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002748/android/sticker.png"
			},
			52002749: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002749/android/sticker.png"
			},
			52002750: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002750/android/sticker.png"
			},
			52002751: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002751/android/sticker.png"
			},
			52002752: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002752/android/sticker.png"
			},
			52002753: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002753/android/sticker.png"
			},
			52002754: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002754/android/sticker.png"
			},
			52002755: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002755/android/sticker.png"
			},
			52002756: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002756/android/sticker.png"
			},
			52002757: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002757/android/sticker.png"
			},
			52002758: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002758/android/sticker.png"
			},
			52002759: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002759/android/sticker.png"
			},
			52002760: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002760/android/sticker.png"
			},
			52002761: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002761/android/sticker.png"
			},
			52002762: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002762/android/sticker.png"
			},
			52002763: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002763/android/sticker.png"
			},
			52002764: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002764/android/sticker.png"
			},
			52002765: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002765/android/sticker.png"
			},
			52002766: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002766/android/sticker.png"
			},
			52002767: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002767/android/sticker.png"
			},
			52002768: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002768/android/sticker.png"
			},
			52002769: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002769/android/sticker.png"
			},
			52002770: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002770/android/sticker.png"
			},
			52002771: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002771/android/sticker.png"
			},
			52002772: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002772/android/sticker.png"
			},
			52002773: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52002773/android/sticker.png"
			}
		}
	},
	11538: {
		image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626494/android/sticker.png",
		sticker: {
			51626494: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626494/android/sticker.png"
			},
			51626495: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626495/android/sticker.png"
			},
			51626496: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626496/android/sticker.png"
			},
			51626497: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626497/android/sticker.png"
			},
			51626498: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626498/android/sticker.png"
			},
			51626499: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626499/android/sticker.png"
			},
			51626500: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626500/android/sticker.png"
			},
			51626501: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626501/android/sticker.png"
			},
			51626502: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626502/android/sticker.png"
			},
			51626503: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626503/android/sticker.png"
			},
			51626504: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626504/android/sticker.png"
			},
			51626505: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626505/android/sticker.png"
			},
			51626506: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626506/android/sticker.png"
			},
			51626507: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626507/android/sticker.png"
			},
			51626508: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626508/android/sticker.png"
			},
			51626509: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626509/android/sticker.png"
			},
			51626510: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626510/android/sticker.png"
			},
			51626511: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626511/android/sticker.png"
			},
			51626512: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626512/android/sticker.png"
			},
			51626513: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626513/android/sticker.png"
			},
			51626514: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626514/android/sticker.png"
			},
			51626515: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626515/android/sticker.png"
			},
			51626516: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626516/android/sticker.png"
			},
			51626517: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626517/android/sticker.png"
			},
			51626518: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626518/android/sticker.png"
			},
			51626519: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626519/android/sticker.png"
			},
			51626520: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626520/android/sticker.png"
			},
			51626521: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626521/android/sticker.png"
			},
			51626522: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626522/android/sticker.png"
			},
			51626523: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626523/android/sticker.png"
			},
			51626524: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626524/android/sticker.png"
			},
			51626525: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626525/android/sticker.png"
			},
			51626526: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626526/android/sticker.png"
			},
			51626527: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626527/android/sticker.png"
			},
			51626528: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626528/android/sticker.png"
			},
			51626529: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626529/android/sticker.png"
			},
			51626530: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626530/android/sticker.png"
			},
			51626531: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626531/android/sticker.png"
			},
			51626532: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626532/android/sticker.png"
			},
			51626533: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/51626533/android/sticker.png"
			}
		}
	},
	11539: {
		image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114110/android/sticker.png",
		sticker: {
			52114110: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114110/android/sticker.png"
			},
			52114111: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114111/android/sticker.png"
			},
			52114112: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114112/android/sticker.png"
			},
			52114113: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114113/android/sticker.png"
			},
			52114114: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114114/android/sticker.png"
			},
			52114115: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114115/android/sticker.png"
			},
			52114116: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114116/android/sticker.png"
			},
			52114117: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114117/android/sticker.png"
			},
			52114118: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114118/android/sticker.png"
			},
			52114119: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114119/android/sticker.png"
			},
			52114120: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114120/android/sticker.png"
			},
			52114121: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114121/android/sticker.png"
			},
			52114122: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114122/android/sticker.png"
			},
			52114123: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114123/android/sticker.png"
			},
			52114124: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114124/android/sticker.png"
			},
			52114125: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114125/android/sticker.png"
			},
			52114126: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114126/android/sticker.png"
			},
			52114127: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114127/android/sticker.png"
			},
			52114128: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114128/android/sticker.png"
			},
			52114129: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114129/android/sticker.png"
			},
			52114130: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114130/android/sticker.png"
			},
			52114131: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114131/android/sticker.png"
			},
			52114132: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114132/android/sticker.png"
			},
			52114133: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114133/android/sticker.png"
			},
			52114134: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114134/android/sticker.png"
			},
			52114135: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114135/android/sticker.png"
			},
			52114136: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114136/android/sticker.png"
			},
			52114137: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114137/android/sticker.png"
			},
			52114138: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114138/android/sticker.png"
			},
			52114139: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114139/android/sticker.png"
			},
			52114140: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114140/android/sticker.png"
			},
			52114141: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114141/android/sticker.png"
			},
			52114142: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114142/android/sticker.png"
			},
			52114143: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114143/android/sticker.png"
			},
			52114144: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114144/android/sticker.png"
			},
			52114145: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114145/android/sticker.png"
			},
			52114146: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114146/android/sticker.png"
			},
			52114147: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114147/android/sticker.png"
			},
			52114148: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114148/android/sticker.png"
			},
			52114149: {
				image: "https://stickershop.line-scdn.net/stickershop/v1/sticker/52114149/android/sticker.png"
			}
		}
	}
}

export default linesticker