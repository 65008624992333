import React from 'react'

const Search = ({ value, onChange }) => {
	return (
		<div className='transparent dropdown-item variable-dropdown-item'>
			<span className='svg-icon svg-icon--search choices-search__search-icon'>
				<svg viewBox='0 0 512 512'>
					<path d='M435 219c0-110-89-199-199-199S37 109 37 219s89 199 199 199 199-89 199-199zM236 333c-63 0-114-51-114-114s51-114 114-114 114 51 114 114-51 114-114 114zm142 115c14 15 42 44 42 44 16 16 43 17 60 1 17-17 18-44 1-61l-95-99c-16-17-43-17-60-1-17 17-18 44-1 61 0 0 35 37 53 55z'></path>
				</svg>
			</span>
			<input
				onChange={onChange}
				type='text'
				placeholder='Search variables'
				className='choice-search-input'
				style={{ width: '100%' }}
			/>
		</div>
	)
}

export default Search
