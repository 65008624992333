import * as actions from "../actions";
import * as api from "../apis";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import getMessageWithTriggers from "./getMessageWithTriggers";
import { call, put, select, take } from "redux-saga/effects";
import { getToolDiagram } from "../../components/diagram/store/global/diagram";

export function* saveMessageConfig() {
	const messagesWithTriggers = getMessageWithTriggers(
		getToolDiagram("getNodes")(),
		getToolDiagram("getEdges")()
	);

	yield call(api.saveMessageConfig, messagesWithTriggers);

	const removedMessages = yield select(selectors.getRemovedMessages);
	yield call(api.updateRemovedMessages, removedMessages);

	const designConfig = yield select(selectors.getDesignConfig);
	yield call(api.updateDesignConfig, designConfig);
}

function* saveMessageConfigSaga() {
	while (true) {
		yield take(types.SAVE_MESSAGE_CONFIG);
		try {
			eventlog.info("save message config");
			yield put(actions.isSaving(true));

			yield* saveMessageConfig();

			yield put(actions.saveVariables());

			const toastManager = yield select(selectors.getToast);
			toastManager.success("Saved Successfully");

			yield put(actions.isSaving(false));
		} catch (err) {
			const toastManager = yield select(selectors.getToast);
			if (err.message === "Request failed with status code 802") {
				toastManager.error("Current plan feature not support");
			}
			if (err.message === "Request failed with status code 803") {
				toastManager.error("Rich menu exceeded");
			} else {
				toastManager.error("Got the error from server");
			}
			yield put(actions.isSaving(false));
			console.error("[saveMessageConfig] ", err);
			eventlog.error("save message config", {
				message: err.message,
			});
		}
	}
}

export default saveMessageConfigSaga;
