import generateID from "../../../../utils/generateID";
import { useStoreDiagram } from "..";
import { nodeId, nodeProps } from "../../config";
import { getMessageConfigDiagram } from "../global/diagram";

export const pasteNode = (set, get, nodes, isMousePositionCenter) => {
    try {
        const messages = getMessageConfigDiagram();

        const newMessages = nodes.map((node) => {
            const hasEnd = node.id.indexOf(nodeId.END) !== -1;
            let id = "";

            if (hasEnd) {
                id = `ms_${generateID()}${nodeId.END}`;
            } else {
                id = `ms_${generateID()}`;
            }

            const newMessage = {
                ...node,
                ...messages[node.id],
                ...nodeProps,
                id,
                position: {
                    x: isMousePositionCenter
                        ? window.mousePositionCenterDiagram.current.x
                        : window.mousePositionDiagram.current.x,
                    y: isMousePositionCenter
                        ? window.mousePositionCenterDiagram.current.y
                        : window.mousePositionDiagram.current.y,
                },
                trigger: null,
                selected: true,
            };

            return newMessage;
        });

        useStoreDiagram.getState().addNewNodeInterface([...newMessages]);
    } catch (err) {
        console.error("[pasteNode] ", err);
    }
};
