import { getToolDiagram } from "../global/diagram";

export const removeEdgeByNodeId = (set, get, id) => {
	try {
		getToolDiagram("getEdges")()
			.filter((edge) => edge.source === id)
			.forEach((edge) => {
				getToolDiagram("deleteElements")({ edges: [{ id: edge.id }] });
			});
	} catch (err) {
		console.error("[removeEdgeByNodeId] ", err);
	}
};
