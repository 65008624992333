import {
	getMessageConfigDiagram,
	getToolDiagram,
	setMessageConfigDiagram,
} from "../global/diagram";

export const addHandles = (set, get, id, handleIds) => {
	try {
		const fromName = "addHandles";
		const messages = getMessageConfigDiagram();

		handleIds.forEach((handleId) => {
			messages[id].handles[handleId] = fromName;
		});

		setMessageConfigDiagram({
			...messages,
		});

		getToolDiagram("updateNodeInternals")(id);
	} catch (err) {
		console.error("[addHandles] ", err);
	}
};
