import * as constants from "../../../../constants";
import ContextualZoom from "../system/contextualZoom";
import CopyIcon from "../../../../images/copy";
import DuplicateIcon from "../../../../images/duplicate";
import HandleSource from "../system/handle/handleSource";
import HandleTarget from "../system/handle/handleTarget";
import React, { useCallback, useState } from "react";
import Setting from "../../../../assets/svg/Setting";
import TrashIcon from "../../../../images/trash";
import { Position } from "@xyflow/react";
import { useTranslation } from "react-i18next";

import {
	contextualZoomSize,
	contextualZoomStyle,
	handlePortId,
} from "../../config";

const ProductCarouselTemplate = ({
	message,
	copyToClipBoard,
	copyComponentNode,
	removeComponentNode,
	onSelectNode,
	detail,
	onHandleUpdate,
	currentAppPlan,
	isTarget,
}) => {
	const { t } = useTranslation(["subscription"]);
	const [helptext, setHelptext] = useState("");

	const handleUpdateHelpText = useCallback(() => {
		onHandleUpdate({
			helptext: helptext,
		});
	}, [onHandleUpdate, helptext]);

	const proPlan = currentAppPlan.pro.some((item) => item === message.mode);
	const starterPlan = currentAppPlan.starter.some(
		(item) => item === message.mode
	);

	return (
		<div>
			<div
				className="question-message-block custom-drag-handle"
				id={`node-${message.id}`}
				style={contextualZoomStyle}
			>
				<ContextualZoom isTarget={isTarget} size={contextualZoomSize.medium}>
					<div className="message-menu">
						<div className="message-menu-body">
							<h6 className="message-id">{message.id}</h6>
							<button
								className="transparent"
								onClick={() => copyToClipBoard(message.id)}
							>
								<CopyIcon />
							</button>
							<button
								className="transparent"
								onClick={() => copyComponentNode(message.id)}
							>
								<DuplicateIcon />
							</button>
							<button
								className="transparent"
								onClick={() => removeComponentNode(message.id)}
							>
								<TrashIcon />
							</button>
						</div>
					</div>
					<div className={isTarget ? "entry-port-container" : ""}>
						<HandleTarget
							id={handlePortId.entry}
							position={Position.Left}
							isTarget={isTarget}
						/>
					</div>

					<div className="header">
						{proPlan && <div className="plan-tag">PRO</div>}
						{starterPlan && <div className="plan-tag">STARTER</div>}
						<div className="icon">
							{!!detail.image && (
								<div
									className="img"
									style={{
										backgroundImage: `url('${detail.image}')`,
									}}
								/>
							)}
							{!!detail.icon && detail.icon}
						</div>
						<div className="detail">
							<div className="header-and-messageId">
								<h6 style={{ marginLeft: "12px" }}>
									{t(`features.${detail.slug}`)}
								</h6>
								{/* <h6>{message.id}</h6> */}
							</div>
							<p
								className="nodrag"
								contentEditable="true"
								suppressContentEditableWarning={true}
								onInput={(e) => {
									setHelptext(e.currentTarget.textContent);
								}}
								onBlur={handleUpdateHelpText}
							>
								{message.helptext ||
									message.question ||
									t(`${constants.defaultHelptext}`)}
							</p>
						</div>
						<div className="settings" onClick={() => onSelectNode(message)}>
							<Setting />
						</div>
					</div>
					<div style={{ background: "#edeef9" }}>
						<div className="button-trigger-container">
							<div className="item default">
								{t(`afterSelect`)}
								<div className="piece-end-point">
									<HandleSource
										id={handlePortId.select}
										position={Position.Right}
										isTarget={isTarget}
									/>
									<div className="piece-end-point__drag-helper">
										<img
											alt=""
											className="piece-end-point__drag-helper-img"
											src="/images/help-arrow.png"
										/>
									</div>
								</div>
							</div>
							<div className="item next-message">
								{t(`nextMessage`)}
								<div className="piece-end-point">
									<HandleSource
										id={handlePortId.right}
										position={Position.Right}
										isTarget={isTarget}
									/>
									<div className="piece-end-point__drag-helper">
										<img
											alt=""
											className="piece-end-point__drag-helper-img"
											src="/images/help-arrow.png"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ContextualZoom>
			</div>
		</div>
	);
};

export default ProductCarouselTemplate;
