import * as actions from '../actions'
import * as apis from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'
import { renewToken } from './renewToken'

function* createNewBot() {
	while (true) {
		try {
			yield take(types.CREATE_NEW_BOT)

			eventlog.info('create an app')

			const draftNewBot = yield select(selectors.getDraftNewBot)
			if (!draftNewBot.app.name || !draftNewBot.app.slug) {
				yield put(
					actions.draftNewBot({
						...draftNewBot,
						errors: {
							name: !draftNewBot.app.name
								? 'This field could not be blank'
								: draftNewBot.errors.name,
							slug: !draftNewBot.app.slug
								? 'This field could not be blank'
								: draftNewBot.errors.slug,
						},
					})
				)
				continue
			}

			if (draftNewBot.errors.name || draftNewBot.errors.slug) {
				continue
			}

			const templateConfig = yield select(selectors.getTemplateConfig)
			const template = templateConfig[draftNewBot.selected]

			yield put(
				actions.draftNewBot({
					...draftNewBot,
					page: 0,
				})
			)

			const { app } = draftNewBot

			yield call(apis.createApp, {
				...app,
				template_id: template._id,
				is_template: app.isTemplate
			})

			yield put(actions.renewToken())
			const response = yield call(apis.fetchApps)
			yield put(actions.apps(response.data))

			yield put(
				actions.draftNewBot({
					page: 1,
					icon: '',
					app: {
						name: '',
						icon: '',
						description: '',
						platform: 'web',
					},
					dirty: {},
					errors: {},
				})
			)
			yield put(actions.isShowCreateNewBotModal(false))
			yield* renewToken()
			yield put(actions.redirect(app.slug))
		} catch (err) {
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			console.error('[createNewBot] ', err)

			const draftNewBot = yield select(selectors.getDraftNewBot)
			yield put(
				actions.draftNewBot({
					...draftNewBot,
					page: 2,
				})
			)

			eventlog.error('create new bot', {
				message: err.message
			})
		}
	}
}

export default createNewBot
