import * as actions from '../actions'
import * as apis from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import validation from './saveBillingInformation/validation'

function* changePlan() {
	while (true) {
		try {
			const { payload: { planID, billingCycle } } = yield take(types.CHANGE_PLAN)
			yield put(actions.onChangingPlan(true))
			const billingInformation = yield select(selectors.getBillingInformation)
			const errors = yield call(validation, billingInformation)

			if (Object.keys(errors).length > 0) {
				yield put(actions.onBillingInformationError(errors))
				continue
			}

			yield put(actions.onBillingInformationError({}))
			yield call(apis.onSaveBillingInformation, billingInformation)
			const { data } = yield call(apis.getBillingInformation)
			yield put(actions.storeBillingInformation(data))

			const currentCards = yield select(selectors.getCurrentCards)
			if (currentCards.data === "") {
				yield put(actions.onChangingPlan(false))
				const toastManager = yield select(selectors.getToast)
				toastManager.error('Please add payment add')
				continue
			}

			yield call(apis.changePlan, planID, billingCycle)

			const { data: currentPlan } = yield call(apis.getCurrentPlan)
			yield put(actions.selectedPlan(currentPlan))

			try {
				const { data: currenteSchedule } = yield call(apis.getCurrenteSchedule)
				yield put(actions.currentSchedule(currenteSchedule))
			} catch (e) {

			}
			yield put(actions.onChangingPlan(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.success('Upgrede plan success')

			const { data: invoicesSubscription } = yield call(apis.getInvoicesSubscription, 'paid')
			yield put(actions.storeInvoicesStatusPaidSubscription(invoicesSubscription))

			yield put(actions.redirect(`/subscription`))

		} catch (err) {
			yield put(actions.onChangingPlan(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
		}
	}
}

export default changePlan
