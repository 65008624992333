import * as actions from "../actions";
import * as api from "../apis";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import parseJwtPayload from "../../utils/parseJwtPayload";
import { put, take } from "redux-saga/effects";

function* startApp() {
	while (true) {
		yield take(types.START_APP)
		try {
			eventlog.info('start the app')
			// renew token if token is existing
			const token = api.getToken()
			if (token) {
				const payload = parseJwtPayload(token)
				eventlog.addContext('userId', payload.sub)
				eventlog.info('start the app')
				yield put(actions.renewToken())
				yield put(actions.storeUserId(payload.sub))
				yield put(actions.storeUniqueID(crypto.randomUUID()))
			}

			yield put(actions.fetchPublicData())
		} catch (err) {
			console.error('[startApp] ', err)
			eventlog.error('start app', {
				message: err.message
			})
		}
	}
}

export default startApp
