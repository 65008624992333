export const unSelectedNode = (set, get, nodes, userID) => {
	try {
		const selectedNodesTemp2 = get().selectedNodes;

		nodes.forEach((node) => {
			const selectedNodesTemp = selectedNodesTemp2[userID];
			if (selectedNodesTemp) {
				delete selectedNodesTemp[node.id];
				selectedNodesTemp2[userID] = selectedNodesTemp;
			}
		});

		set({
			selectedNodes: { ...selectedNodesTemp2 },
		});
	} catch (err) {
		console.error("[unSelectedNode] ", err);
	}
};
