import * as actions from "../actions";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { put, take } from "redux-saga/effects";
import { useStoreDiagram } from "../../components/diagram/store";
import { transformMessageConfig } from "../../components/diagram/utils/transform";

function* selectSaveDiagramHistory() {
	while (true) {
		const {
			payload: { messageConfig, isFromPreview },
		} = yield take(types.SELECT_SAVE_DIAGRAM_HISTORY);
		try {
			const messageSaveDiagramHistory = transformMessageConfig(messageConfig);

			if (messageSaveDiagramHistory) {
				if (!isFromPreview) {
					yield put(actions.messageConfigBackupDiagramHistory(null));
					yield put(actions.toggleSaveDiagramHistory());
				}

				useStoreDiagram.getState().removeAllNodeEdgeSelect();

				setTimeout(() => {
					useStoreDiagram
						.getState()
						.addNewNodeInterface(
							Object.values(messageSaveDiagramHistory),
							true
						);
				}, 100);
			} else {
				continue;
			}
		} catch (err) {
			console.error("[selectSaveDiagramHistory] ", err);
			eventlog.error("preview Save Diagram History", {
				message: err.message,
			});
		}
	}
}

export default selectSaveDiagramHistory;
