export const startDragEdge = (set, get) => {
	try {
		set(() => ({
			isDragingLink: true,
			draftSource: {},
			draftTarget: {},
		}));
	} catch (err) {
		console.error("[startDragEdge] ", err);
	}
};
