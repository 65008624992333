const lineCarouselTemplate = (editMessage) => {
	const errors = new Array(10);

	let isImage = 0
	let isNotImage = 0
	editMessage.config.columns.forEach((configItem, index) => {
		const error = {};
		if (configItem.thumbnailImageUrl) {
			isImage++
		} if (!configItem.thumbnailImageUrl) {
			isNotImage++
		}

		if (configItem.title && configItem.title.length > 40) {
			error.title = `title`;
		}

		if (configItem.thumbnailImageUrl && configItem.thumbnailImageUrl.length > 2000) {
			error.thumbnailImageUrl = `imageUrl`;
		}

		if (!configItem.text) {
			error.text = `textRequired`;
		} else {
			if (!configItem.title || !configItem.thumbnailImageUrl) {
				if (!configItem.text || configItem.text.length > 120) {
					error.text = `textWithoutImage`;
				}
			} else {
				if (!configItem.text || configItem.text.length > 60) {
					error.text = `text`;
				}
			}
		}

		if (Object.keys(error).length > 0) {
			errors[index] = error
		}
	});

	if (isImage > 0 && isNotImage > 0) {
		editMessage.config.columns.forEach((configItem, index) => {
			const error = {};
			error.thumbnailImageUrl = `thumbnailImageUrl`;
			errors[index] = {
				...errors[index],
				...error,
			};
		});
	}

	return errors;
};


export default lineCarouselTemplate