const country = [
    {
        id: 1,
        iso2: 'TH',
        iso3: 'THA',
        calling_code: 66,
        slug: "thailand"
    }
]

export default country