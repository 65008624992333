import * as actions from "../actions";
import * as api from "../apis";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { store } from "..";
import { put, select, take } from "redux-saga/effects";

// initial cancel token to avoid undefine for first call
let controller = {
	abort: (reason) => { },
};

function* validateSlug() {
	while (true) {
		yield take(types.VALIDATE_SLUG);
		try {
			const draftNewBot = yield select(selectors.getDraftNewBot);
			// cancel the request
			controller.abort();

			if (!draftNewBot.app.slug) {
				yield put(actions.isValidatingSlug(false));
				continue;
			}

			controller = new AbortController();

			api.slugValidation(draftNewBot.app.slug, controller).then(
				() => {
					store.dispatch(
						actions.draftNewBot({
							...draftNewBot,
							errors: {
								...draftNewBot.errors,
								slug: "",
							},
						})
					);
					store.dispatch(actions.isValidatingSlug(false));
				},
				(err) => {
					let errorMsg = "Got error from the server";
					if (err && err.response.status === 422) {
						errorMsg = err.response.data.detail;
					}

					store.dispatch(
						actions.draftNewBot({
							...draftNewBot,
							errors: {
								...draftNewBot.errors,
								slug: errorMsg,
							},
						})
					);
					store.dispatch(actions.isValidatingSlug(false));
				}
			);
		} catch (err) {
			console.error("[validateSlug] ", err);
			eventlog.error("validate slug", {
				message: err.message,
			});
		}
	}
}

export default validateSlug;
