import * as actions from "../actions";
import * as api from "../apis";
import * as types from "../types";
import _ from "lodash";
import eventlog from "../../modules/eventlog";
import { call, put, take } from "redux-saga/effects";
import { getMessageConfigDiagram } from "../../components/diagram/store/global/diagram";

function* fetchModuleResponses() {
	while (true) {
		yield take(types.FETCH_MODULE_RESPONSES);
		try {
			yield put(actions.isFetchingModuleResponses(true));
			const { data } = yield call(api.fetchModuleResponses);
			const messages = getMessageConfigDiagram();

			const stats = data.stats.reduce((o, stat) => {
				return {
					...o,
					[stat._id]: stat,
				};
			}, {});

			const moduleResponses = Object.values(messages)
				.filter((message) => {
					return message.mode === "MODULE-RESPONSE";
				})
				.map((message) => {
					return {
						id: message.id,
						title: message.config.title,
						description: message.config.description,
						all: _.get(stats, `${message.id}.all`, 0),
						today: _.get(stats, `${message.id}.today`, 0),
					};
				});

			yield put(actions.moduleResponses(moduleResponses));
			yield put(actions.isFetchingModuleResponses(false));
		} catch (err) {
			yield put(actions.isFetchingModuleResponses(false));
			eventlog.error("fetch module responses", {
				message: err.message,
			});
		}
	}
}

export default fetchModuleResponses;
