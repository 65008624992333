import * as actions from "../actions";
import * as api from "../apis";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, put, take } from "redux-saga/effects";

function* generateInvitationURL() {
	while (true) {
		const {
			payload: { role },
		} = yield take(types.GENERATE_INVITATION_LINK);
		try {
			const { data: response } = yield call(api.generateInviteUrl, role);
			yield put(actions.storeInvitationURL(response.url));
		} catch (err) {
			console.error("[generateInvitationURL] ", err);
			eventlog.error("generate invitation url", {
				message: err.message,
			});
		}
	}
}

export default generateInvitationURL;
