const input = {
	types: [
		{
			value: '-',
			label: '-',
		},
		{
			value: 'email',
			label: 'Email',
		},
		{
			value: 'number',
			label: 'Number',
		},
		{
			value: 'decimal',
			label: 'Decimal',
		},
		{
			value: 'phone',
			label: 'Phone',
		},
		{
			value: 'url',
			label: 'Url',
		},
		{
			value: 'name',
			label: 'Name',
		},
	],
}

export default input
