import * as selectors from '../selectors'
import * as types from '../types'

import { select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* unsubcribeChannel() {
	while (true) {
		const {
			payload: { name },
		} = yield take(types.UNSUBCRIBE_CHANNEL)
		try {
			const selectedApp = yield select(selectors.getSelectedApp)
			const messengerManagers = yield select(selectors.getMessengerManager)

			if (messengerManagers && name) {
				const messengerManager = messengerManagers[selectedApp._id]
				messengerManager.unsubscribe(name)
			}
		} catch (err) {
			console.error('[unsubcribeChannel] ', err)
			eventlog.error('unsubscribe chat', {
				message: err.message
			})
		}
	}
}

export default unsubcribeChannel
