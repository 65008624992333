const conditionOperation = {
    'Equal to': {
        value: 'Equal to',
        label: 'Equal to',
        from: 'any',
        to: 'any',
        isRequireValue: true
    },
    'Does not equal to': {
        value: 'Does not equal to',
        label: 'Does not equal to',
        from: 'any',
        to: 'any',
        isRequireValue: true
    },
    '(Text) Contains': {
        value: '(Text) Contains',
        label: '(Text) Contains',
        from: 'string',
        to: 'string',
        isRequireValue: true
    },
    '(Text) Does not contain': {
        value: '(Text) Does not contain',
        label: '(Text) Does not contain',
        from: 'string',
        to: 'string',
        isRequireValue: true
    },
    '(Text) Exactly matchs': {
        value: '(Text) Exactly matchs',
        label: '(Text) Exactly matchs',
        from: 'string',
        to: 'string',
        isRequireValue: true
    },
    '(Text) Does not exactly match': {
        value: '(Text) Does not exactly match',
        label: '(Text) Does not exactly match',
        from: 'string',
        to: 'string',
        isRequireValue: true
    },
    '(Text) Is in': {
        value: '(Text) Is in',
        label: '(Text) Is in',
        from: 'string',
        to: 'string',
        isRequireValue: true
    },
    '(Text) Is not in': {
        value: '(Text) Is not in',
        label: '(Text) Is not in',
        from: 'string',
        to: 'string',
        isRequireValue: true
    },
    '(Text) Starts with': {
        value: '(Text) Starts with',
        label: '(Text) Starts with',
        from: 'string',
        to: 'string',
        isRequireValue: true
    },
    '(Text) Does not start with': {
        value: '(Text) Does not start with',
        label: '(Text) Does not start with',
        from: 'string',
        to: 'string',
        isRequireValue: true
    },
    '(Text) Ends with': {
        value: '(Text) Ends with',
        label: '(Text) Ends with',
        from: 'string',
        to: 'string',
        isRequireValue: true
    },
    '(Text) Does not end with': {
        value: '(Text) Does not end with',
        label: '(Text) Does not end with',
        from: 'string',
        to: 'string',
        isRequireValue: true
    },
    '(Number) Greater than': {
        value: '(Number) Greater than',
        label: '(Number) Greater than',
        from: 'number',
        to: 'number',
        isRequireValue: true
    },
    '(Number) Less than': {
        value: '(Number) Less than',
        label: '(Number) Less than',
        from: 'number',
        to: 'number',
        isRequireValue: true
    },
    '(Date/time) After': {
        value: '(Date/time) After',
        label: '(Date/time) After',
        from: 'datetime',
        to: 'datetime',
        isRequireValue: true
    },
    '(Date/time) Before': {
        value: '(Date/time) Before',
        label: '(Date/time) Before',
        from: 'datetime',
        to: 'datetime',
        isRequireValue: true
    },
    '(Boolean) Is true': {
        value: '(Boolean) Is true',
        label: '(Boolean) Is true',
        from: 'boolean',
        to: 'boolean',
        isRequireValue: false
    },
    '(Boolean) Is false': {
        value: '(Boolean) Is false',
        label: '(Boolean) Is false',
        from: 'boolean',
        to: 'boolean',
        isRequireValue: false
    },
    '(Array) Includes': {
        value: '(Array) Includes',
        label: '(Array) Includes',
        from: 'boolean',
        to: 'any',
        isRequireValue: true
    },
    '(Array) Does not include': {
        value: '(Array) Does not include',
        label: '(Array) Does not include',
        from: 'boolean',
        to: 'any',
        isRequireValue: true
    },
    'Exists': {
        value: 'Exists',
        label: 'Exists',
        from: 'any',
        isRequireValue: false
    },
    'Does not exist': {
        value: 'Does not exist',
        label: 'Does not exist',
        from: 'any',
        isRequireValue: false
    }
}

export default conditionOperation