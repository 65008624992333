import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchDesignConfig() {
	while (true) {
		yield take(types.FETCH_DESIGN_CONFIG)
		try {
			const { data } = yield call(api.designConfig)
			yield put(actions.design(data))
		} catch (err) {
			console.error('[fetchDesignConfig] ', err)
			eventlog.error('fetch design config', {
				message: err.message
			})
		}
	}
}

export default fetchDesignConfig
