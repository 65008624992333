export const removeStatePastFuture = (set, get, id) => {
	try {
		set((state) => ({
			past: state.past.filter(pastNow => pastNow.id !== id),
		}));

		set((state) => ({
			future: state.future.filter(futureNow => futureNow.id !== id),
		}));
	} catch (err) {
		console.error("[removeStatePastFuture] ", err);
	}
};
