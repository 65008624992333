import * as actions from '../actions'
import * as apis from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* unlinkWithGoogle() {
	while (true) {
		yield take(types.UNLINK_WITH_GOOGLE)
		try {
			eventlog.info('unlink the app with google')
			yield call(apis.unlinkWithGoogle)
			yield put(actions.googleLinkingDetail(null))
		} catch (err) {
			console.error('[unlinkWithGoogle] ', err)
			eventlog.error('unlink with google', {
				message: err.message
			})
		}
	}
}

export default unlinkWithGoogle
