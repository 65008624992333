import * as actions from '../actions'
import * as apis from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* updateProduct() {
	while (true) {
		try {
			const {
				payload: { id, url },
			} = yield take(types.INVOICE_ACTACH_FILE_CHANGE)
			eventlog.info('update the invoice')
			yield call(
				apis.updateInvoice,
				id,
				url,
			)

			const { data: invoicesStatusUnpaidSubscription } = yield call(apis.getInvoicesSubscription, 'unpaid')
			yield put(actions.storeInvoicesStatusUnpaidSubscription(invoicesStatusUnpaidSubscription || []))

		} catch (err) {
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			console.error('[updateInvoice] ', err)
			eventlog.error('update invoice', {
				message: err.message
			})
		}
	}
}

export default updateProduct
