import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* copyApp() {
	while (true) {
		const {
			payload: { appId },
		} = yield take(types.COPY_APP)
		try {
			eventlog.info('copy an app', {
				appId
			})
			
			yield put(actions.copyingApp(true))

			yield call(api.copyApp, appId)

			yield put(actions.renewToken())

			const response = yield call(api.fetchApps)
			yield put(actions.apps(response.data))

			yield put(actions.copyingApp(false))
		} catch (err) {
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			yield put(actions.copyingApp(false))
			console.error('[copyApp] ', err)
			eventlog.error('copy app', {
				message: err.message
			})
		}
	}
}

export default copyApp
