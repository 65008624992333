import React, { memo } from "react";
import { BaseEdge, getStraightPath } from "@xyflow/react";

function CustomEdge({ sourceX, sourceY, targetX, targetY, style = {} }) {
  sourceX -= 10;
  targetX += 10;
  
  const [edgePath] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <BaseEdge path={edgePath} style={style} />
    </>
  );
}

export default memo(CustomEdge);
