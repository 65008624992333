import CustomHandle from "./customHandle";
import React from "react";
import { Position } from "@xyflow/react";
import { handleConnectableMax } from "../../../config";

function HandleTarget(props) {
    const { keyUnique = crypto.randomUUID(), id, isTarget, position = Position.Left, isConnectable = handleConnectableMax.xtra } = props;

    return (
        <CustomHandle
            key={keyUnique}
            id={id}
            type="target"
            position={position}
            isConnectable={isConnectable}
            style={
                isTarget
                    ? {
                        width: "110%",
                        height: "110%",
                        border: "none",
                        borderRadius: "0",
                        backgroundColor: "transparent",
                        animation: "none",
                        color: "transparent",
                        opacity: "0",
                        transform: "translate(-5%, -50%)",
                    }
                    : {}
            }
        />
    );
}
export default HandleTarget;
