import * as types from '../types'

import eventlog from '../../modules/eventlog'
import { take } from 'redux-saga/effects'

const postMessageToPreviewWindow = (iframeId, source, data) => {
	try {
		window.frames[iframeId].postMessage(
			{
				source,
				data,
			},
			'*'
		)
	} catch (err) {
		console.error('[post message to preview window error] ', err)
	}
}

function* postMessageToIframe() {
	while (true) {
		const {
			payload: { iframeId, source, data },
		} = yield take(types.POST_MESSAGE_TO_IFRAME)
		try {
			postMessageToPreviewWindow(iframeId, source, data)
		} catch (err) {
			console.error('[postMessageToIframe] ', err)
			eventlog.error('post message to iframe', {
				message: err.message
			})
		}
	}
}

export default postMessageToIframe
