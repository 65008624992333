import * as actions from "../actions";
import * as api from "../apis";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, put, take } from "redux-saga/effects";

function* refreshLineConfigToken() {
	while (true) {
		yield take(types.REFRESH_LINE_CONFIG_TOKEN);
		try {
			eventlog.info("refresh line config token");

			yield call(api.refreshLineConfigToken);
			const lineConfig = yield call(api.fetchLineConfig);

			yield put(actions.lineConfig(lineConfig));
		} catch (err) {
			console.error("[refreshLineConfigToken] ", err);

			eventlog.error("refresh line config token", {
				message: err.message,
			});
		}
	}
}

export default refreshLineConfigToken;
