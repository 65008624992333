import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* enterInvitationPage() {
	while (true) {
		try {
			yield take(types.ENTER_INVITATION_PAGE)
			eventlog.info('enter invitaion page')
			const query = new URLSearchParams(window.location.search)
			const { data: app } = yield call(api.fetchInvitedApp, query.get('access_token'))
			yield put(actions.selectedApp(app))
		} catch (err) {
			if (err.response.status === 404 || err.response.status === 502) {
				window.location.replace('/404')
				return
			}

			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			console.error('[enterInvitationPage] ', err)
			eventlog.error('enter invitation', {
				message: err.message
			})
		}
	}
}

export default enterInvitationPage
