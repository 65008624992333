const START_APP = "system/START_APP";
const STORE_USER_ID = "system/STORE_USER_ID";
const FETCH_APPS = "system/FETCH_APPS";
const ON_SELECT_APP = "system/ON_SELECT_APP";
const APPS = "system/APPS";
const FETCH_EDIT_MODAL = "system/FETCH_EDIT_MODAL";
const EDIT_MODAL_CONFIG = "system/EDIT_MODAL_CONFIG";
const SAVE_EDIT_MODAL_CONFIG = "system/UPDATE_EDIT_MODAL_CONFIG";
const ECOMMERCE_CONFIG = "system/ECOMMERCE_CONFIG";
const UPDATE_ECOMMERCE_CONFIG = "system/UPDATE_ECOMMERCE_CONFIG";
const CREATE_NEW_BOT = "system/CREATE_NEW_BOT";
const ENTER_BOT_MANAGEMENT = "system/ENTER_BOT_MANAGEMENT";
const SELECTED_APP = "system/SELECTED_APP";
const DRAFT_APP_CONFIG = "system/DRAFT_APP_CONFIG";
const TEMPLATE_CONFIG = "system/TEMPLATE_CONFIG";
const ENTER_APP_MANAGEMENT = "system/ENTER_APP_MANAGEMENT";
const ON_FETCH_DRAFT_APP_CONFIG = "system/ON_FETCH_DRAFT_APP_CONFIG";
const SAVE_APP_CONFIG = "system/SAVE_APP_CONFIG";
const UPDATE_SAVE_CONFIG_LOADING = "system/UPDATE_SAVE_CONFIG_LOADING";
const FETCH_RESPONSES = "system/FETCH_RESPONSES";
const RESPONSES = "system/RESPONSES";
const IS_RESPONSES_LOADING = "system/IS_RESPONSES_LOADING";
const IS_CONFIG_LOADING = "system/IS_CONFIG_LOADING";
const IS_APPS_LOADING = "system/IS_APPS_LOADING";
const IS_BOT_MANAGEMENT_LOADING = "system/IS_BOT_MANAGEMENT_LOADING";
const REMOVE_LINKS = "system/REMOVE_LINKS";
const IS_SHOW_CREATE_NEW_BOT_MODAL = "system/IS_SHOW_CREATE_NEW_BOT_MODAL";
const PARAMS = "system/PARAMS";
const TOAST = "system/TOAST";
const DRAFT_NEW_BOT = "system/DRAFT_NEW_BOT";
const TOGGLE_NODE_MENU = "system/TOGGLE_NODE_MENU";
const IS_SAVING = "system/IS_SAVING";
const SAVE_UPLOAD_MEDIA = "system/SAVE_UPLOAD_MEDIA";
const DRAFT_UPLOAD_MEDIA = "system/DRAFT_UPLOAD_MEDIA";
const IS_DELETING_APP = "system/IS_DELETING_APP";
const DELETE_APP = "system/DELETE_APP";
const COPY_APP = "system/COPY_APP";
const IS_COPYING_APP = "system/IS_COPYING_APP";
const IS_SHOWING_SPLASH = "system/IS_SHOWING_SPLASH";
const FETCH_SETTINGS = "system/FETCH_SETTINGS";
const START_OVER = "system/START_OVER";
const SAVE_START_OVER = "system/SAVE_START_OVER";
const LINE_CONFIG = "system/LINE_CONFIG";
const FETCH_LINE_CONFIG = "system/FETCH_LINE_CONFIG";
const IS_FETCHING_LINE_CONFIG = "system/IS_FETCHING_LINE_CONFIG";
const SAVE_LINE_CONFIG = "system/SAVE_LINE_CONFIG";
const TYPING_EMULATION = "system/TYPING_EMULATION";
const FETCH_PUBLIC_DATA = "system/FETCH_PUBLIC_DATA";
const CREDENTIALS = "system/CREDENTIALS";
const ON_ENTER_DISTRIBUTE = "system/ON_ENTER_DISTRIBUTE";
const IS_DISTRIBUTE_LOADING = "system/IS_DISTRIBUTE_LOADING";
const SHARED_TOKEN = "system/SHARED_TOKEN";
const RENEW_TOKEN = "system/RENEW_TOKEN";
const LINK_WITH_GOOGLE = "system/LINK_WITH_GOOGLE";
const GOOGLE_LINKING_URL = "system/GOOGLE_LINKING_URL";
const UNLINK_WITH_GOOGLE = "system/UNLINK_WITH_GOOGLE";
const GOOGLE_LINKING_DETAIL = "system/GOOGLE_LINKING_DETAIL";
const FETCH_GOOGLE_LINKING_DETAIL = "system/FETCH_GOOGLE_LINKING_DETAIL";
const GOOGLE_SPREADSHEETS = "system/GOOGLE_SPREADSHEETS";
const SAVE_TYPING_EMULATION = "system/SAVE_TYPING_EMULATION";
const IS_SAVING_TYPEING_EMULATION = "system/IS_SAVING_TYPEING_EMULATION";
const IS_VALIDATING_SLUG = "system/IS_VALIDATING_SLUG";
const VALIDATE_SLUG = "system/VALIDATE_SLUG";
const MOBILE_APP = "system/MOBILE_APP";
const MOBILE_APP_CONFIG = "system/MOBILE_APP_CONFIG";
const SAVE_MOBILE_APP_CONFIG = "system/SAVE_MOBILE_APP_CONFIG";
const IS_SAVING_MOBILE_APP_CONFIG = "system/IS_SAVING_MOBILE_APP_CONFIG";
const MOBILE_APP_BUILDS = "system/MOBILE_APP_BUILDS";
const REDIRECT = "system/REDIRECT";
const PUBLISH_APP = "system/PUBLISH_APP";
const IS_PUBLISHING_APP = "system/IS_PUBLISHING_APP";
const LINE_CONFIG_ERRORS = "system/LINE_CONFIG_ERRORS";
const VERIFY_LINE_CONFIG = "system/VERIFY_LINE_CONFIG";
const REFRESH_LINE_CONFIG_TOKEN = "system/REFRESH_LINE_CONFIG_TOKEN";
const ENABLE_FEATURE_FLAG = "system/ENABLE_FEATURE_FLAG";
const ENABLING_FEATURE_FLAG = "system/ENABLING_FEATURE_FLAG";
const FEATURE_FLAGS = "system/FEATURE_FLAGS";
const STORE_UNIQUE_ID = "system/STORE_UNIQUE_ID";
const IS_DEPLOYING = "system/IS_DEPLOYING";

const FETCH_MOBILE_APP_BUILDS = "messageConfig/FETCH_MOBILE_APP_BUILDS";
const AUTO_GEN_MOBILE_APP_CONFIG = "messageConfig/AUTO_GEN_MOBILE_APP_CONFIG";
const IS_GENERATING_MOBILE_APP_CONFIG =
	"messageConfig/IS_GENERATING_MOBILE_APP_CONFIG";
const REMOVED_MESSAGES = "messageConfig/REMOVED_MESSAGES";
const FETCH_MESSAGE_CONFIG = "messageConfig/FETCH_MESSAGE_CONFIG";
const NEW_MESSAGE = "messageConfig/NEW_MESSAGE";
const CREATE_NEW_MESSAGE_ERROR = "messageConfig/CREATE_NEW_MESSAGE_ERROR";
const MESSAGE_PROPERTIES = "messageConfig/MESSAGE_PROPERTIES";
const CONFIGURATION = "messageConfig/CONFIGURATION";
const ON_SELECT_STEP = "messageConfig/ON_SELECT_STEP";
const ON_EDIT = "messageConfig/ON_EDIT";
const EDIT_MESSAGE = "messageConfig/EDIT_MESSAGE";
const START_NODE_CONFIG = "messageConfig/START_NODE_CONFIG";
const FETCH_REMOVED_MESSAGES = "messageConfig/FETCH_REMOVED_MESSAGES";
const ON_SELECT_NODE = "messageConfig/ON_SELECT_NODE";
const IS_HAS_INTRO = "messageConfig/IS_HAS_INTRO";
const DESIGN = "messageConfig/DESIGN";
const ON_EDIT_MAP = "messageConfig/ON_EDIT_MAP";
const ON_CLOSE_EDIT_MAP = "messageConfig/ON_CLOSE_EDIT_MAP";
const ON_CONFIRM_EDIT_MAP = "messageConfig/ON_CONFIRM_EDIT_MAP";
const FETCH_DESIGN_CONFIG = "messageConfig/FETCH_DESIGN_CONFIG";
const FETCH_RESPONSES_STATS = "messageConfig/FETCH_RESPONSES_STATS";
const IS_FETCHING_RESPONSES_STATS = "messageConfig/IS_FETCHING_RESPONSES_STATS";
const RESPONSES_STATS = "messageConfig/RESPONSES_STATS";
const IS_FETCHING_RESPONSE = "messageConfig/IS_FETCHING_RESPONSE";
const FETCH_RESPONSE = "messageConfig/FETCH_RESPONSE";
const FETCH_ANALYTIC = "messageConfig/FETCH_ANALYTIC";
const FETCH_DASHBOARD = "messageConfig/FETCH_DASHBOARD";
const IS_FETCHING_DASHBOARD = "messageConfig/IS_FETCHING_DASHBOARD";
const FETCH_RESPONSES_GROUP_BY_DEVICE =
	"messageConfig/FETCH_RESPONSES_GROUP_BY_DEVICE";
const RESPONSES_GROUP_BY_DEVICE = "messageConfig/RESPONSES_GROUP_BY_DEVICE";
const RESPONSE = "messageConfig/RESPONSE";
const EDIT_MESSAGE_ERRORS = "messageConfig/EDIT_MESSAGE_ERRORS";
const VARIABLES = "messageConfig/VARIABLES";
const FETCH_VARIABLES = "messageConfig/FETCH_VARIABLES";
const SAVE_VARIABLES = "messageConfig/SAVE_VARIABLES";
const IMAGE_SIZE_ERROR = "messageConfig/IMAGE_SIZE_ERROR";
const ON_SUBMIT_EDIT_MESSAGE = "messageConfig/ON_SUBMIT_EDIT_MESSAGE";
const ON_REMOVE_MESSAGE = "messageConfig/ON_REMOVE_MESSAGE";
const SAVE_MESSAGE_CONFIG = "messageConfig/SAVE_MESSAGE_CONFIG";
const DEPLOY_MESSAGE_CONFIG = "messageConfig/DEPLOY_MESSAGE_CONFIG";
const MESSAGE_CONFIG_BACKUP_DIAGRAM_HISTORY =
	"messageConfig/MESSAGE_CONFIG_BACKUP_DIAGRAM_HISTORY";
const SAVE_MESSAGE_CONFIG_NO_HISTORY = "messageConfig/SAVE_MESSAGE_CONFIG_NO_HISTORY";

const FETCH_MODULE_RESPONSES = "response/FETCH_MODULE_RESPONSES";
const MODULE_RESPONSES = "response/MODULE_RESPONSES";
const IS_FETCHING_MODULE_RESPONSES = "response/IS_FETCHING_MODULE_RESPONSES";
const ENTER_MODULE_RESPONSE_DETAIL = "response/ENTER_MODULE_RESPONSE_DETAIL";
const MODULE_RESPONSE_DETAIL = "response/MODULE_RESPONSE_DETAIL";
const ANALYTIC_DATA = "response/ANALYTIC_DATA";
const DASHBOARD_DATA = "response/DASHBOARD_DATA";

const IS_FETCHING_PRODUCTS = "product/IS_FETCHING_PRODUCTS";
const FETCH_PRODUCTS = "product/FETCH_PRODUCTS";
const PRODUCTS = "product/PRODUCTS";
const CREATE_PRODUCT = "product/CREATE_PRODUCT";
const UPDATE_PRODUCT = "product/UPDATE_PRODUCT";
const DELETE_PRODUCT = "product/DELETE_PRODUCT";

const PREVIEW_TIME_LEFT_CHANGE = "previewConfig/PREVIEW_TIME_LEFT_CHANGE";
const PREVIEW_PERIOD_TIMER_CHANGE = "previewConfig/PREVIEW_PERIOD_TIMER_CHANGE";
const ON_POST_MESSAGE_TO_PREVIEW_WINDOW =
	"previewConfig/ON_POST_MESSAGE_TO_PREVIEW_WINDOW";
const IS_SAVING_PREVIEW = "previewConfig/IS_SAVING_PREVIEW";
const IS_SAVING_PREVIEW_IN_BACKGROUND =
	"previewConfig/IS_SAVING_PREVIEW_IN_BACKGROUND";
const SAVE_PREVIEW_CONFIG = "previewConfig/SAVE_PREVIEW_CONFIG";
const SAVE_PREVIEW_IN_BACKGROUND = "previewConfig/SAVE_PREVIEW_IN_BACKGROUND";

const SIGNIN = "auth/SIGNIN";
const SIGNIN_ERROR = "auth/SIGNIN_ERROR";
const SIGNIN_LOADING = "auth/SIGNIN_LOADING";
const LOGOUT = "auth/LOGOUT";
const UNAUTHORZIATION = "auth/UNAUTHORZIATION";
const REGISTER_ERROR = "auth/REGISTER_ERROR";
const REGISTER = "auth/REGISTER";
const REGISTER_LOADING = "auth/REGISTER_LOADING";
const IS_REGISTER_SUCCESS = "auth/IS_REGISTER_SUCCESS";
const LOGIN_FORM = "auth/LOGIN_FORM";
const CHECKING_AUTH = "auth/CHECKING_AUTH";
const IS_REQUESTING_RESET_PASSWORD = "auth/IS_REQUESTING_RESET_PASSWORD";
const FORGOT_PASSWORD_ERROR = "auth/FORGOT_PASSWORD_ERROR";
const IS_FORGOT_PASSWORD_SUCCESS = "auth/IS_FORGOT_PASSWORD_SUCCESS";
const REQUEST_RESET_PASSWORD = "auth/REQUEST_RESET_PASSWORD";
const FORGOT_PASSWORD_FORM = "auth/FORGOT_PASSWORD_FORM";
const IS_RESETTING_PASSWORD = "auth/IS_RESETTING_PASSWORD";
const RESET_PASSWORD_ERROR = "auth/RESET_PASSWORD_ERROR";
const IS_RESET_PASSWORD_SUCCESS = "auth/IS_RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FORM = "auth/RESET_PASSWORD_FORM";
const RESET_PASSWORD = "auth/RESET_PASSWORD";
const ACCESS_CONTROLS = "auth/ACCESS_CONTROLS";
const AUTH_DATA = "auth/AUTH_DATA";

const START_APP_CHAT = "messenger/SUBSCRIBE";
const START_INTEGRATION_CHAT = "messenger/START_INTEGRATION_CHAT";
const VISITORS = "messenger/VISITORS";
const VISITOR_MESSAGES = "messenger/VISITOR_MESSAGES";
const MESSENGER_MANAGER = "messenger/MESSENGER_MANAGER";
const PRESENCE_VISITORS = "messenger/PRESENCE_VISITORS";
const ON_SELECT_MESSENGER_VISITOR = "messenger/ON_SELECT_MESSENGER_VISITOR";
const SELECTED_VISITOR = "messenger/SELECTED_VISITOR";
const ON_SEND_MESSAGE = "messenger/ON_SEND_MESSAGE";
const ON_FETCH_OLDER_MESSAGES = "messenger/ON_FETCH_OLDER_MESSAGES";
const IS_LOADING_OLDER_MESSAGES = "messenger/IS_LOADING_OLDER_MESSAGES";
const HAS_MORE_MESSAGES = "messenger/HAS_MORE_MESSAGES";
const HAS_MORE_VISITORS = "messenger/HAS_MORE_VISITORS";
const IS_LOADING_MESSAGES = "messenger/IS_LOADING_MESSAGES";
const IS_LOADING_VISITORS = "messenger/IS_LOADING_VISITORS";
const ON_LOAD_MORE_APP_VISITORS = "messenger/ON_LOAD_MORE_APP_VISITORS";
const ON_LOAD_MORE_INTEGRATION_VISITORS =
	"messenger/ON_LOAD_MORE_INTEGRATION_VISITORS";
const IS_LOADING_MORE_VISITORS = "messenger/IS_LOADING_MORE_VISITORS";
const UNSUBCRIBE_CHANNEL = "messenger/UNSUBCRIBE_CHANNEL";
const FAILED_MESSAGES = "messenger/FAILED_MESSAGES";
const RETRY_MESSAGE = "messenger/RETRY_MESSAGE";
const DELETE_MESSAGE = "messenger/DELETE_MESSAGE";
const HUMAN_TAKEOVER_START = "messenger/HUMAN_TAKEOVER_START";
const HUMAN_TAKEOVER_IGNORE = "messenger/HUMAN_TAKEOVER_IGNORE";
const HUMAN_TAKEOVER_COMPLETE = "messenger/HUMAN_TAKEOVER_COMPLETE";
const HUMAN_TAKEOVER = "messenger/HUMAN_TAKEOVER";
const FLEX_ERROR = "messenger/FLEX_ERROR";

const POST_MESSAGE_TO_IFRAME = "iframe/POST_MESSAGE_TO_IFRAME";

const ON_SEARCH_COLLABORATORS = "collaborators/ON_SEARCH_COLLABORATORS";
const COLLABORATORS = "collaborators/COLLABORATORS";
const COLLABORATORS_SEARCH_RESULT = "collaborators/COLLABORATORS_SEARCH_RESULT";
const IS_SEACHING_COLLABORATORS = "collaborators/IS_SEACHING_COLLABORATORS";
const IS_FETCHING_COLLABORATORS = "collaborators/IS_FETCHING_COLLABORATORS";
const ON_ENTER_COLLABORATORS_PAGE = "collaborators/ON_ENTER_COLLABORATORS_PAGE";
const SEND_COLLABORATOR_INVATATION =
	"collaborators/SEND_COLLABORATOR_INVATATION";
const IS_INVITING_COLLABORATOR = "collaborators/IS_INVITING_COLLABORATOR";
const ACCEPT_INVITATION = "collaborators/ACCEPT_INVITATION";
const DECLINE_INVITATION = "collaborators/DECLINE_INVITATION";
const ENTER_INVITATION_PAGE = "colaborators/ENTER_INVITATION_PAGE";
const IS_SHOWING_INVITATION_MODAL = "collaborators/IS_SHOWING_INVITATION_MODAL";
const FETCH_ROLES = "collaborators/FETCH_ROLES";
const ROLES = "collaborators/ROLES";
const IS_FETCHING_ROLES = "collaborators/IS_FETCHING_ROLES";
const GENERATE_INVITATION_LINK = "collaborators/GENERATE_INVITATION_LINK";
const STORE_INVITATION_URL = "collaborators/STORE_INVITATION_URL";

const ENTER_SUBSCRIPTION = "subscription/ENTER_SUBSCRIPTION";
const ALL_PLANS = "subscription/ALL_PLANS";
const SELECTED_PLAN = "subscription/SELECTED_PLAN";
const PLAN_SUBSCRIBE_SETTING = "subscription/PLAN_SUBSCRIBE_SETTING";
const CURRENT_SCHEDULE = "subscription/CURRENT_SCHEDULE";
const SELECTED_CURRENCY = "subscription/SELECTED_CURRENCY";
const CURRENT_CARDS = "subscription/CURRENT_CARDS";
const DRAFT_CARD = "subscription/DRAFT_CARD";
const ADD_CARD = "subscription/ADD_CARD";
const EDIT_CARD = "subscription/EDIT_CARD";
const IS_ADDING_CARD = "subscription/IS_ADDING_CARD";
const ADD_CARD_ERROR = "subscription/ADD_CARD_ERROR";
const CHANGE_PLAN = "subscription/CHANGE_PLAN";
const IS_SUBSCIPTION_PAGE_LOADING = "subscription/IS_SUBSCIPTION_PAGE_LOADING";
const IS_SHOWING_ADD_PAYMENT_METHOD =
	"subscription/IS_SHOWING_ADD_PAYMENT_METHOD";
const IS_SHOWING_EDIT_PAYMENT_METHOD =
	"subscription/IS_SHOWING_EDIT_PAYMENT_METHOD";
const ON_SELECT_PLANS_PLATFORM_CHANGE =
	"subscription/ON_SELECT_PLANS_PLATFORM_CHANGE";
const ON_DELETE_CARD = "subscription/ON_DELETE_CARD";
const STORE_BILLING_INFORMATION = "subscription/STORE_BILLING_INFORMATION";
const ON_SAVE_BILLING_INFORMATION = "subscription/ON_SAVE_BILLING_INFORMATION";
const BILLING_INFORMATION_ERROR = "subscription/BILLING_INFORMATION_ERROR";
const CARD_ERROR = "subscription/CARD_ERROR";
const ON_ENTER_BILLING_INFORMATION =
	"subscription/ON_ENTER_BILLING_INFORMATION";
const ON_CHANGING_PLAN = "subscription/ON_CHANGING_PLAN";
const STORE_BILLING_CYCLE = "subscription/STORE_BILLING_CYCLE";
const STORE_UPGRADE_PLAN = "subscription/STORE_UPGRADE_PLAN";
const CANCEL_AUTO_RENEW_PLAN = "subscription/CANCEL_AUTO_RENEW_PLAN";
const STORE_INVOICES_STATUS_PAID_SUBSCRIPTION =
	"subscription/STORE_INVOICES_STATUS_PAID_SUBSCRIPTION";
const STORE_INVOICES_STATUS_UNPAID_SUBSCRIPTION =
	"subscription/STORE_INVOICES_STATUS_UNPAID_SUBSCRIPTION";
const STORE_CURRENT_APP_PLAN = "subscription/STORE_CURRENT_APP_PLAN";
const STORE_SUMMARY_PLAN = "subscription/STORE_SUMMARY_PLAN";
const GET_INVOICE_PDF = "subscription/GET_INVOICE_PDF";
const IS_SHOW_CURRENT_USAGE_UPGRADE_DETAIL =
	"subscription/IS_SHOW_CURRENT_USAGE_UPGRADE_DETAIL";
const IS_SHOW_CHAT_BOT = "subscription/IS_SHOW_CHAT_BOT";
const IS_CANCEL_AUTO_RENEW_PLAN = "subscription/IS_CANCEL_AUTO_RENEW_PLAN";
const IS_SHOWING_UNPAID_INVOICE_SUMMARY =
	"subscription/IS_SHOWING_UNPAID_INVOICE_SUMMARY";
const SELECTED_UNPAID_INVOICES = "subscription/SELECTED_UNPAID_INVOICES";
const SELECTED_ALL_UNPAID_INVOICES =
	"subscription/SELECTED_ALL_UNPAID_INVOICES";
const IS_SELECTED_ALL_UNPAID_INVOICES =
	"subscription/IS_SELECTED_ALL_UNPAID_INVOICES";
const IS_PAYING_UNPAID_INVOICE = "subscription/IS_PAYING_UNPAID_INVOICE";
const PAYMENT_UNPAID_INVOICE = "subscription/PAYMENT_UNPAID_INVOICE";
const INVOICE_ACTACH_FILE_CHANGE = "subscription/INVOICE_ACTACH_FILE_CHANGE";

const IS_FETCHING_LINE_CUSTOMER = "lineCustomer/IS_FETCHING_LINE_CUSTOMER";
const LINE_CUSTOMER = "lineCustomer/LINE_CUSTOMER";
const BAN_LINE_CUSTOMER = "lineCustomer/BAN_LINE_CUSTOMER";
const LINE_CUSTOMER_QUERY = "lineCustomer/LINE_CUSTOMER_QUERY";
const IS_SHOWING_TRACKING_MESSAGE_MODAL =
	"lineCustomer/IS_SHOWING_TRACKING_MESSAGE_MODAL";

const LINE_INSIGHT_FRIEND = "lineInsight/LINE_INSIGHT_FRIEND";
const LINE_INSIGHT_MESSAGE = "lineInsight/LINE_INSIGHT_MESSAGE";
const IS_FETCHING_LINE_INSIGHT_FRIEND =
	"lineInsight/IS_FETCHING_LINE_INSIGHT_FRIEND";
const IS_FETCHING_LINE_INSIGHT_MESSAGE =
	"lineInsight/IS_FETCHING_LINE_INSIGHT_MESSAGE";
const LINE_INSIGHT_FRIEND_QUERY = "lineInsight/LINE_INSIGHT_FRIEND_QUERY";
const LINE_INSIGHT_MESSAGE_QUERY = "lineInsight/LINE_INSIGHT_MESSAGE_QUERY";

const DATA_FEED_SELECT = "dataFeed/DATA_FEED_SELECT";
const DATA_FEED = "dataFeed/DATA_FEED";
const DATA_FEED_QUERY = "dataFeed/DATA_FEED_QUERY";
const IS_FETCHING_DATA_FEED = "dataFeed/IS_FETCHING_DATA_FEED";

const TOGGLE_SAVE_DIAGRAM_HISTORY =
	"saveDiagramHistory/TOGGLE_SAVE_DIAGRAM_HISTORY";
const IS_FETCHING_SAVE_DIAGRAM_HISTORY =
	"saveDiagramHistory/IS_FETCHING_SAVE_DIAGRAM_HISTORY";
const SAVE_DIAGRAM_HISTORIES = "saveDiagramHistory/SAVE_DIAGRAM_HISTORIES";
const SAVE_DIAGRAM_HISTORIES_QUERY =
	"saveDiagramHistory/SAVE_DIAGRAM_HISTORIES_QUERY";
const SAVE_DIAGRAM_HISTORIES_UPDATE =
	"saveDiagramHistory/SAVE_DIAGRAM_HISTORIES_UPDATE";
const PREVIEW_SAVE_DIAGRAM_HISTORY =
	"saveDiagramHistory/PREVIEW_SAVE_DIAGRAM_HISTORY";
const SELECT_SAVE_DIAGRAM_HISTORY =
	"saveDiagramHistory/SELECT_SAVE_DIAGRAM_HISTORY";
const RESET_SAVE_DIAGRAM_HISTORY =
	"saveDiagramHistory/RESET_SAVE_DIAGRAM_HISTORY";

export {
	START_APP,
	FETCH_MESSAGE_CONFIG,
	NEW_MESSAGE,
	CREATE_NEW_MESSAGE_ERROR,
	MESSAGE_PROPERTIES,
	SIGNIN,
	SIGNIN_ERROR,
	SIGNIN_LOADING,
	CONFIGURATION,
	LOGOUT,
	UNAUTHORZIATION,
	SAVE_APP_CONFIG,
	ON_SELECT_STEP,
	FETCH_PUBLIC_DATA,
	ON_EDIT,
	EDIT_MESSAGE,
	PREVIEW_PERIOD_TIMER_CHANGE,
	PREVIEW_TIME_LEFT_CHANGE,
	ON_POST_MESSAGE_TO_PREVIEW_WINDOW,
	START_NODE_CONFIG,
	IS_SAVING_PREVIEW,
	ON_SELECT_APP,
	FETCH_APPS,
	APPS,
	FETCH_EDIT_MODAL,
	EDIT_MODAL_CONFIG,
	SAVE_EDIT_MODAL_CONFIG,
	ECOMMERCE_CONFIG,
	UPDATE_ECOMMERCE_CONFIG,
	CREATE_NEW_BOT,
	ENTER_BOT_MANAGEMENT,
	SELECTED_APP,
	DRAFT_APP_CONFIG,
	TEMPLATE_CONFIG,
	ENTER_APP_MANAGEMENT,
	ON_FETCH_DRAFT_APP_CONFIG,
	UPDATE_SAVE_CONFIG_LOADING,
	FETCH_RESPONSES,
	RESPONSES,
	IS_RESPONSES_LOADING,
	IS_CONFIG_LOADING,
	REMOVED_MESSAGES,
	FETCH_REMOVED_MESSAGES,
	IS_APPS_LOADING,
	VARIABLES,
	IS_BOT_MANAGEMENT_LOADING,
	REMOVE_LINKS,
	ON_SELECT_NODE,
	IS_HAS_INTRO,
	IS_SHOW_CREATE_NEW_BOT_MODAL,
	PARAMS,
	TOAST,
	DRAFT_NEW_BOT,
	TOGGLE_NODE_MENU,
	IS_SAVING,
	DRAFT_UPLOAD_MEDIA,
	SAVE_UPLOAD_MEDIA,
	ON_CLOSE_EDIT_MAP,
	ON_EDIT_MAP,
	ON_CONFIRM_EDIT_MAP,
	IS_DELETING_APP,
	DELETE_APP,
	COPY_APP,
	IS_COPYING_APP,
	REGISTER,
	REGISTER_LOADING,
	REGISTER_ERROR,
	IS_REGISTER_SUCCESS,
	LOGIN_FORM,
	CHECKING_AUTH,
	IS_SHOWING_SPLASH,
	IS_SAVING_PREVIEW_IN_BACKGROUND,
	DESIGN,
	FETCH_DESIGN_CONFIG,
	POST_MESSAGE_TO_IFRAME,
	FETCH_SETTINGS,
	START_OVER,
	SAVE_START_OVER,
	LINE_CONFIG,
	SAVE_LINE_CONFIG,
	FETCH_LINE_CONFIG,
	IS_FETCHING_LINE_CONFIG,
	IS_REQUESTING_RESET_PASSWORD,
	FORGOT_PASSWORD_ERROR,
	IS_FORGOT_PASSWORD_SUCCESS,
	REQUEST_RESET_PASSWORD,
	FORGOT_PASSWORD_FORM,
	IS_RESETTING_PASSWORD,
	RESET_PASSWORD_ERROR,
	IS_RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FORM,
	RESET_PASSWORD,
	CREDENTIALS,
	ON_ENTER_DISTRIBUTE,
	IS_DISTRIBUTE_LOADING,
	SHARED_TOKEN,
	RENEW_TOKEN,
	START_APP_CHAT,
	START_INTEGRATION_CHAT,
	VISITORS,
	VISITOR_MESSAGES,
	MESSENGER_MANAGER,
	PRESENCE_VISITORS,
	ON_SELECT_MESSENGER_VISITOR,
	SELECTED_VISITOR,
	ON_SEND_MESSAGE,
	ON_FETCH_OLDER_MESSAGES,
	IS_LOADING_OLDER_MESSAGES,
	HAS_MORE_MESSAGES,
	HAS_MORE_VISITORS,
	IS_LOADING_MESSAGES,
	IS_LOADING_VISITORS,
	ON_LOAD_MORE_APP_VISITORS,
	ON_LOAD_MORE_INTEGRATION_VISITORS,
	IS_LOADING_MORE_VISITORS,
	UNSUBCRIBE_CHANNEL,
	FAILED_MESSAGES,
	RETRY_MESSAGE,
	DELETE_MESSAGE,
	FETCH_RESPONSES_STATS,
	IS_FETCHING_RESPONSES_STATS,
	RESPONSES_STATS,
	IS_FETCHING_RESPONSE,
	FETCH_RESPONSE,
	FETCH_ANALYTIC,
	FETCH_DASHBOARD,
	IS_FETCHING_DASHBOARD,
	RESPONSE,
	ON_SEARCH_COLLABORATORS,
	COLLABORATORS,
	COLLABORATORS_SEARCH_RESULT,
	IS_SEACHING_COLLABORATORS,
	IS_FETCHING_COLLABORATORS,
	SEND_COLLABORATOR_INVATATION,
	IS_INVITING_COLLABORATOR,
	ACCEPT_INVITATION,
	DECLINE_INVITATION,
	ACCESS_CONTROLS,
	ENTER_INVITATION_PAGE,
	ON_ENTER_COLLABORATORS_PAGE,
	IS_SHOWING_INVITATION_MODAL,
	LINK_WITH_GOOGLE,
	GOOGLE_LINKING_URL,
	UNLINK_WITH_GOOGLE,
	GOOGLE_LINKING_DETAIL,
	FETCH_GOOGLE_LINKING_DETAIL,
	GOOGLE_SPREADSHEETS,
	TYPING_EMULATION,
	SAVE_TYPING_EMULATION,
	IS_SAVING_TYPEING_EMULATION,
	IS_VALIDATING_SLUG,
	VALIDATE_SLUG,
	MOBILE_APP,
	MOBILE_APP_CONFIG,
	SAVE_MOBILE_APP_CONFIG,
	IS_SAVING_MOBILE_APP_CONFIG,
	MOBILE_APP_BUILDS,
	REDIRECT,
	PUBLISH_APP,
	IS_PUBLISHING_APP,
	EDIT_MESSAGE_ERRORS,
	LINE_CONFIG_ERRORS,
	VERIFY_LINE_CONFIG,
	ENABLE_FEATURE_FLAG,
	ENABLING_FEATURE_FLAG,
	FETCH_MODULE_RESPONSES,
	IS_FETCHING_MODULE_RESPONSES,
	MODULE_RESPONSES,
	FETCH_PRODUCTS,
	IS_FETCHING_PRODUCTS,
	PRODUCTS,
	CREATE_PRODUCT,
	UPDATE_PRODUCT,
	DELETE_PRODUCT,
	ENTER_MODULE_RESPONSE_DETAIL,
	MODULE_RESPONSE_DETAIL,
	FEATURE_FLAGS,
	FETCH_VARIABLES,
	SAVE_VARIABLES,
	FETCH_MOBILE_APP_BUILDS,
	AUTO_GEN_MOBILE_APP_CONFIG,
	IS_GENERATING_MOBILE_APP_CONFIG,
	FETCH_RESPONSES_GROUP_BY_DEVICE,
	RESPONSES_GROUP_BY_DEVICE,
	AUTH_DATA,
	HUMAN_TAKEOVER_COMPLETE,
	HUMAN_TAKEOVER,
	HUMAN_TAKEOVER_IGNORE,
	HUMAN_TAKEOVER_START,
	ANALYTIC_DATA,
	DASHBOARD_DATA,
	ENTER_SUBSCRIPTION,
	ALL_PLANS,
	SELECTED_PLAN,
	PLAN_SUBSCRIBE_SETTING,
	CURRENT_SCHEDULE,
	SELECTED_CURRENCY,
	CURRENT_CARDS,
	DRAFT_CARD,
	ADD_CARD,
	EDIT_CARD,
	IS_ADDING_CARD,
	ADD_CARD_ERROR,
	CHANGE_PLAN,
	IS_SUBSCIPTION_PAGE_LOADING,
	IS_FETCHING_LINE_CUSTOMER,
	LINE_CUSTOMER,
	BAN_LINE_CUSTOMER,
	LINE_CUSTOMER_QUERY,
	IS_SHOWING_TRACKING_MESSAGE_MODAL,
	FLEX_ERROR,
	IMAGE_SIZE_ERROR,
	STORE_USER_ID,
	REFRESH_LINE_CONFIG_TOKEN,
	FETCH_ROLES,
	ROLES,
	IS_FETCHING_ROLES,
	GENERATE_INVITATION_LINK,
	STORE_INVITATION_URL,
	IS_SHOWING_ADD_PAYMENT_METHOD,
	IS_SHOWING_EDIT_PAYMENT_METHOD,
	ON_SELECT_PLANS_PLATFORM_CHANGE,
	ON_DELETE_CARD,
	STORE_BILLING_INFORMATION,
	ON_SAVE_BILLING_INFORMATION,
	BILLING_INFORMATION_ERROR,
	CARD_ERROR,
	ON_ENTER_BILLING_INFORMATION,
	ON_CHANGING_PLAN,
	STORE_BILLING_CYCLE,
	STORE_UPGRADE_PLAN,
	LINE_INSIGHT_FRIEND,
	LINE_INSIGHT_MESSAGE,
	IS_FETCHING_LINE_INSIGHT_FRIEND,
	IS_FETCHING_LINE_INSIGHT_MESSAGE,
	LINE_INSIGHT_FRIEND_QUERY,
	LINE_INSIGHT_MESSAGE_QUERY,
	DATA_FEED_SELECT,
	DATA_FEED,
	DATA_FEED_QUERY,
	IS_FETCHING_DATA_FEED,
	CANCEL_AUTO_RENEW_PLAN,
	STORE_INVOICES_STATUS_PAID_SUBSCRIPTION,
	STORE_INVOICES_STATUS_UNPAID_SUBSCRIPTION,
	STORE_CURRENT_APP_PLAN,
	STORE_SUMMARY_PLAN,
	GET_INVOICE_PDF,
	IS_SHOW_CURRENT_USAGE_UPGRADE_DETAIL,
	IS_SHOW_CHAT_BOT,
	IS_CANCEL_AUTO_RENEW_PLAN,
	IS_SHOWING_UNPAID_INVOICE_SUMMARY,
	SELECTED_UNPAID_INVOICES,
	SELECTED_ALL_UNPAID_INVOICES,
	IS_SELECTED_ALL_UNPAID_INVOICES,
	IS_PAYING_UNPAID_INVOICE,
	PAYMENT_UNPAID_INVOICE,
	INVOICE_ACTACH_FILE_CHANGE,
	ON_SUBMIT_EDIT_MESSAGE,
	ON_REMOVE_MESSAGE,
	SAVE_MESSAGE_CONFIG,
	SAVE_PREVIEW_CONFIG,
	SAVE_PREVIEW_IN_BACKGROUND,
	TOGGLE_SAVE_DIAGRAM_HISTORY,
	IS_FETCHING_SAVE_DIAGRAM_HISTORY,
	SAVE_DIAGRAM_HISTORIES,
	SAVE_DIAGRAM_HISTORIES_QUERY,
	SAVE_DIAGRAM_HISTORIES_UPDATE,
	MESSAGE_CONFIG_BACKUP_DIAGRAM_HISTORY,
	PREVIEW_SAVE_DIAGRAM_HISTORY,
	SELECT_SAVE_DIAGRAM_HISTORY,
	RESET_SAVE_DIAGRAM_HISTORY,
	STORE_UNIQUE_ID,
	DEPLOY_MESSAGE_CONFIG,
	IS_DEPLOYING,
	SAVE_MESSAGE_CONFIG_NO_HISTORY,
};
