import * as actions from "../actions";
import * as api from "../apis";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, put, take } from "redux-saga/effects";

function* fetchLineInsightMessageQuery() {
	while (true) {
		const {
			payload: { limit, offset, search },
		} = yield take(types.LINE_INSIGHT_MESSAGE_QUERY);
		try {
			eventlog.info("fetch line Insight Message");
			yield put(actions.isFetchingLineInsightMessage(true));
			const { data } = yield call(
				api.fetchLineCustomerWithQuery,
				limit,
				offset,
				search
			); //

			yield put(actions.lineInsightMessage(data));
			yield put(actions.isFetchingLineInsightMessage(false));
		} catch (err) {
			yield put(actions.isFetchingLineInsightMessage(false));
			console.error("[onFetchLineInsight Message] ", err);
			eventlog.error("enter line Insight Message", {
				message: err.message,
			});
		}
	}
}

export default fetchLineInsightMessageQuery;
