import * as actions from "../actions";
import * as api from "../apis";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { all, call, put, select, take } from "redux-saga/effects";

export function* fetchMobileAppBuilds() {
	try {
		const { data } = yield call(api.fetchMobileAppBuilds);
		yield put(actions.mobileAppBuilds(data || []));
	} catch (err) {
		console.error("[fetchMobileAppBuilds] ", err);
	}
}

// function* fetchDistributeToken() {
// 	try {
// 		const {
// 			data: { token },
// 		} = yield call(api.fetchDistributeToken)
// 		yield put(actions.sharedToken(token))
// 	} catch (err) {
// 		console.error('[fetchDistributeToken] ', err)
// 	}
// }

export function* fetchMobileAppConfig() {
	try {
		const { data } = yield call(api.fetchMobileAppConfig);
		const mobileAppConfig = yield select(selectors.getMobileAppConfig);
		yield put(
			actions.mobileAppConfig({
				...data,
				...mobileAppConfig,
			})
		);
	} catch (err) {
		console.error("[fetchMobileAppConfig] ", err);
	}
}

function* onEnterDistribute() {
	while (true) {
		yield take(types.ON_ENTER_DISTRIBUTE);
		try {
			eventlog.info("enter distribute page");
			yield put(actions.isDistributeLoading(true));
			yield all([
				fetchMobileAppBuilds() /* , fetchDistributeToken() */,
				fetchMobileAppConfig(),
			]);
			yield put(actions.isDistributeLoading(false));
		} catch (err) {
			console.error("[onEnterDistribute] ", err);
			yield put(actions.isDistributeLoading(false));
			const toastManager = yield select(selectors.getToast);
			toastManager.error("Got the error from server");
			eventlog.error("enter distribute", {
				message: err.message,
			});
		}
	}
}

export default onEnterDistribute;
