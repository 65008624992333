import { edgeProps } from "../../config";
import { getToolDiagram } from "../global/diagram";

export const addEdge = (set, get, edges) => {
	try {
		setTimeout(() => {
			const data = [];
			edges.forEach((edgeNow) => {
				const haveSource = getToolDiagram("getNode")(edgeNow.source.nodeId);
				const haveTarget = getToolDiagram("getNode")(edgeNow.target.nodeId);
				const haveEdge = getToolDiagram("getEdge")(
					`${edgeNow.source.nodeId}-${edgeNow.source.handleId}|${edgeNow.target.nodeId}-${edgeNow.target.handleId}`
				);

				if (haveSource && haveTarget && !haveEdge) {
					data.push({
						...edgeProps,
						source: edgeNow.source.nodeId,
						sourceHandle: edgeNow.source.handleId,
						target: edgeNow.target.nodeId,
						targetHandle: edgeNow.target.handleId,
						id: `${edgeNow.source.nodeId}-${edgeNow.source.handleId}|${edgeNow.target.nodeId}-${edgeNow.target.handleId}`,
					});
				}
			});

			getToolDiagram("addEdges")(data);
		}, 1);
	} catch (err) {
		console.error("[addEdge] ", err);
	}
};
