import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* saveEditModalConfig() {
	while (true) {
		try {
			yield take(types.SAVE_EDIT_MODAL_CONFIG)
			eventlog.info('save edit modal config')
			yield put(actions.updateSaveConfigLoading(true))
			const editModalConfig = yield select(selectors.getEditModalConfig)
			const response = yield call(api.saveEditModalConfig, editModalConfig)
			yield put(actions.editModalConfig(response.data))

			const toastManager = yield select(selectors.getToast)
			toastManager.success('Saved successfully')

			yield put(actions.updateSaveConfigLoading(false))
		} catch (err) {
			console.error('[saveEditModalConfig] ', err)
			yield put(actions.updateSaveConfigLoading(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			eventlog.error('save edit modal config', {
				message: err.message
			})
		}
	}
}

export default saveEditModalConfig
