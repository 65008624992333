import MapHandler from "./map-handler";
import React, { useState } from "react";
import { PlaceAutocompleteClassic } from "./autocomplete-classic";

import {
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
  Marker
} from '@vis.gl/react-google-maps';


const MapComponent = (props) => {
  const { enableSearch, isShowMarker, onMapChange, center, apiKey } = props

  const [centerNow, SetCenterNow] = useState(center)
  const [selectedPlace, setSelectedPlace] = useState(null)

  return (
    <APIProvider apiKey={apiKey} libraries={['marker']}>
      <Map
        defaultZoom={3}
        defaultCenter={center}
        onCenterChanged={(e) => {
          const centerChange = e.detail.center
          if (centerChange.lat !== centerNow.lat || centerChange.lng !== centerNow.lng) {
            SetCenterNow({
              lat: centerChange.lat,
              lng: centerChange.lng,
            })
            if (typeof onMapChange === "function") {
              onMapChange({
                lat: centerChange.lat,
                lng: centerChange.lng,
              })
            }
          }
        }}
      >
        {isShowMarker && <Marker position={centerNow}></Marker>}
        {enableSearch && (
          <>
            <MapControl position={ControlPosition.TOP_RIGHT}>
              <div className="autocomplete-control">
                <PlaceAutocompleteClassic onPlaceSelect={setSelectedPlace} />
              </div>
            </MapControl>
            <MapHandler place={selectedPlace} onMapChange={onMapChange} SetCenterNow={SetCenterNow} />
          </>
        )}
      </Map>
    </APIProvider>
  );
};

export default MapComponent;
