import React from 'react'
import datatype from './datatype'
import { useTranslation } from 'react-i18next'

const Format = () => {
	const { t } = useTranslation('editMessage')
	return (
		<div className='variable-palette-caption'>
			<span>{t(`variableFormat.format`)}</span>
			<ul>
				<li>
					<span className={'datatype-item string'}>{datatype('string')}</span>
					<span>{t(`variableFormat.string`)}</span>
				</li>
				<li>
					<span className={'datatype-item number'}>{datatype('number')}</span>
					<span>{t(`variableFormat.number`)}</span>
				</li>
				<li>
					<span className={'datatype-item boolean'}>{datatype('boolean')}</span>
					<span>{t(`variableFormat.boolean`)}</span>
				</li>
				<li>
					<span className={'datatype-item datetime'}>{datatype('datetime')}</span>
					<span>{t(`variableFormat.datetime`)}</span>
				</li>
				<li>
					<span className={'datatype-item array'}>{datatype('array')}</span>
					<span>{t(`variableFormat.array`)}</span>
				</li>
				<li>
					<span className={'datatype-item object'}>{datatype('object')}</span>
					<span>{t(`variableFormat.object`)}</span>
				</li>
			</ul>
		</div>
	)
}

export default Format
