const calendar = {
    types: [
        {
            value: 'datetime',
            label: 'Date time'
        },
        {
            value: 'date',
            label: 'Date'
        },
        {
            value: 'time',
            label: 'Time'
        }
    ]
}

export default calendar