import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchLineConfig() {
	while (true) {
		yield take(types.FETCH_LINE_CONFIG)
		try {
            yield put(actions.isFetchingLineConfig(true))
			const data = yield call(api.fetchLineConfig)
            yield put(actions.lineConfig(data))
            yield put(actions.isFetchingLineConfig(false))
		} catch (err) {
            yield put(actions.isFetchingLineConfig(false))
			console.error('[fetchLineConfig] ', err)
			eventlog.error('fetch line config', {
				message: err.message
			})
		}
	}
}

export default fetchLineConfig
