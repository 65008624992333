import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* register() {
	while (true) {
		const {
			payload: { register }
		} = yield take(types.REGISTER)
		try {
			yield put(actions.registerLoading(true))
			yield put(actions.registerError({}))

			yield call(
				api.Register,
				register.email,
				register.password,
				register.firstName,
				register.lastName,
				register.recaptcha
			)

			yield put(actions.registerLoading(false))
			yield put(actions.isRegisterSuccess(true))
		} catch (err) {
			const {
				response: { data }
			} = err
			yield put(actions.registerLoading(false))
			yield put(actions.registerError(data))
			console.error('[register] ', err)
			eventlog.error('register', {
				message: err.message
			})
		}
	}
}

export default register
