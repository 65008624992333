import * as api from "../apis";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, take } from "redux-saga/effects";

function* declineInvitation() {
    while (true) {
        const {
            payload: { userID },
        } = yield take(types.DECLINE_INVITATION);
        try {
            localStorage.setItem("isInvitation", "");

            const query = new URLSearchParams(window.location.search);
            yield call(api.declineInvitation, query.get("access_token"), userID);
            localStorage.setItem(
                "InvitationMessage",
                "success:Decline invitation success"
            );
            window.location.href = api.APP_HOSTNAME;
        } catch (err) {
            console.error("[declineInvitation] ", err);
            eventlog.error("decline invitation", {
                message: err.message,
            });
            localStorage.setItem(
                "InvitationMessage",
                "error:Invitation link token have been used or expired"
            );
            window.location.href = api.APP_HOSTNAME;
        }
    }
}

export default declineInvitation;
