import "./edge.scss";
import React, { memo, useCallback } from "react";
import { BaseEdge, getSmoothStepPath, useReactFlow } from "@xyflow/react";
import { STROKE_COLOR, STROKE_COLOR_SELECTED, edgeProps } from "../config";
import { EdgeLabel } from "./edgeLabel";

function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}) {
    targetX -= 13;

    const { getEdge } = useReactFlow();
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        borderRadius: edgeProps.borderRadius,
    });

    const isSelectedEdge = useCallback(() => {
        const edge = getEdge(id);
        return edge.selected;
    }, [getEdge, id]);

    const colorChangeMarkerArrow = useCallback(() => {
        return isSelectedEdge() ? STROKE_COLOR_SELECTED : STROKE_COLOR;
    }, [isSelectedEdge]);

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <path
                fill={colorChangeMarkerArrow()}
                stroke={colorChangeMarkerArrow()}
                pointerEvents="all"
                d="M 0 0 L 20 10 L 0 20 z"
                transform={"translate(" + (targetX - 10) + ", " + (targetY - 10) + ")"}
            />
            <EdgeLabel id={id} labelX={labelX} labelY={labelY} />
        </>
    );
}

export default memo(CustomEdge);
