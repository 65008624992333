const quickReply = (editMessage) => {
	const errors = new Array(13);

	if (editMessage.input.textinput.enum.length > 13) {
		errors[14] = "buttonMax"
	}
	if (editMessage.input.textinput.enum.length < 1) {
		errors[14] = "buttonRequired"
	}

	editMessage.input.textinput.enum.forEach((item, index) => {
		const error = {};

		if (item.image !== '' && item.image !== undefined) {
			if (!(item.size > 0 && item.size <= 1000000)) {
				error.size = 'size'
			}

			if (!['image/x-png', 'image/png'].includes(item.extension)) {
				error.extension = 'extension'
			}

			if (!(item.dimension.width / item.dimension.height === 1)) {
				error.ratio = 'ratio'
			}

			if (Object.keys(error).length > 0) {
				errors[index] = error
			}
		}
	})

	return errors
}

export default quickReply