import "./primary-buttons.css";
import * as React from "react";
import { OldPlayIcon, StopIcon } from "../ui/icons";

export const PrimaryButton = ({ className, ...props }) => {
  return (
    <div
      className={[
        "primary-button",
        className,
        props.disabled ? "disabled" : "",
      ].join(" ")}
    >
      <button type="button" {...props} />
      <div className="background" />
    </div>
  )
}

export const PlayButton = ({ isPlaying, ...props }) => (
  <PrimaryButton
    className={isPlaying ? "stop" : "play"}
    {...props}
  >
    {isPlaying ? <StopIcon /> : <OldPlayIcon />}
  </PrimaryButton>
)