import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchVariables() {
	while (true) {
		yield take(types.FETCH_VARIABLES)
		try {
			const { data: { variables } } = yield call(api.fetchVariables)
			yield put(actions.variables(variables))
		} catch (err) {
			console.error('[fetchVariables] ', err)
			yield put(actions.variables({}))
			eventlog.error('fetch variables error', {
				message: err.message
			})
		}
	}
}

export default fetchVariables
