import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchResponsesSaga() {
	while (true) {
		// visitorId is defined only doszy and visitor page
		const { payload: { visitorId }} = yield take(types.FETCH_RESPONSES)
		try {
			const selectedApp = yield select(selectors.getSelectedApp)
			const { data: responses } = yield call(api.fetchResponses, selectedApp.platform, visitorId)
			yield put(actions.responses(responses || []))
			yield put(actions.isResponsesLoading(false))
		} catch (err) {
			console.error('[fetchResponsesSaga] ', err)
			yield put(actions.isResponsesLoading(false))
			eventlog.error('fetch response saga', {
				message: err.message
			})
		}
	}
}

export default fetchResponsesSaga
