import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* saveVariables() {
	while (true) {
		yield take(types.SAVE_VARIABLES)
		try {
			const config = yield select(selectors.getVariables)
            const { data: { variables }} = yield call(api.saveVariables, config)
            yield put(actions.variables(variables))
		} catch (err) {
			console.error('[saveVariables] ', err)
			eventlog.error('save variables', {
				message: err.message
			})
		}
	}
}

export default saveVariables
