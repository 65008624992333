import * as api from "../apis";
import * as types from "../types";
import { call, take } from "redux-saga/effects";
import { nodeId, nodeProps } from "../../components/diagram/config";
import { useStoreDiagram } from "../../components/diagram/store";
import { transformMessageConfig } from "../../components/diagram/utils/transform";

function* fetchMessageConfig() {
	while (true) {
		yield take(types.FETCH_MESSAGE_CONFIG);
		try {
			const response = yield call(api.messages, "");
			const messages = transformMessageConfig(response.data.messages || []);

			// if there is no start, should add default
			if (!messages[nodeId.START]) {
				messages[nodeId.START] = {
					...nodeProps,
					trigger: null,
					position: {
						x: 210,
						y: 90,
					},
					points: [],
					mode: "UNION-MESSAGE",
					messages: [
						{
							mode: "MESSAGE",
							message: ``,
						},
					],
					id: nodeId.START,
				};
			}

			// add messages to diagram
			useStoreDiagram
				.getState()
				.addNewNodeInterface(Object.values(messages), true);
		} catch (e) {
			console.error("[fetchMessageConfig] ", e);

			const messages = {
				START: {
					...nodeProps,
					trigger: null,
					position: {
						x: 210,
						y: 90,
					},
					points: [],
					mode: "UNION-MESSAGE",
					messages: [
						{
							mode: "MESSAGE",
							message: ``,
						},
					],
					id: nodeId.START,
				},
			};

			// add messages to diagram
			useStoreDiagram.getState().addNewNodeInterface(Object.values(messages));
		}
	}
}

export default fetchMessageConfig;
