class EventLog {
    init(token) { }

    logs = [];

    addContext(key, value) { }

    removeContext(key) { }

    keepLog(status, message, context) {
        this.logs.push({
            status,
            message,
            context,
        });
    }

    sendKeepLogs() { }

    sendLog(status, message, context) { }

    info(message, context) { }

    debug(message, context) { }

    warn(message, context) { }

    error(message, context) { }
}

const eventLog = new EventLog();

export default eventLog;
