import React from 'react'

const datetype = (type) => {
    switch (type) {
        case 'string':
            return (<span>S</span>)
        case 'number':
            return (<span>1</span>)
        case 'datetime':
            return (<svg style={{ transform: 'scale(.7)', fill: 'currentColor' }} data-icon='calendar' width='16' height='16' viewBox='0 0 16 16'><desc>calendar</desc><path d='M11 3c.6 0 1-.5 1-1V1c0-.6-.4-1-1-1s-1 .4-1 1v1c0 .5.4 1 1 1zm3-2h-1v1c0 1.1-.9 2-2 2s-2-.9-2-2V1H6v1c0 1.1-.9 2-2 2s-2-.9-2-2V1H1c-.6 0-1 .5-1 1v12c0 .6.4 1 1 1h13c.6 0 1-.4 1-1V2c0-.6-.5-1-1-1zM5 13H2v-3h3v3zm0-4H2V6h3v3zm4 4H6v-3h3v3zm0-4H6V6h3v3zm4 4h-3v-3h3v3zm0-4h-3V6h3v3zM4 3c.6 0 1-.5 1-1V1c0-.6-.4-1-1-1S3 .4 3 1v1c0 .5.4 1 1 1z' fillRule='evenodd'></path></svg>)
        case 'array':
            return (<svg style={{ transform: 'scale(.7)', fill: 'currentColor' }} data-icon='properties' width='16' height='16' viewBox='0 0 16 16'><desc>properties</desc><path d='M2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm4-3h9c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1s.45 1 1 1zm-4 9c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm13-5H6c-.55 0-1 .45-1 1s.45 1 1 1h9c.55 0 1-.45 1-1s-.45-1-1-1zm0 6H6c-.55 0-1 .45-1 1s.45 1 1 1h9c.55 0 1-.45 1-1s-.45-1-1-1zM2 0C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z' fillRule='evenodd'></path></svg>)
        case 'boolean':
            return (<span>B</span>)
        case 'object':
            return (<span>O</span>)
        default:
            return (<span></span>)
    }
}

export default datetype