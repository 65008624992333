import * as types from '../types'

const auth = (
	state = {
		isRegisterSuccess: false,
		loginForm: {
			email: '',
			password: '',
		},
		isRequestingResetPassword: false,
		forgotPasswordError: null,
		isForgotPasswordSuccess: false,
		forgotPasswordForm: {
			email: '',
		},
		isResettingPassword: false,
		resetPasswordError: null,
		isResetPasswordSuccess: false,
		resetPasswordForm: {
			password: '',
			confirmPassword: '',
		},
		authData: {},
		isInvitationURL: false,
	},
	action
) => {
	switch (action.type) {
		case types.SIGNIN_ERROR:
			return {
				...state,
				signInError: action.payload.error,
			}
		case types.SIGNIN_LOADING:
			return {
				...state,
				signInLoading: action.payload.loading,
			}
		case types.REGISTER_LOADING:
			return {
				...state,
				registerLoading: action.payload.loading,
			}
		case types.REGISTER_ERROR:
			return {
				...state,
				registerError: action.payload.error,
			}
		case types.IS_REGISTER_SUCCESS:
			return {
				...state,
				isRegisterSuccess: action.payload.status,
			}
		case types.LOGIN_FORM:
			return {
				...state,
				loginForm: action.payload.loginForm,
			}
		case types.IS_REQUESTING_RESET_PASSWORD:
			return {
				...state,
				isRequestingResetPassword: action.payload.isRequesting,
			}
		case types.FORGOT_PASSWORD_ERROR:
			return {
				...state,
				forgotPasswordError: action.payload.error,
			}
		case types.IS_FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				isForgotPasswordSuccess: action.payload.isSuccess,
			}
		case types.FORGOT_PASSWORD_FORM:
			return {
				...state,
				forgotPasswordForm: action.payload.form,
			}
		case types.IS_RESETTING_PASSWORD:
			return {
				...state,
				isResettingPassword: action.payload.isResetting,
			}
		case types.RESET_PASSWORD_ERROR:
			return {
				...state,
				resetPasswordError: action.payload.error,
			}
		case types.IS_RESET_PASSWORD_SUCCESS:
			return {
				...state,
				isResetPasswordSuccess: action.payload.isSuccess,
			}
		case types.RESET_PASSWORD_FORM:
			return {
				...state,
				resetPasswordForm: action.payload.form,
			}
		case types.AUTH_DATA:
			return {
				...state,
				authData: action.payload.data
			}
		case types.ACCESS_CONTROLS:
			if (Array.isArray(action.payload.accessControls)) {
				return {
					...state,
					accessControls: action.payload.accessControls.reduce((o, n) => {
						return {
							...o,
							[n.slug]: n,
						}
					}, {}),
				}
			}

			return {
				...state,
				accessControls: action.payload.accessControls,
			}
		default:
			return state
	}
}

export default auth
