import * as types from '../types'

const product = (
	state = {
		products: [],
	},
	action
) => {
	switch (action.type) {
		case types.PRODUCTS:
			return {
				...state,
				products: action.payload.products,
			}
		default:
			return state
	}
}

export default product
