import { DIAGRAM_TYPE_FORCE_LAYOUT, nodeId } from "../../config";
import { getToolDiagram } from "../global/diagram";

export const takeSnapshot = (set, get, type, data) => {
	try {
		const getNodes = getToolDiagram("getNodes");
		const getEdges = getToolDiagram("getEdges");
		if (
			getNodes().filter(
				(node) =>
					node.id === nodeId.DRAFT || node.type === DIAGRAM_TYPE_FORCE_LAYOUT
			).length > 0
		) {
			return;
		}

		const maxHistorySize =
			Number(process.env.REACT_APP_HISTORY_DIAGRAM_SIZE_MAX) || 100;

		// push the current graph to the past state
		set((state) => ({
			past: [
				...state.past.slice(state.past.length - maxHistorySize + 1, state.past.length),
				{ type, data, id: data.id, nodes: getNodes(), edges: getEdges() },
			],
		}));

		// whenever we take a new snapshot, the redo operations need to be cleared to avoid state mismatches
		set({
			future: [],
		});
	} catch (err) {
		console.error("[takeSnapshot] ", err);
	}
};
