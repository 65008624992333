import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

// generate to nano unix to make it compatiable with backend from go
const generateMessageId = () => {
	return new Date().getTime() * 1000000
}

function* onSendMessage() {
	while (true) {
		const {
			payload: { visitor, message: sendingMessage },
		} = yield take(types.ON_SEND_MESSAGE)

		eventlog.info('send chat message', {
			visitor,
			message: sendingMessage
		})

		const id = generateMessageId()
		const message = {
			...sendingMessage,
			_id: id,
			id: id,
			createdAt: new Date(),
			user: {
				id: 2,
				_id: 2,
			},
			question: {},
		}

		try {
			const oldMessages = yield select(selectors.getMessengerMessages)

			yield put(
				actions.visitorMessages({
					[visitor._id]: [message, ...(oldMessages[visitor._id] || [])],
					...oldMessages,
				})
			)

			const apps = yield select(selectors.getApps)
			const appSlug = apps.find((app) => app._id === visitor.app_id).slug

			yield call(api.sendMessage, appSlug, message, visitor._id)
		} catch (err) {
			console.error('[onSendSaga] ', err)
			const failedMessages = yield select(selectors.getFailedMessages)
			yield put(
				actions.failedMessages({
					...failedMessages,
					[id]: {
						...message,
					},
				})
			)
			eventlog.error('send message', {
				message: err.message
			})
		}
	}
}

export default onSendMessage
