const businessHours = (editMessage) => {
	let errors = {}
	errors['business_hours'] = {}

	editMessage.input.business_hours.forEach((day, index) => {
		if (day.is_enabled) {
			day.time.forEach((time, timeIndex) => {

				let error = {}
				if (time.from === "") {
					if (!error[timeIndex]) {
						error[timeIndex] = {};
					}

					error[timeIndex].from = "From time is required";
				}

				if (time.to === "") {
					if (!error[timeIndex]) {
						error[timeIndex] = {};
					}
					error[timeIndex].to = "To time is required";

				}

				var timeFrom = time.from.split(":");
				var timeTo = time.to.split(":");

				var dateFrom = new Date(0, 0, 0, timeFrom[0], timeFrom[1]);
				var dateTo = new Date(0, 0, 0, timeTo[0], timeTo[1]);

				if (dateFrom > dateTo) {
					if (!error[timeIndex]) {
						error[timeIndex] = {};
					}
					error[timeIndex].time = "End time must be after start time"
				} else if (dateFrom < dateTo) {
				} else {
					if (!error[timeIndex]) {
						error[timeIndex] = {};
					}
					error[timeIndex].time = "End time must be after start time"
				}

				if (Object.keys(error).length !== 0) {
					errors['business_hours'][index] = error;
				}
			})
		}
	})

	errors['closed_days'] = {}

	editMessage.input.closed_days.forEach((day, index) => {
		let error = {}

		if (!day.is_single_day) {

			if (day.date.start === undefined || day.date.start === null) {
				error.start = "Start date is required";
			}

			if (day.date.end === undefined || day.date.end === null) {
				error.end = "End date is required";
			}

			if (day.date.start > day.date.end) {
				error.date = "Start date is after End date";
			}

			if (Object.keys(error).length !== 0) {
				errors['closed_days'][index] = error;
			}
		}
	})

	errors['special_days'] = {}

	editMessage.input.special_days.forEach((day, index) => {
		let error = {}

		if (!day.is_single_day) {

			if (day.date.start === undefined || day.date.start === null) {
				error.start = "Start time is required";
			}

			if (day.date.end === undefined || day.date.end === null) {
				error.end = "End time is required";
			}

			if (day.date.start > day.date.end) {
				error.date = "Start date is after End date";
			}
		}

		day.time.forEach((time, timeIndex) => {

			if (time.from === '') {
				error[timeIndex] = {};
				error[timeIndex].from = "From time is required";
			}

			if (time.to === '') {
				if (!error[timeIndex]) {
					error[timeIndex] = {};
				}
				error[timeIndex].to = "To time is required";
			}

			var timeFrom = time.from.split(":");
			var timeTo = time.to.split(":");

			var dateFrom = new Date(0, 0, 0, timeFrom[0], timeFrom[1]);
			var dateTo = new Date(0, 0, 0, timeTo[0], timeTo[1]);

			if (dateFrom > dateTo) {
				if (!error[timeIndex]) {
					error[timeIndex] = {};
				}
				error[timeIndex].time = "End time must be after start time"
			} else if (dateFrom < dateTo) {
			} else {
				if (!error[timeIndex]) {
					error[timeIndex] = {};
				}
				error[timeIndex].time = "End time must be after start time"
			}
		})

		if (Object.keys(error).length !== 0) {
			errors['special_days'][index] = error;
		}
	})

	const errorObjects = ['business_hours', 'closed_days', 'special_days'];

	if (errorObjects.every(objKey => Object.keys(errors[objKey]).length === 0)) {
		errors = {};
	}

	return errors;
};


export default businessHours