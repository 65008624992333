import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

function* humanTakeoverStart() {
	while (true) {
		const { payload: { appId, roomId }} = yield take(types.HUMAN_TAKEOVER_START)
		try {
			const apps = yield select(selectors.getApps)
			const appSlug = apps.find((app) => app._id === appId).slug

			yield call(api.humanTakeoverStart, appSlug, roomId)

			const visitors = yield select(selectors.getVisitors)

			yield put(actions.visitors({
				...visitors,
				[roomId]: {
					...visitors[roomId],
					human_takeover_enabled: false,
					admin_takeover_id: "admin_id",
				}
			}))
		} catch (err) {
			console.error('[humanTakeoverStart] ', err)
		}
	}
}

export default humanTakeoverStart
