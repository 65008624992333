const paymentTypes = [
    {
        id: 1,
		type: 'mobile_number',
		name: 'Mobile number',
	},
    {
        id: 2,
		type: 'national_id_or_tax_id',
		name: 'National id or tax id',
	
	},
    {
        id: 3,
		type: 'e_wallet_id',
		name: 'E wallet id',
	}
]

export default paymentTypes