import * as types from '../types'

import { APP_HOSTNAME } from '../apis'
import eventlog from '../../modules/eventlog'
import { take } from 'redux-saga/effects'

function* signout() {
	while (true) {
		yield take(types.LOGOUT)
		try {
			const redirectLogin = localStorage.getItem('redirectLogin')
			const isInvitation = localStorage.getItem('isInvitation')
			localStorage.clear()
			window.location.href = APP_HOSTNAME
			window.location.reload()
			localStorage.setItem('redirectLogin', redirectLogin);
			localStorage.setItem('isInvitation', isInvitation);
		} catch (err) {
			console.error('[signout] ', err)
			eventlog.error('signout', {
				message: err.message
			})
		}
	}
}

export default signout
