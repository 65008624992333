import React, { useCallback } from "react";
import { EdgeLabelRenderer, useReactFlow } from "@xyflow/react";
import { useStoreDiagram } from "../store";

export const EdgeLabel = (props) => {
    const { id, labelX, labelY } = props;

    const { getEdge, deleteElements } = useReactFlow();

    const onEdgeClick = useCallback(() => {
        deleteElements({ edges: [{ id }] });
    }, [deleteElements, id]);

    const isSelectedEdge = useCallback(() => {
        const edge = getEdge(id);
        return edge.selected;
    }, [getEdge, id]);

    const endDragEdge = useCallback(
        (nodeIdSource, handleIdSource, nodeIdTarget, handleIdTarget) => {
            useStoreDiagram
                .getState()
                .endDragEdge(
                    nodeIdSource,
                    handleIdSource,
                    nodeIdTarget,
                    handleIdTarget
                );
        },
        []
    );

    const createDraftNode = useCallback((position) => {
        useStoreDiagram.getState().createDraftNode(position);
    }, []);

    const onCreateDraftNodeSelectedLinks = useCallback(() => {
        const edge = getEdge(id);

        endDragEdge(edge.source, edge.sourceHandle, edge.target, edge.targetHandle);

        createDraftNode({
            x: window.mousePositionDiagram.current.x,
            y: window.mousePositionDiagram.current.y,
        });
    }, [createDraftNode, endDragEdge, getEdge, id]);

    return (
        <EdgeLabelRenderer>
            <div
                style={{
                    position: "absolute",
                    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                    fontSize: 12,
                    // everything inside EdgeLabelRenderer has no pointer events by default
                    // if you have an interactive element, set pointer-events: all
                    pointerEvents: "all",
                    display: isSelectedEdge() ? "" : "none",
                }}
                className="nodrag nopan"
            >
                <button className="edgebutton edge-delete" onClick={onEdgeClick}>
                    <i className="fa fa-trash"></i>
                </button>
                <div className="space" />
                <button
                    className="edgebutton edge-create-node-draft"
                    onClick={onCreateDraftNodeSelectedLinks}
                >
                    <i className="fa fa-plus"></i>
                </button>
            </div>
        </EdgeLabelRenderer>
    );
};
