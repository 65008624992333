// Redux
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux'

// Reducers
import Reducers from './reducers'
// Redux Saga
import createSagaMiddleware from 'redux-saga'
// Saga
import rootSaga from './sagas'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const composeWithDevTools =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// mount it on the Store
export const store = createStore(
    Reducers,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)