import ComponentNode from "./node";
import React, { memo } from "react";

function NodeModel(props) {
    return (
        <>
            <ComponentNode {...props} />
        </>
    );
}

export default memo(NodeModel);
