import Picker from "@emoji-mart/react";
import React, { useCallback, useEffect, useRef } from "react";
import Variables from "./variables";
import _ from "lodash";
import emojiMartData from "@emoji-mart/data";
import styles from "./button.module.scss";
import toolsStyles from "./tools.module.scss";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { useSelector } from "react-redux";

import {
	Editor,
	EditorState,
	Modifier,
	RichUtils,
	convertFromRaw,
	convertToRaw,
} from "draft-js";

const TextAreaAutoSize = (props) => {
	const value = props.value || "";
	const onChange = props.onChange;

	const selectedApp = useSelector((state) => state.system.selectedApp);
	const [editorState, setEditorState] = React.useState(() =>
		EditorState.createWithContent(convertFromRaw(markdownToDraft(value)))
	);
	// const [isEditing, setIsEditing] = React.useState(false);
	const [isShowingEmojiPicker, setIsShowingEmojiPicker] = React.useState(false);

	const editorInputRef = useRef(null);

	useEffect(() => {
		setEditorState(EditorState.createWithContent(convertFromRaw(markdownToDraft(value))))
	}, [value]);

	const onBlurEditor = useCallback(() => {
		const rawContent = convertToRaw(editorState.getCurrentContent());
		const markdownString = draftToMarkdown(rawContent);
		onChange?.(markdownString);
	}, [editorState, onChange]);

	useEffect(() => {
		const interval = setInterval(() => {
			onBlurEditor()
		}, 1000);
		return () => clearInterval(interval)
	}, [onBlurEditor])

	const editorContainerRef = useRef(null);
	// const handleClickOutside = useCallback(
	// 	(event) => {
	// 		if (
	// 			isEditing &&
	// 			editorContainerRef &&
	// 			!editorContainerRef.current.contains(event.target)
	// 		) {
	// 			setIsEditing(false);
	// 			setIsShowingEmojiPicker(false);
	// 			onBlurEditor();
	// 		}
	// 	},
	// 	[isEditing, editorContainerRef, onBlurEditor]
	// );

	const onUnderlineClick = useCallback(() => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
	}, [editorState]);

	const onBoldClick = useCallback(() => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
	}, [editorState]);

	const onItalicClick = useCallback(() => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
	}, [editorState]);

	const onStrikeThroughClick = useCallback(() => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, "STRIKETHROUGH"));
	}, [editorState]);

	const insertText = useCallback(
		(text) => {
			const newContent = Modifier.replaceText(
				editorState.getCurrentContent(),
				editorState.getSelection(),
				text
			);
			const newEditorState = EditorState.push(
				editorState,
				newContent,
				"insert-characters"
			);
			return EditorState.forceSelection(
				newEditorState,
				newContent.getSelectionAfter()
			);
		},
		[editorState]
	);

	const onOptionSelected = useCallback(
		(txtToAdd) => {
			setEditorState(insertText(txtToAdd));
		},
		[insertText, setEditorState]
	);

	const onAddEmoji = useCallback(
		(e) => {
			let sym = e.unified.split("-");
			let codesArray = [];
			sym.forEach((el) => codesArray.push("0x" + el));
			let emoji = String.fromCodePoint(...codesArray);
			setEditorState(insertText(emoji));
		},
		[insertText, setEditorState]
	);

	// const onEnableEditing = useCallback(() => {
	// 	setIsEditing(true);
	// }, []);

	// useEffect(() => {
	// 	if (isEditing) {
	// 		document.addEventListener("mousedown", handleClickOutside);
	// 	} else {
	// 		document.removeEventListener("mousedown", handleClickOutside);
	// 	}
	// 	return () => {
	// 		document.removeEventListener("mousedown", handleClickOutside);
	// 	};
	// }, [isEditing, handleClickOutside]);

	const onToggleEmojiPicker = useCallback(() => {
		setIsShowingEmojiPicker(!isShowingEmojiPicker);
	}, [isShowingEmojiPicker]);

	// useEffect(() => {
	// 	if (isEditing) {
	// 		editorInputRef.current.focus();
	// 	}
	// }, [isEditing]);

	return (
		<div
			className={`nodrag textarea-autosize ${_.get(selectedApp, "platform") === "line" ? "line-platform-editor" : ""
				}`}
			ref={editorContainerRef}
		>
			{/* {!isEditing && (
				<div className="textarea-autosize-preview" onClick={onEnableEditing}>
					<Editor
						placeholder="Add a message"
						editorState={editorState}
						readOnly={true}
					/>
				</div>
			)} */}
			{(
				<div>
					<div>
						<Editor
							placeholder="Add a message"
							ref={editorInputRef}
							editorState={editorState}
							onChange={setEditorState}
						/>
					</div>
					<div className={toolsStyles.container}>
						<Variables
							onOptionSelected={onOptionSelected}
							messageId={props.messageId}
						/>
						<button
							type="button"
							className={`text-style ${styles.styleButton} ${styles.underline}`}
							onClick={onUnderlineClick}
						>
							U
						</button>
						<button
							type="button"
							className={`text-style ${styles.styleButton}`}
							onClick={onBoldClick}
						>
							<b>B</b>
						</button>
						<button
							type="button"
							className={`text-style ${styles.styleButton}`}
							onClick={onItalicClick}
						>
							<em>I</em>
						</button>
						<div style={{ flex: 1 }}></div>
						<button
							type="button"
							className={`text-style ${styles.styleButton} ${styles.lineThrough}`}
							onClick={onStrikeThroughClick}
						>
							abc
						</button>
						<div className="emoji-layout nowheel">
							<button
								type="button"
								className={`text-style ${styles.styleButton}`}
								onClick={onToggleEmojiPicker}
							>
								<span>{String.fromCodePoint("0x1f600")}</span>
							</button>
							{isShowingEmojiPicker && (
								// <Picker
								// 	set='apple'
								// 	showPreview={false}
								// 	showSkinTones={false}
								// 	onSelect={onAddEmoji}
								// 	style={{
								// 		position: 'absolute',
								// 		top: '0px',
								// 		marginLeft: '20px',
								// 		zIndex: 2,
								// 	}}
								// />
								<div
									style={{
										position: "absolute",
										marginTop: "10px",
										zIndex: 3,
									}}
								>
									<Picker data={emojiMartData} onEmojiSelect={onAddEmoji} />
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default TextAreaAutoSize;
