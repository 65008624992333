import CustomHandle from "./customHandle";
import React from "react";
import { Position } from "@xyflow/react";
import { handleConnectableMax } from "../../../config";

function HandleSource(props) {
    const { keyUnique = crypto.randomUUID(), id, isTarget, position = Position.Right, isConnectable = handleConnectableMax.xtra } = props;

    return (
        <CustomHandle
            key={keyUnique}
            id={id}
            type="source"
            position={position}
            isConnectable={isConnectable}
            style={
                isTarget
                    ? {
                        display: "none",
                    }
                    : {}
            }
        />
    );
}
export default HandleSource;
