const paymentConfirmationGatewayBank = [
    {
        id: 1,
        bank: 'KBANK',
    },
    {
        id: 2,
        bank: 'SCB',
    }
]

export default paymentConfirmationGatewayBank