export const transformMessageConfig = (messages) => {
    return messages.reduce((o, message) => {
        return {
            ...o,
            [message.id]: {
                ...message,
            },
        };
    }, {});
};

export const transformDiagram = (messages) => {
    return Object.values(messages);
};
