import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchResponsesStats() {
	while (true) {
		yield take(types.FETCH_RESPONSES_STATS)
		try {
			yield put(actions.isFetchingResponsesStats(true))
			const { data: responses } = yield call(api.fetchResponsesStats)
			yield put(actions.responsesStats(responses))
			yield put(actions.isFetchingResponsesStats(false))
		} catch (err) {
			console.error('[fetchResponseStats] ', err)
			eventlog.error('fetch response status', {
				message: err.message
			})
		}
	}
}

export default fetchResponsesStats
