import * as actions from "../actions";
import * as api from "../apis";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, put, take } from "redux-saga/effects";

function* fetchLineInsightFriendQuery() {
	while (true) {
		const {
			payload: { limit, offset, search },
		} = yield take(types.LINE_INSIGHT_FRIEND_QUERY);
		try {
			eventlog.info("fetch line Insight Friend");
			yield put(actions.isFetchingLineInsightFriend(true));
			const { data } = yield call(
				api.fetchLineCustomerWithQuery,
				limit,
				offset,
				search
			); //

			yield put(actions.lineInsightFriend(data));
			yield put(actions.isFetchingLineInsightFriend(false));
		} catch (err) {
			yield put(actions.isFetchingLineInsightFriend(false));
			console.error("[onFetchLineInsight Friend] ", err);
			eventlog.error("enter line Insight Friend", {
				message: err.message,
			});
		}
	}
}

export default fetchLineInsightFriendQuery;
