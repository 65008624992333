import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* enterAppManagement() {
	while (true) {
		try {
			yield take(types.ENTER_APP_MANAGEMENT)

			eventlog.removeContext('appId')
			eventlog.info('enter app management')

			// clear config
			yield put(actions.mobileAppBuilds([]))
			yield put(actions.mobileAppConfig({}))
			yield put(actions.editMessage(null))
			yield put(actions.removedMessages([]))
			yield put(actions.variables({}))

			const { data: templates } = yield call(api.fetchTemplateConfigs)
			yield put(actions.onFetchTemplateConfig(templates.reduce((o, n) => {
				return {
					...o,
					[n._id]: {
						...n
					}
				}
			}, {})))
		} catch (err) {
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			console.error('[enterAppManagement] ', err)
			eventlog.error('enter app management', {
				message: err.message
			})
		}
	}
}

export default enterAppManagement
