import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'
import { fetchMobileAppConfig } from './onEnterDistribute'

function* autoGenMobileAppConfig() {
    while (true) {
        yield take(types.AUTO_GEN_MOBILE_APP_CONFIG)
        try {
            yield put(actions.isGeneratingMobileAppConfig(true))
            eventlog.info('auto gen mobile app config')
            yield call(api.autoGenMobileAppConfig)
            yield* fetchMobileAppConfig()
            yield put(actions.isGeneratingMobileAppConfig(false))
        } catch (err) {
            console.error('[auto gen mobile app config] ', err)
            eventlog.error('auto gen mobile app config', {
				message: err.message
            })
            yield put(actions.isGeneratingMobileAppConfig(false))
        }
    }
}

export default autoGenMobileAppConfig