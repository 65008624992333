import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'
import moment from 'moment'

function* fetchAnalytic() {
	while (true) {
		const { payload: { start, end }} = yield take(types.FETCH_ANALYTIC)
		try {
			const dateStart = moment(start)
			const dateEnd = moment(end)

            const { data } = yield call(api.fetchAnalytic, dateStart.utc().format('MM-DD-YYYY'), dateEnd.utc().format('MM-DD-YYYY'))
            yield put(actions.analyticData(data))
		} catch (err) {
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			console.error('[fetchAnalytic] ', err)
			eventlog.error('fetch analytic', {
				message: err.message
			})
		}
	}
}

export default fetchAnalytic
