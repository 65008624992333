const advancedMessages = {
	ESSENTIALS: {
		name: 'ESSENTIALS',
		messages: {
			'UNION-MESSAGE/END': {
				slug: 'UNION-MESSAGE/END',
				image: '/images/message-icons/finish.svg',
				name: 'Ending point',
				description: 'Ending point',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'DIAGRAM/GROUP': {
				slug: 'DIAGRAM/GROUP',
				image: '/images/message-icons/group.png',
				name: 'Group',
				description: 'Group node component',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'UNION-MESSAGE/MESSAGE': {
				slug: 'UNION-MESSAGE/MESSAGE',
				image: '/images/message-icons/messenger.svg',
				name: 'Send a message',
				description: 'Send a message',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'UNION-MESSAGE/BUTTON': {
				slug: 'UNION-MESSAGE/BUTTON',
				image: '/images/message-icons/button.svg',
				name: 'Buttons',
				description: 'Choices based on buttons',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'UNION-MESSAGE/MEDIA': {
				slug: 'UNION-MESSAGE/MEDIA',
				image: '/images/message-icons/picture.svg',
				name: 'Media',
				description: 'Send a media',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'UNION-MESSAGE/MAP': {
				slug: 'UNION-MESSAGE/MAP',
				image: '/images/message-icons/map.svg',
				name: 'Map',
				description: 'Show a map location',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'UNION-MESSAGE/AUDIO': {
				slug: 'UNION-MESSAGE/AUDIO',
				image: '/images/message-icons/speaker.png',
				name: 'Audio',
				description: 'Send an audio',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
		},
	},
	LINE: {
		name: 'LINE',
		messages: {
			'LINE/RICH-MENU': {
				slug: 'LINE/RICH-MENU',
				image: '/images/message-icons/richmenu.jpeg',
				name: 'Rich menu',
				description: 'Rich menu',
				platform: {
					line: true,
				},
			},
			'LINE/STICKER': {
				slug: 'LINE/STICKER',
				image:
					'https://stickershop.line-scdn.net/stickershop/v1/sticker/1/android/sticker.png',
				name: 'Sticker',
				description: 'Sticker',
				platform: {
					line: true,
				},
			},
			'LINE/IMAGE-MAP': {
				slug: 'LINE/IMAGE-MAP',
				image: '/images/message-icons/imagemap.png',
				name: 'Image map',
				description: 'Image map',
				platform: {
					line: true,
				},
			},
			'LINE/BUTTON-TEMPLATE': {
				slug: 'LINE/BUTTON-TEMPLATE',
				image: '/images/message-icons/button-template.png',
				name: 'Button template',
				description: 'Button template',
				platform: {
					line: true,
				},
			},
			'LINE/CONFIRM-TEMPLATE': {
				slug: 'LINE/CONFIRM-TEMPLATE',
				image: '/images/message-icons/confirm-template.png',
				name: 'Confirm template',
				description: 'Confirm template',
				platform: {
					line: true,
				},
			},
			'LINE/CAROUSEL-TEMPLATE': {
				slug: 'LINE/CAROUSEL-TEMPLATE',
				image: '/images/message-icons/carousel-template.png',
				name: 'Carousel template',
				description: 'Carousel template',
				platform: {
					line: true,
				},
			},
			'LINE/IMAGE-CAROUSEL-TEMPLATE': {
				slug: 'LINE/IMAGE-CAROUSEL-TEMPLATE',
				image: '/images/message-icons/image-carousel-template.png',
				name: 'Image carousel template',
				description: 'Image carousel template',
				platform: {
					line: true,
				},
			}
		},
	},
	FLEX_MESSAGE: {
		name: 'FLEX MESSAGES',
		messages: {
			'FLEX/RESTAURANT': {
				slug: 'FLEX/RESTAURANT',
				image: '/images/message-icons/restaurant.png',
				name: 'Restaurant',
				description: '2020 BKKMENU.com by BKKMENU Co., Ltd. EATGOPLAYSHOP. Most Popular. E',
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/HOTEL': {
				slug: 'FLEX/HOTEL',
				image: '/images/message-icons/hotel.jpg',
				name: 'Hotel',
				description:
					'Find Best Hotel Deals. Shock Sale Up To 80% Off. Book now! Best Price Guarantee 23 Million+',
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/REAL_ESTATE': {
				slug: 'FLEX/REAL_ESTATE',
				image: '/images/message-icons/real_estate.jpeg',
				name: 'Real Estate',
				description: `ealestate.com.au is Australia's No.1 property site for real estate.`,
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/RECEIPT': {
				slug: 'FLEX/RECEIPT',
				image: '/images/message-icons/receipt.jpeg',
				name: 'Receipt',
				description: 'receipt noun (PROOF) · Keep your receipt as proof of purchase.',
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/TICKET': {
				slug: 'FLEX/TICKET',
				image: '/images/message-icons/ticket.png',
				name: 'Ticket',
				description:
					'Ticketmelon is an easy to use ticketing platform for all types of experiences',
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/APPAREL': {
				slug: 'FLEX/APPAREL',
				image: '/images/message-icons/apparel.png',
				name: 'Apparel',
				description:
					'Discover the Latest Apparel Trends. Unbeatable Discounts Up To 70% Off. Shop Now! Premium Quality Clothing for Every Style. Explore a Wide Range of Options.',
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/LOCAL_SEARCH': {
				slug: 'FLEX/LOCAL_SEARCH',
				image: '/images/message-icons/local-search.png',
				name: 'Local Search',
				description:
					'Your Ultimate Local Search Partner. Find Nearby Businesses and Services. Exclusive Deals and Offers. Your Go-to Source for Local Information.',
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/SOCIAL': {
				slug: 'FLEX/SOCIAL',
				image: '/images/message-icons/social.png',
				name: 'Social',
				description:
					'Connect and Share with the World. Join the Social Network Revolution. Stay Connected, Engage, and Explore. Your Online Hub for Socializing and Networking.',
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/TODO_APP': {
				slug: 'FLEX/TODO_APP',
				image: '/images/message-icons/todo-app.png',
				name: 'TODO App',
				description:
					'Elevate Your Productivity with the TODO App. Stay Organized, Track Tasks, and Accomplish Goals Effectively. Your Ultimate Task Management Companion.',
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/TRANSIT': {
				slug: 'FLEX/TRANSIT',
				image: '/images/message-icons/transit.png',
				name: 'Transit',
				description:
					'Seamless Transit Solutions for Your Journey. Get to Your Destination Effortlessly. Real-Time Updates, Routes, and Schedules. Your Trusted Partner in Smooth Travel.',
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/SHOPPING': {
				slug: 'FLEX/SHOPPING',
				image: '/images/message-icons/shopping.png',
				name: 'Shopping',
				description:
					'Explore the Ultimate Shopping Experience. Discover the Best Deals, Hottest Trends, and Widest Selection. Your One-Stop Destination for Retail Therapy."',
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/MENU': {
				slug: 'FLEX/MENU',
				image: '/images/message-icons/menu.png',
				name: 'Menu',
				description:
					'Indulge in Culinary Delights with Our Menu. Explore a Fusion of Flavors and Tantalize Your Taste Buds. From Classic to Creative, Satisfy Your Cravings Today.',
				platform: {
					line: true,
					web: true
				},
			},
			'FLEX/CUSTOM': {
				slug: 'FLEX/CUSTOM',
				image: '/images/message-icons/custom.png',
				name: 'Custom',
				description:
					'A custom is an activity, a way of behaving, or an event which is usual or traditional in a particular society or in particular circumstances.',
				platform: {
					line: true,
					web: true
				},
			},
		},
	},
	QUESTIONS: {
		name: 'QUESTIONS',
		messages: {
			'SINGLE-INPUT/NAME': {
				slug: 'SINGLE-INPUT/NAME',
				image: '/images/message-icons/boy.svg',
				name: 'Name',
				description: 'Ask for the user name',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'SINGLE-INPUT/TEXT': {
				slug: 'SINGLE-INPUT/TEXT',
				image: '/images/message-icons/document.svg',
				name: 'Text',
				description: 'Ask anything to the user',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'SINGLE-INPUT/EMAIL': {
				slug: 'SINGLE-INPUT/EMAIL',
				image: '/images/message-icons/email.svg',
				name: 'Email',
				description: 'Ask for the user email',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'SINGLE-INPUT/NUMBER': {
				slug: 'SINGLE-INPUT/NUMBER',
				image: '/images/message-icons/number.svg',
				name: 'Number',
				description: 'Ask for a number',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'SINGLE-INPUT/PHONE': {
				slug: 'SINGLE-INPUT/PHONE',
				image: '/images/message-icons/smartphone.svg',
				name: 'Phone',
				description: 'Ask for the user\'s phone number',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'SINGLE-INPUT/URL': {
				slug: 'SINGLE-INPUT/URL',
				image: '/images/message-icons/internet.svg',
				name: 'URL',
				description: 'Ask for an URL',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'QUICK-REPLY/DEFAULT': {
				slug: 'QUICK-REPLY/DEFAULT',
				image: 'https://play-lh.googleusercontent.com/lPGixRRTFWEBLlO3PG29e1ny8Tw64Ri6XYOynIJuGiYdt_Ln43C6qpfHsZbAJwXjHg',
				name: 'Quick reply',
				description: 'Quick reply input',
				platform: {
					line: true,
					botless: true,
				},
			},
			'QUICK-REPLY/COLOR': {
				slug: 'QUICK-REPLY/COLOR',
				image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBW_pZvATgjAZHcC8qcfBv4ah8HZsUDFSixQ&usqp=CAU',
				name: 'Color quick reply',
				description: 'Color quick reply',
				platform: {
					line: true,
					botless: true,
				},
			},
			'QUICK-REPLY/SHIRT-SIZE': {
				slug: 'QUICK-REPLY/SHIRT-SIZE',
				image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxeN6MkcKMrF1lka1-11jYy2QZHCkRGJZq9A&usqp=CAU',
				name: 'Shirt size quick reply',
				description: 'Shirt size quick reply',
				platform: {
					line: true,
					botless: true,
				},
			},
			RATING: {
				slug: 'RATING',
				image: '/images/message-icons/star.svg',
				name: 'Rating',
				description: 'Ask for satisfaction from users',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'AMOUNT-INPUT-LISTS': {
				slug: 'AMOUNT-INPUT-LISTS',
				image: '/images/message-icons/timeline.svg',
				name: 'Number answer set',
				description: 'Ask for information in the form of a numbered series.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			APPROVE: {
				slug: 'APPROVE',
				image: '/images/message-icons/handshake.svg',
				name: 'Term of service',
				description: 'Ask to agree in term of service.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'AUTO-COMPLETE': {
				slug: 'AUTO-COMPLETE',
				image: '/images/message-icons/auto-complete.svg',
				name: 'Auto complete',
				description: 'Ask with suggested answers.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			CALENDAR: {
				slug: 'CALENDAR',
				image: '/images/message-icons/calendar.svg',
				name: 'Calendar picker',
				description: 'Ask for QR code information',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'QR-CODE': {
				slug: 'QR-CODE',
				image: '/images/message-icons/qr-code.svg',
				name: 'QR code scanner',
				description: 'Ask to scan the QR code',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'AUDIO-RECORDING': {
				slug: 'AUDIO-RECORDING',
				image: '/images/message-icons/music.svg',
				name: 'Audio recorder',
				description: 'Request audio recordings from users',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'PHOTO-CAMERA': {
				slug: 'PHOTO-CAMERA',
				image: '/images/message-icons/camera.svg',
				name: 'Camera',
				description: 'Request a photo from the user.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'LINE/CAMERA': {
				slug: 'LINE/CAMERA',
				image: '/images/message-icons/camera.svg',
				name: 'Camera',
				description: 'Request a photo from the user.',
				platform: {
					line: true,
				},
			},
			'LINE/IMAGE': {
				slug: 'LINE/IMAGE',
				image: '/images/message-icons/image.svg',
				name: 'Image',
				description: 'Request photos from user',
				platform: {
					line: true,
				},
			},
			'VIDEO-RECORDING': {
				slug: 'VIDEO-RECORDING',
				image: '/images/message-icons/video-recorder.svg',
				name: 'Video recorder',
				description: 'Request video recording from user',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			CHECKBOX: {
				slug: 'CHECKBOX',
				image: '/images/message-icons/checkbox.svg',
				name: 'Checkbox',
				description: 'Ask a user by being able to answer more than one.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			SIGNATURE: {
				slug: 'SIGNATURE',
				image: '/images/message-icons/contract.svg',
				name: 'Signature',
				description: 'Request a signature from the user.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'ITEM-LISTS': {
				slug: 'ITEM-LISTS',
				image: '/images/message-icons/search-item.svg',
				name: 'Item lists',
				description: 'It\'s great for asking users, and there are quite a few options.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'MULTI-INPUT': {
				slug: 'MULTI-INPUT',
				image: '/images/message-icons/to-do.svg',
				name: 'Multi input',
				description: 'The modal with multi input',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			MAP: {
				slug: 'MAP',
				image: '/images/message-icons/map-location.svg',
				name: 'Map location',
				description: 'Ask for location from users',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'MAP-DIRECTION': {
				slug: 'MAP-DIRECTION',
				image: '/images/message-icons/map.svg',
				name: 'Map direction',
				description: 'Ask for directions from users',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			YESNO: {
				slug: 'YESNO',
				image: '/images/message-icons/vote.svg',
				name: 'Yes/No',
				description: 'Make a yes/no choice',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			SCALE: {
				slug: 'SCALE',
				image: '/images/message-icons/law.svg',
				name: 'Scale',
				description: 'Ask the user to form an answer to a set of numbers.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'PICTURE-CHOICE': {
				slug: 'PICTURE-CHOICE',
				image: '/images/message-icons/photography.svg',
				name: 'Picture choice',
				description: 'Ask the user using an optional image.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			FILE: {
				slug: 'FILE',
				image: '/images/message-icons/folder.svg',
				name: 'File',
				description: 'Asks the user to send a file.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'LINE/SHARE-LOCATION': {
				slug: 'LINE/SHARE-LOCATION',
				image: '/images/message-icons/map-location.svg',
				name: 'Share location',
				description: 'Ask for location from users',
				platform: {
					line: true,
				},
			},
			'LINE/DATETIME-PICKER': {
				slug: 'LINE/DATETIME-PICKER',
				image: '/images/message-icons/calendar.svg',
				name: 'Datetime picker',
				description: 'Ask the date',
				platform: {
					line: true,
				},
			},
		},
	},
	ECOMMERCE: {
		name: 'E-COMMERCE',
		messages: {
			'PRODUCT-CAROUSEL-TEMPLATE': {
				slug: 'PRODUCT-CAROUSEL-TEMPLATE',
				image: '/images/message-icons/ecommerce.png',
				name: 'ECommerce',
				description: 'Show a list of products to the user for trading.',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'CLEAR-CART': {
				slug: 'CLEAR-CART',
				image: '/images/message-icons/clear-cart.png',
				name: 'Clear orders',
				description: 'Delete all items that the user has selected.',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'ORDERS-TEMPLATE': {
				slug: 'ORDERS-TEMPLATE',
				image: '/images/message-icons/bill.png',
				name: 'Show orders',
				description: 'Show a user\'s purchase list',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			OMISE: {
				slug: 'OMISE',
				image: '/images/message-icons/omise.png',
				name: 'Omise',
				description:
					'Payment using the Omise system',
				platform: {
					web: true,
					android: true,
					ios: true,
					line: true,
				},
			},
			'GENERATE-QR-CODE-PAYMENT': {
				slug: 'GENERATE-QR-CODE-PAYMENT',
				image: '/images/message-icons/qr-code-payment.png',
				name: 'Generate qr code payment',
				description:
					'Generate qr code payment',
				platform: {
					web: true,
					android: true,
					ios: true,
					line: true,
				},
			},
			'VERIFY-TRANSACTION': {
				slug: 'VERIFY-TRANSACTION',
				image: '/images/message-icons/verify-transaction.png',
				name: 'Verify transaction',
				description:
					'Verify transaction',
				platform: {
					web: true,
					android: true,
					ios: true,
					line: true,
				},
			},
		},
	},
	RESPONSE: {
		name: 'RESPONSE',
		messages: {
			'MODULE-RESPONSE': {
				slug: 'MODULE-RESPONSE',
				image: '/images/message-icons/sheet.png',
				name: 'Module response',
				description: 'Store the information you need in subunits.',
				platform: {
					line: true,
				},
			},
		},
	},
	FLOW_OPERATIONS: {
		name: 'FLOW OPERATIONS',
		messages: {
			LOGIN: {
				slug: 'LOGIN',
				image: '/images/message-icons/login.svg',
				name: 'Log in',
				description: 'Authentication with Facebook or Google',
				platform: {
				},
			},
			CODE: {
				slug: 'CODE',
				image: '/images/message-icons/javascript.svg',
				name: 'Code',
				description: 'Edit or add the required information via javascript.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'AB-TEST': {
				slug: 'AB-TEST',
				image: '/images/message-icons/ab-test.svg',
				name: 'A/B test',
				description: 'Divide the flow for % of users',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'GOOD-BYE': {
				slug: 'GOOD-BYE',
				image: '/images/message-icons/bye.png',
				name: 'Goodbye',
				description: 'A farewell for your users',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			INTRO: {
				slug: 'INTRO',
				image: '/images/message-icons/splash.svg',
				name: 'Intro app',
				description: 'Your intro application to make it easier for users to understand.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			CONDITION: {
				slug: 'CONDITION',
				image: '/images/message-icons/validation.svg',
				name: 'Condition',
				description: 'Go to different path for different result',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'JUMP-TO': {
				slug: 'JUMP-TO',
				image: '/images/message-icons/climb.svg',
				name: 'Jump to',
				description: 'Jump to the node of other app',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'HUMAN-TAKEOVER': {
				slug: 'HUMAN-TAKEOVER',
				image: '/images/message-icons/job-interview.png',
				name: 'Human takeover',
				description: 'Send notification to your chater',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			'BUSINESS-HOURS': {
				slug: 'BUSINESS-HOURS',
				image: '/images/message-icons/business-hours.png',
				name: 'Business hours',
				description: 'Direct your visitors to a particular flow based on the day and time they access',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
		},
	},
	INTEGRATIONS: {
		name: 'INTEGRATIONS',
		messages: {
			WEBHOOK: {
				slug: 'WEBHOOK',
				image: '/images/message-icons/webhook.svg',
				name: 'Webhook',
				description: 'Call Http to do something',
				platform: {
					line: true,
					web: true,
					android: true,
					ios: true,
				},
			},
			SMTP: {
				slug: 'SMTP',
				image: '/images/message-icons/email.svg',
				name: 'SMTP',
				description: 'Send an email with smtp',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
		},
	},
	OTHERS: {
		name: 'OTHERS',
		messages: {
			'WEB-CARD': {
				slug: 'WEB-CARD',
				image: '/images/message-icons/domain.svg',
				name: 'Web carousel',
				description: 'A list of information that can open the web for reference.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'BOT-PROFILE': {
				slug: 'BOT-PROFILE',
				image: '/images/message-icons/support.svg',
				name: 'Bot profile',
				description: 'The bot introduction.',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
			'CONTACT-INFO': {
				slug: 'CONTACT-INFO',
				image: '/images/message-icons/agenda.svg',
				name: 'Contact information',
				description: 'Contact information',
				platform: {
					web: true,
					android: true,
					ios: true,
				},
			},
		},
	},
}

export default advancedMessages
