import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchPublicData() {
	while (true) {
		yield take(types.FETCH_PUBLIC_DATA)
		try {
			const { data: credentials } = yield call(api.credentials)
			yield put(actions.credentials(credentials))
			eventlog.init(credentials.datadog.client_token)
		} catch (err) {
			console.error('[fetchPublicData] ', err)
			eventlog.error('fetch public data', {
				message: err.message
			})
		}
	}
}

export default fetchPublicData
