import * as actions from '../actions'
import * as apis from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

function* cancelAutoRenewPlan() {
	while (true) {
		const { payload: { platform } } = yield take(types.CANCEL_AUTO_RENEW_PLAN)
		try {
			yield put(actions.isCancelAutoRenewPlan(true))
			yield call(apis.cancelPlan, platform)

			const { data: currentPlan } = yield call(apis.getCurrentPlan)
			yield put(actions.selectedPlan(currentPlan))

			try {
				const { data: currenteSchedule } = yield call(apis.getCurrenteSchedule)
				yield put(actions.currentSchedule(currenteSchedule))
			} catch (e) {

			}

			const toastManager = yield select(selectors.getToast)
			toastManager.success('cancel plan succss')
			yield put(actions.isCancelAutoRenewPlan(false))
		} catch (err) {
			console.error('[cancel auto renew plan] ', err)
			const toastManager = yield select(selectors.getToast)
			toastManager.error('cancel plan failed')
		}
	}
}

export default cancelAutoRenewPlan
