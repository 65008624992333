import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* saveTypingEmulation() {
	while (true) {
		yield take(types.SAVE_TYPING_EMULATION)
		try {
			yield put(actions.updateSaveConfigLoading(true))
			const config = yield select(selectors.getTypingEmulation)
			const response = yield call(api.saveTypingEmulation, config)
			yield put(actions.typingEmulation(response.data))
			const toastManager = yield select(selectors.getToast)
			toastManager.success('Saved successfully')
			yield put(actions.updateSaveConfigLoading(false))
		} catch (err) {
			console.error('[saveTypingEmulation] ', err)
			yield put(actions.updateSaveConfigLoading(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			eventlog.error('save typing emulation', {
				message: err.message
			})
		}
	}
}

export default saveTypingEmulation
