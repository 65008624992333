import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'
import moment from 'moment'

function* fetchDashboard() {
	while (true) {
		const { payload: { start, end, filter } } = yield take(types.FETCH_DASHBOARD)
		try {
			const dateStart = moment(start)
			const dateEnd = moment(end)

			yield put(actions.isFetchingDashboard(true))
			const { data } = yield call(api.fetchDashboard, dateStart.utc().format('MM-DD-YYYY'), dateEnd.utc().format('MM-DD-YYYY'), filter)
			yield put(actions.dashboardData(data))
			yield put(actions.isFetchingDashboard(false))
		} catch (err) {
			yield put(actions.isFetchingDashboard(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			console.error('[fetchAnalytic] ', err)
			eventlog.error('fetch analytic', {
				message: err.message
			})
		}
	}
}

export default fetchDashboard
