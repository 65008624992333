import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* signin() {
	while (true) {
		yield take(types.SIGNIN)
		const { email, password } = yield select(selectors.getLoginFormData)
		try {
			yield put(actions.signinError(null))
			yield put(actions.signinLoading(true))
			// when email and password should not be blank
			if (!email || !password) {
				yield put(actions.signinLoading(false))
				yield put(actions.signinError('The username and password should not be blank'))
				continue
			}

			const response = yield call(api.Login, email, password)

			// if token is exist, should refresh and redirect to authenticated page
			if (response.data.token) {
				localStorage.setItem('token', response.data.token)
				const query = new URLSearchParams(window.location.search)
				const redirect = query.get('redirect')
				if(redirect) {
					window.location.href = redirect
					continue
				}
				window.location.reload()
			}
		} catch (err) {
			const {
				response: { data },
			} = err
			yield put(actions.loginForm(email, ''))
			yield put(actions.signinLoading(false))
			yield put(actions.signinError(data))
			eventlog.error('signin', {
				message: err.message
			})
			continue
		}
	}
}

export default signin
