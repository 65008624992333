import * as types from '../types'

import eventlog from '../../modules/eventlog'
import { fetchMobileAppBuilds } from './onEnterDistribute'
import { take } from 'redux-saga/effects'

function* fetchMobileAppBuildsSaga() {
	while (true) {
		yield take(types.FETCH_MOBILE_APP_BUILDS)
		try {
            yield* fetchMobileAppBuilds()
		} catch (err) {
			console.error('[fetchMobileAppBuilds] ', err)
			eventlog.error('fetchMobileAppBuilds', {
				message: err.message
			})
		}
	}
}

export default fetchMobileAppBuildsSaga
