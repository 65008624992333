import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import _ from 'lodash'
import eventlog from '../../modules/eventlog'

function* enterModuleResponseDetail() {
	while (true) {
		try {
			const {
				payload: { messageId },
			} = yield take(types.ENTER_MODULE_RESPONSE_DETAIL)
			eventlog.info('enter module response detail', {
				messageId
			})
			const { data: messageDetail } = yield call(api.fetchMessage, messageId)
			const { data: moduleResponseDetail } = yield call(
				api.fetchModuleResponseDetail,
				messageId
			)

			yield put(
				actions.moduleResponseDetail({
					columns: _.get(messageDetail, 'message.config.columns', []),
					responses: moduleResponseDetail || [],
				})
			)
		} catch (err) {
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			console.error('[enterModuleResponseDetail] ', err)
			eventlog.error('enter module response detail', {
				message: err.message
			})
		}
	}
}

export default enterModuleResponseDetail
