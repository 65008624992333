import React from 'react'

const ThreeDot = () => (
	<svg data-icon='more' width='16' height='16' viewBox='0 0 16 16'>
		<desc>more</desc>
		<path
			d='M2 6.03a2 2 0 1 0 0 4 2 2 0 1 0 0-4zM14 6.03a2 2 0 1 0 0 4 2 2 0 1 0 0-4zM8 6.03a2 2 0 1 0 0 4 2 2 0 1 0 0-4z'
			fillRule='evenodd'
		></path>
	</svg>
)

export default ThreeDot