import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'
import validateEmail from '../../utils/validateEmail'

function* onSearchCollaborators() {
	while (true) {
		const {
			payload: { query },
		} = yield take(types.ON_SEARCH_COLLABORATORS)
		try {
			// clear collaboratos search
			yield put(actions.collaboratorsSearchResult([]))

			if (!query) {
				yield put(actions.isSearchingCollaborators(false))
				continue
			}

			const { data } = yield call(api.searchCollaborators, query)
			if (!data || data.length === 0) {
				if (validateEmail(query)) {
					yield put(
						actions.collaboratorsSearchResult([
							{
								email: query,
								isNewUser: true,
								firstname: '',
								lastname: '',
							},
						])
					)
				}
				yield put(actions.isSearchingCollaborators(false))
				continue
			}

			yield put(actions.collaboratorsSearchResult(data || []))
			yield put(actions.isSearchingCollaborators(false))
		} catch (err) {
			console.error('[onSearchCollaborators] ', err)
			eventlog.error('search collaborators', {
				message: err.message
			})
		}
	}
}

export default onSearchCollaborators
