const lineButtonTemplate = (editMessage) => {
	let errors = {}

	if (editMessage.config.title.length > 40) {
		errors.title = 'title'
	}

	if (editMessage.config.title || editMessage.config.thumbnailImageUrl) {
		if (!editMessage.config.text || editMessage.config.text.length > 60) {
			errors.text = 'textImage'
		}
	} else {
		if (!editMessage.config.text || editMessage.config.text.length > 160) {
			errors.text = 'text'
		}
	}

	if (editMessage.config.thumbnailImageUrl !== "") {
		if (!['image/x-png', 'image/png', 'image/jpeg'].includes(editMessage.config.extension)) {
			errors.extension = 'extension'
		}

		if (!(editMessage.config.dimension.width <= 1024)) {
			errors.width = 'width'
		}

		if (!(editMessage.config.size > 0 && editMessage.config.size <= 10000000)) {
			errors.size = 'size'
		}
	}

	return errors
}

export default lineButtonTemplate