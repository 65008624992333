export const resetSelectedNode = (set, get, userID) => {
	try {
		const selectedNodesTemp2 = get().selectedNodes;

		const selectedNodesTemp = selectedNodesTemp2[userID];
		if (selectedNodesTemp) {
			delete selectedNodesTemp2[userID];
		}

		set({
			selectedNodes: { ...selectedNodesTemp2 },
		});
	} catch (err) {
		console.error("[resetSelectedNode] ", err);
	}
};
