import { getToolDiagram } from "../global/diagram";

// import {
// 	EDGE_ADD_ACTION_COLLABORATIVE,
// 	EDGE_REMOVE_ACTION_COLLABORATIVE,
// 	NODE_ADD_ACTION_COLLABORATIVE,
// 	NODE_DATA_ACTION_COLLABORATIVE,
// 	NODE_MOVE_POSITION_ACTION_COLLABORATIVE,
// 	NODE_REMOVE_ACTION_COLLABORATIVE,
// } from "../../feature/collaborative/config";
export const redo = (set, get) => {
	try {
		const getNodes = getToolDiagram("getNodes");
		const getEdges = getToolDiagram("getEdges");
		const setNodes = getToolDiagram("setNodes");
		const setEdges = getToolDiagram("setEdges");
		const past = get().past;
		const future = get().future;

		const futureState = future[future.length - 1];

		if (futureState) {
			set({
				future: future.slice(0, future.length - 1),
			});
			set({
				past: [
					...past,
					{ type: futureState.type, data: futureState.data, id: futureState.data.id, nodes: getNodes(), edges: getEdges() }
				],
			});
			setNodes(futureState.nodes);
			setEdges(futureState.edges);
		}
	} catch (err) {
		console.error("[redo] ", err);
	}
};
