import * as types from '../types'

const collaborator = (
	state = {
		collaborators: [],
		collaboratorsSearchResult: [],
		isShowingInvitationModal: false,
		roles: {},
		invitationURL: ""
	},
	action
) => {
	switch (action.type) {
		case types.COLLABORATORS:
			return {
				...state,
				collaborators: action.payload.collaborators,
			}
		case types.COLLABORATORS_SEARCH_RESULT:
			return {
				...state,
				collaboratorsSearchResult: action.payload.collaborators,
			}
		case types.IS_SEACHING_COLLABORATORS:
			return {
				...state,
				isSearchingCollaborators: action.payload.status,
			}
		case types.IS_FETCHING_COLLABORATORS:
			return {
				...state,
				isFetchingCollaborators: action.payload.status,
			}
		case types.IS_INVITING_COLLABORATOR:
			return {
				...state,
				isInvitingCollaborator: action.payload.status,
			}
		case types.IS_SHOWING_INVITATION_MODAL:
			return {
				...state,
				isShowingInvitationModal: action.payload.status,
			}
		case types.ROLES:
			return {
				...state,
				roles: action.payload.roles,
			}
		case types.IS_FETCHING_ROLES:
			return {
				...state,
				isFetchingRoles: action.payload.status,
			}
		case types.STORE_INVITATION_URL:
			return {
				...state,
				invitationURL: action.payload.url,
			}
		default:
			return state
	}
}

export default collaborator
