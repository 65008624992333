import * as types from '../types'

const lineInsight = (
	state = {
		insightFriend: {
			all: 0,
			friends: [],
			limit: 0,
			offset: 0,
		},
		insightMessage: {
			all: 0,
			messages: [],
			limit: 0,
			offset: 0,
		},
		isFetchingInsightFriend: false,
		isFetchingInsightMessage: false,
	},
	action
) => {
	switch (action.type) {
		case types.LINE_INSIGHT_FRIEND:
			return {
				...state,
				insightFriend: action.payload.insightFriend,
			}
		case types.LINE_INSIGHT_MESSAGE:
			return {
				...state,
				insightMessage: action.payload.insightMessage,
			}
		case types.IS_FETCHING_LINE_INSIGHT_FRIEND:
			return {
				...state,
				isFetchingInsightFriend: action.payload.status,
			}
		case types.IS_FETCHING_LINE_INSIGHT_MESSAGE:
			return {
				...state,
				isFetchingInsightMessage: action.payload.status,
			}
		default:
			return state
	}
}

export default lineInsight
