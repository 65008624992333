import * as actions from '../actions'
import * as apis from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

function* payingInvoiceStatusUnpaid() {
	while (true) {
		try {
			const { payload: { paymentMethod, invoices } } = yield take(types.PAYMENT_UNPAID_INVOICE)
			yield put(actions.isPayingUnpaidInvoice(true))

			const currentCards = yield select(selectors.getCurrentCards)
			if (currentCards.data === "") {
				yield put(actions.isPayingUnpaidInvoice(false))
				const toastManager = yield select(selectors.getToast)
				toastManager.error('Please add payment add')
				continue
			}

			yield call(apis.payingInvoiceStatusUnpaid, paymentMethod, invoices)

			const { data: invoicesSubscription } = yield call(apis.getInvoicesSubscription, 'paid')
			yield put(actions.storeInvoicesStatusPaidSubscription(invoicesSubscription || []))

			const { data: unpaidInvoicesSubscription } = yield call(apis.getInvoicesSubscription, 'unpaid')
			yield put(actions.storeInvoicesStatusUnpaidSubscription(unpaidInvoicesSubscription || []))

			const toastManager = yield select(selectors.getToast)
			toastManager.success('Payment invoice success')

			yield put(actions.isShowingUnpaidInvoiceSummary(false))
			yield put(actions.isPayingUnpaidInvoice(false))
		} catch (err) {
			yield put(actions.isPayingUnpaidInvoice(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
		}
	}
}

export default payingInvoiceStatusUnpaid
