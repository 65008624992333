import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* deleteApp() {
	while (true) {
		const {
			payload: { appId },
		} = yield take(types.DELETE_APP)
		try {
			yield put(actions.deletingApp(true))
			yield call(api.deleteApp, appId)
			const response = yield call(api.fetchApps)
			yield put(actions.apps(response.data))
			eventlog.info('delete an app')
			yield put(actions.deletingApp(false))
		} catch (err) {
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			yield put(actions.deletingApp(false))
			console.error('[deleteApp] ', err)
			eventlog.error('delete app', {
				message: err.message
			})
		}
	}
}

export default deleteApp
