import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchResponse() {
	while (true) {
		const {
			payload: { responseId },
		} = yield take(types.FETCH_RESPONSE)
		try {
			const selectedApp = yield select(selectors.getSelectedApp)
			const { data: response } = yield call(
				api.fetchResponse,
				selectedApp.platform,
				responseId
			)
			yield put(actions.response(response))
			yield put(actions.isFetchingResponse(false))
		} catch (err) {
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			console.error('[fetchResponse] ', err)
			eventlog.error('fetch response', {
				message: err.message
			})
		}
	}
}

export default fetchResponse
