import { handlePortId } from "../../config";

import {
	getMessageConfigDiagram,
	getToolDiagram,
	setMessageConfigDiagram,
} from "../global/diagram";

export const resetHandles = (set, get, id, handleIds) => {
	try {
		const messages = getMessageConfigDiagram();

		messages[id].handles.forEach((handle) => {
			handleIds.forEach((handleId) => {
				if (
					handle !== handlePortId.entry &&
					handle !== handlePortId.left &&
					handle !== handleId
				) {
					delete messages[id].handles[handle];
				}
			});
		});

		setMessageConfigDiagram({
			...messages,
		});

		const isNotPortHandleReserve = (portHandleId) => {
			let resultSum = 0;

			if (portHandleId === handlePortId.entry) {
				resultSum++;
			}

			handleIds.forEach((handleId) => {
				if (portHandleId === handleId) {
					resultSum++;
				}
			});

			if (resultSum > 0) {
				return false;
			} else {
				return true;
			}
		};

		getToolDiagram("setEdges")((eds) =>
			eds.filter(
				(edge) =>
					!(edge.source === id && isNotPortHandleReserve(edge.sourceHandle))
			)
		);

		getToolDiagram("updateNodeInternals")(id);
	} catch (err) {
		console.error("[resetHandles] ", err);
	}
};
