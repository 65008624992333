import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchResponsesGroupByDevice() {
	while (true) {
		yield take(types.FETCH_RESPONSES_GROUP_BY_DEVICE)
		try {
			yield put(actions.isResponsesLoading(true))
			const { data } = yield call(api.fetchResponsesGroupByDevice)
			yield put(actions.responsesGroupByDevice(data))
			yield put(actions.isResponsesLoading(false))
		} catch (err) {
			console.error('[fetchResponsesGroupByDevice] ', err)
			yield put(actions.isResponsesLoading(false))
			eventlog.error('fetch response group by device', {
				message: err.message
			})
		}
	}
}

export default fetchResponsesGroupByDevice
