import * as actions from "../actions";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { put, select, take } from "redux-saga/effects";
import { useStoreDiagram } from "../../components/diagram/store";

function* resetSaveDiagramHistory() {
	while (true) {
		yield take(types.RESET_SAVE_DIAGRAM_HISTORY);
		try {
			let messageConfigBackupDiagramHistory = yield select(
				selectors.getMessageConfigBackupDiagramHistory
			);

			if (messageConfigBackupDiagramHistory) {
				yield put(actions.messageConfigBackupDiagramHistory(null));

				useStoreDiagram.getState().removeAllNodeEdge();

				setTimeout(() => {
					useStoreDiagram
						.getState()
						.addNewNodeInterface(
							Object.values(messageConfigBackupDiagramHistory),
							true
						);
				}, 100);
			} else {
				continue;
			}
		} catch (err) {
			console.error("[resetSaveDiagramHistory] ", err);
			eventlog.error("reset Save Diagram History", {
				message: err.message,
			});
		}
	}
}

export default resetSaveDiagramHistory;
