import "react-bootstrap-typeahead/css/Typeahead.css";
import "./App.scss";
import * as actions from "./redux/actions";
import React, { Component, Suspense } from "react";
import { ToastBar, Toaster, toast } from "react-hot-toast";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { compose } from "redux";
import { store } from "./redux";

const ForgotPassword = React.lazy(() => import('./screen/forgotPassword'));
const Invitation = React.lazy(() => import('./screen/invitation'));
const Login = React.lazy(() => import('./screen/login'));
const Main = React.lazy(() => import('./layout/main'));
const Register = React.lazy(() => import('./screen/register'));
const ResetPassword = React.lazy(() => import('./screen/resetPassword'));
const Loading = React.lazy(() => import('./loading'));

const NotFound = ({ t }) => (
	<div className='error'>
		<div className='error__content'>
			<h2>{t('content.notfound.code')}</h2>
			<h4>{t('content.notfound.title')}</h4>
			<p>{t('content.notfound.description')}</p>
			<button type='button' className='btn btn-accent btn-pill' onClick={() => window.location.href = "/"}>
				&larr; Go Back
			</button>
		</div>
	</div>
)

const AccessForbidden = ({ t }) => (
	<div className='error'>
		<div className='error__content'>
			<h2>{t('content.forbidden.code')}</h2>
			<h4>{t('content.forbidden.title')}</h4>
			<p>{t('content.forbidden.description')}</p>
			<button type='button' className='btn btn-accent btn-pill' onClick={() => window.location.href = "/"}>
				&larr; Go Back
			</button>
		</div>
	</div>
)

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			token: localStorage.getItem('token'),
			appId: localStorage.getItem('appId'),
		}
	}

	render() {
		const { t } = this.props

		return (
			<>
				<Toaster
					position="top-right"
					reverseOrder={false}
					containerStyle={{
						top: 70,
					}}

				>
					{(t) => (
						<ToastBar toast={t}>
							{({ icon, message }) => (
								<>
									{icon}
									{message}
									{t.type !== 'loading' && (
										<button type="button" className="btn btn-secondary" onClick={() => toast.dismiss(t.id)}>Close</button>
									)}
								</>
							)}
						</ToastBar>
					)}
				</Toaster>
				<Routes>
					<Route
						path='/join'
						element={
							this.state.token ? (
								<Navigate to="/" />
							) : (
								<Suspense fallback={<Loading />}>
									<Register />
								</Suspense>
							)
						}
					/>
					<Route
						path='/login'
						element={
							this.state.token ? (
								<Navigate to="/" />
							) : (
								<Suspense fallback={<Loading />}>
									<Login />
								</Suspense>
							)
						}
					/>
					<Route
						path='/forgot-password'
						element={
							this.state.token ? (
								<Navigate to="/" />
							) : (
								<Suspense fallback={<Loading />}>
									<ForgotPassword />
								</Suspense>
							)
						}
					/>
					<Route
						path='/:appId/invitation'
						element={
							<Suspense fallback={<Loading />}>
								<Invitation onParams={this.props.params} />
							</Suspense>
						}
					/>
					<Route
						path='/reset-password'
						element={
							<Suspense fallback={<Loading />}>
								<ResetPassword />
							</Suspense>
						}
					/>
					<Route
						path='/*'
						element={
							!this.state.token ? (
								// If there is no token, dispatch an action and return an empty div
								<>
									{store.dispatch(actions.unauthorization())}
									<div></div>
								</>
							) : (
								// If there is a token, render Main component inside Suspense
								<Suspense fallback={<Loading />}>
									<Main />
								</Suspense>
							)
						}
					/>
					<Route
						path='/404'
						element={<NotFound t={t} />}
					/>
					<Route
						path='/403'
						element={<AccessForbidden t={t} />}
					/>
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	accessControls: state.auth.accessControls,
})

const mapDispatchToProps = (dispatch) => ({
	logout: () => {
		dispatch(actions.logout())
	},
	params: (params) => {
		dispatch(actions.params(params))
	},
})

const Container = compose(
	withTranslation(['layout']),
	connect(mapStateToProps, mapDispatchToProps))(App)

export default Container