import * as actions from "../actions";
import * as api from "../apis";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, put, select, take } from "redux-saga/effects";

function* enterBotManagement() {
	while (true) {
		try {
			yield take(types.ENTER_BOT_MANAGEMENT);
			yield put(actions.isBotManagementLoading(true));

			const { data: app } = yield call(api.fetchSelectedApp);

			eventlog.addContext("appId", app._id);
			eventlog.info("enter bot management", {
				...app,
			});

			yield put(actions.selectedApp(app));
			yield put(actions.draftAppConfig(app));
			yield put(actions.featureFlags(app.feature_flags));

			// fetch removed messages
			const {
				data: { messages },
			} = yield call(api.removedMessages);
			yield put(actions.removedMessages(messages || []));

			// fetch design config
			const { data } = yield call(api.designConfig);
			yield put(actions.design(data));

			// fetch variables
			yield put(actions.fetchVariables());

			// fetch google linking detail
			yield put(actions.fetchGoogleLinkingDetail());

			// fetch current app plan
			const { data: currentAppPlan } = yield call(api.getCurrentAppPlan);
			yield put(actions.storeCurrentAppPlan(currentAppPlan));

			yield put(actions.isBotManagementLoading(false));
		} catch (err) {
			if (err.response.status === 404 || err.response.status === 502) {
				window.location.replace("/404");
				return;
			}

			const toastManager = yield select(selectors.getToast);
			toastManager.error("Got the error from server");
			console.error("[enterBotManament] ", err);
			eventlog.error("enter bot management", {
				message: err.message,
			});
		}
	}
}

export default enterBotManagement;
