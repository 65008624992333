import "./listen.css";
import * as React from "react";
import ContributionPage from "../contribution";
import { PlayButton } from "../../../primary-buttons/primary-buttons";

const initialState = {
    hasPlayed: false,
    isPlaying: false,
};
class ListenPage extends React.Component {
    audioRef = React.createRef();

    state = initialState;

    componentDidMount = () => {
        const audio = this.audioRef.current;
        audio.pause();
        audio.load();
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.audio !== prevProps.message.audio) {
            const audio = this.audioRef.current;
            audio.pause();
            audio.load();
        }
    }

    play = () => {
        if (this.state.isPlaying) {
            this.stop();
            return;
        }

        this.audioRef.current.play();
        this.setState({ isPlaying: true });
    };

    stop = () => {
        if (this.state.isPlaying) {
            const audio = this.audioRef.current;
            audio.pause();
            audio.currentTime = 0;
            this.setState({ isPlaying: false });
        }
    };

    hasPlayed = () => {
        this.setState({ hasPlayed: true, isPlaying: false });
    };

    render() {
        const { isPlaying } = this.state;
        const {
            audio,
        } = this.props;

        return (
            <>
                <div id="listen-page">
                    <audio
                        controls
                        src={audio}
                        preload="auto"
                        onEnded={this.hasPlayed}
                        ref={this.audioRef}
                        data-nodeid="NONE"
                    />
                    <ContributionPage
                        isPlaying={isPlaying}
                        primaryButtons={
                            <>
                                <PlayButton isPlaying={isPlaying} onClick={this.play} />
                            </>
                        }
                        type="listen"
                    />
                </div>
            </>
        );
    }
}

export default ListenPage;
