import * as actions from "../actions";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { put, select, take } from "redux-saga/effects";

import {
	getMessageConfigDiagram,
	setMessageConfigDiagram,
} from "../../components/diagram/store/global/diagram";

function* saveUploadMedia() {
	while (true) {
		yield take(types.SAVE_UPLOAD_MEDIA);
		try {
			const draftUploadMedia = yield select(selectors.getDraftUploadMedia);
			const messages = getMessageConfigDiagram();

			eventlog.info("save an upload media", {
				url: draftUploadMedia.url,
			});

			const newMessage = {
				...messages[draftUploadMedia.nodeId],
			};

			const { fileType } = draftUploadMedia;
			switch (fileType) {
				case "IMAGE":
					newMessage.messages[draftUploadMedia.messageIndex].image =
						draftUploadMedia.url;
					break;
				case "AUDIO":
					newMessage.messages[draftUploadMedia.messageIndex].audio =
						draftUploadMedia.url;
					newMessage.messages[draftUploadMedia.messageIndex].duration =
						draftUploadMedia.duration;
					break;
				case "VIDEO":
					newMessage.messages[draftUploadMedia.messageIndex].video =
						draftUploadMedia.url;
					newMessage.messages[draftUploadMedia.messageIndex].preview =
						draftUploadMedia.preview;
					break;
				default:
					break;
			}

			setMessageConfigDiagram({
				...messages,
				[draftUploadMedia.nodeId]: {
					...newMessage,
				},
			});

			yield put(actions.draftUploadMedia(null));
		} catch (err) {
			console.error("[saveUploadMedia] ", err);
			eventlog.error("save upload media", {
				message: err.message,
			});
		}
	}
}

export default saveUploadMedia;
