import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

export function* renewToken() {
	try {
		const {data: { token }} = yield call(api.renewToken)
		eventlog.info('renew token', {
			token
		})
		const payload = JSON.parse(atob(token.split('.')[1]))
		yield put(actions.accessControls(payload.acl))
		yield put(actions.authData(payload.data || {}))
		localStorage.setItem('token', token)
	} catch (err) {
		console.error('[renewToken] ', err)
	}
}

function* renewTokenSaga() {
	while (true) {
		yield take(types.RENEW_TOKEN)
		try {
			yield* renewToken()
		} catch (err) {
			console.error('[renewTokenSaga] ', err)
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			eventlog.error('renew token', {
				message: err.message
			})
		}
	}
}

export default renewTokenSaga
