import * as actions from '../actions'
import * as selectors from '../selectors'
import * as types from '../types'

import { put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* retryMessage() {
	while (true) {
		const {
			payload: { message },
		} = yield take(types.RETRY_MESSAGE)
		try {
			eventlog.info('retry sending the message', message)
			const failedMessages = yield select(selectors.getFailedMessages)
			const messages = yield select(selectors.getMessengerMessages)
			const selectedVisitor = yield select(selectors.getSelectedVisitor)
			const newMessages = messages[selectedVisitor._id].filter((m) => m.id !== message.id)
			delete failedMessages[message.id]
			yield put(actions.failedMessages({ ...failedMessages }))
			yield put(
				actions.visitorMessages({
					...messages,
					[selectedVisitor._id]: newMessages,
				})
			)

			// update create timestamp
			message.createdAt = new Date()
			yield put(actions.onSendMessage(selectedVisitor, message))
		} catch (err) {
			console.error('[retryMessage] ', err)
			eventlog.error('retry messsage', {
				message: err.message
			})
		}
	}
}

export default retryMessage
