import { getToolDiagram } from "../global/diagram";

export const removeAllNodeEdge = (set, get) => {
	try {
		getToolDiagram("setEdges")([]);
		getToolDiagram("setNodes")([]);
	} catch (err) {
		console.error("[removeAllNodeEdge] ", err);
	}
};
