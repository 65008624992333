const permissions = {
	types: [
		'app_access',
		'build_and_design',
		'chat',
		'configuraton_and_setting',
		'token_and_authentication',
		'user_mamagement_and_invitation',
		'analytics',
	],
	lineTypes: [
		'app_access',
		'build_and_design',
		'chat',
		'configuraton_and_setting',
		'token_and_authentication',
		'user_mamagement_and_invitation',
		'customer_management',
		'analytics',
		'insights',
	],
	roles: [
		{
			'name': 'owner',
			'permission': [
				'app_access',
				'build_and_design',
				'chat',
				'configuraton_and_setting',
				'user_mamagement_and_invitation',
				'token_and_authentication',
				'analytics',
				'insights',
				'customer_management'
			]
		},
		{
			'name': 'builder',
			'permission': [
				'build_and_design',
			]
		},
		{
			'name': 'chater',
			'permission': [
				'chat',
				'insights',
				'customer_management'
			]
		}
	]
}

export default permissions
