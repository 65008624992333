import * as actions from "../../../../redux/actions";
import { useStoreDiagram } from "..";
import { store } from "../../../../redux";
import { INTERFACE_MESSAGE } from "../../../../utils/config";
import { getMessageConfigDiagram, getToolDiagram } from "../global/diagram";

import {
    DIAGRAM_TYPE_FORCE_LAYOUT,
    handlePortId,
    nodeId,
    nodeProps,
} from "../../config";

export const createNodeByMessageSelection = (
    set,
    get,
    mode,
    position,
    forceLayout
) => {
    try {
        const newMessage = {
            ...INTERFACE_MESSAGE(mode),
            ...nodeProps,
            position: position,
        };

        useStoreDiagram.getState().addNewNodeInterface([newMessage]);

        if (forceLayout) {
            const nodesForceLayout = getToolDiagram("getNodes")().filter(
                (node) => node.type === DIAGRAM_TYPE_FORCE_LAYOUT
            );
            useStoreDiagram.getState().removeNodesDraft(nodesForceLayout);
        } else {
            useStoreDiagram.getState().removeNodeDraft(nodeId.DRAFT);
        }

        store.dispatch(actions.onSelectNode(newMessage));

        const draftSource = useStoreDiagram.getState().draftSource;

        const sourceDraftSource = {
            nodeId: draftSource.nodeId,
            handleId: draftSource.handleId,
        };

        const targetDraftSource = {
            nodeId: newMessage.id,
            handleId: handlePortId.entry,
        };

        if (sourceDraftSource.nodeId && sourceDraftSource.handleId) {
            // remove the old links
            useStoreDiagram.getState().removeEdgeByNodeIdAndHandleId(sourceDraftSource.nodeId, sourceDraftSource.handleId);
        }

        const draftTarget = useStoreDiagram.getState().draftTarget;

        setTimeout(() => {
            if (draftTarget.nodeId) {
                const hasEnd = newMessage.id.indexOf(nodeId.END) !== -1;

                const messages = getMessageConfigDiagram();
                const size = Object.keys(messages[newMessage.id].handles).length;

                if (size > 0 && !hasEnd) {
                    const sourceDraftTarget = {
                        nodeId: newMessage.id,
                        handleId: Object.keys(messages[newMessage.id].handles)[size - 1],
                    };

                    const targetDraftTarget = {
                        nodeId: draftTarget.nodeId,
                        handleId: draftTarget.handleId,
                    };

                    if (targetDraftTarget.nodeId && sourceDraftSource.nodeId) {
                        setTimeout(() => {
                            useStoreDiagram.getState().addEdge([
                                { source: sourceDraftSource, target: targetDraftSource },
                                { source: sourceDraftTarget, target: targetDraftTarget },
                            ]);
                        }, 1);
                    }
                }
            } else {
                if (sourceDraftSource.nodeId) {
                    setTimeout(() => {
                        const addEdges = [
                            { source: sourceDraftSource, target: targetDraftSource },
                        ];

                        const messages = getMessageConfigDiagram();

                        Object.entries(window.selectedNodes.current).forEach(([nodeIdNow]) => {
                            if (nodeIdNow !== sourceDraftSource.nodeId) {
                                const size = Object.keys(messages[nodeIdNow].handles).length;
                                const sourceDraftSourceTemp = {
                                    nodeId: nodeIdNow,
                                    handleId: Object.keys(messages[nodeIdNow].handles)[size - 1],
                                };

                                addEdges.push({
                                    source: sourceDraftSourceTemp,
                                    target: targetDraftSource,
                                });
                            }
                        });

                        useStoreDiagram.getState().addEdge(addEdges);
                    }, 1);
                }
            }
        }, 1);

        useStoreDiagram.getState().endDragEdge();
    } catch (err) {
        console.error("[createNodeByMessageSelection] ", err);
    }
};
