const ALPHABET = '0123456789';

const ID_LENGTH = 5

const generate = function() {
    var rtn = ''
    for (var i = 0; i < ID_LENGTH; i++) {
        rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
    }
    return rtn
}

export default generate