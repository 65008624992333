import { getToolDiagram } from "../global/diagram";

export const removeAllNodeEdgeSelect = (set, get) => {
	try {
		const nodes = getToolDiagram("getNodes")();
		if (nodes.length > 0) {
			getToolDiagram("deleteElements")({ nodes: nodes });
		}
		const edges = getToolDiagram("getEdges")();
		if (edges.length > 0) {
			getToolDiagram("deleteElements")({ edges: edges });
		}
	} catch (err) {
		console.error("[removeAllNodeEdgeSelect] ", err);
	}
};
