import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* saveAppConfig() {
	while (true) {
		try {
			yield take(types.SAVE_APP_CONFIG)
			eventlog.info('save the app config')
			yield put(actions.updateSaveConfigLoading(true))
			const draftAppConfig = yield select(selectors.getDraftAppConfig)
			const { data: app } = yield call(api.updateApp, draftAppConfig)
			yield put(actions.draftAppConfig(app))
			yield put(actions.selectedApp(app))

			const toastManager = yield select(selectors.getToast)
			toastManager.success('Saved successfully')

			yield put(actions.updateSaveConfigLoading(false))
		} catch (err) {
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			yield put(actions.updateSaveConfigLoading(false))
			console.error('[saveAppConfig] ', err)
			eventlog.error('save app config', {
				message: err.message
			})
		}
	}
}

export default saveAppConfig
