import * as actions from "../../actions";
import * as selectors from "../../selectors";
import * as types from "../../types";
import _ from "lodash";
import cloneDeep from "../../../utils/cloneDeep";
import validation from "./validation";
import { call, put, select, take } from "redux-saga/effects";
import { NODE_DATA_ACTION_COLLABORATIVE } from "../../../components/diagram/feature/collaborative/config";
import { useStoreDiagram } from "../../../components/diagram/store";

import {
	DIAGRAM_TYPE_FORCE_LAYOUT,
	nodeProps,
} from "../../../components/diagram/config";

import {
	getMessageConfigDiagram,
	getToolDiagram,
	setMessageConfigDiagram,
} from "../../../components/diagram/store/global/diagram";

function* onSubmitEditMessage() {
	while (true) {
		yield take(types.ON_SUBMIT_EDIT_MESSAGE);
		try {
			let editMessage = yield select(selectors.getEditMessage);

			const errors = yield call(validation, editMessage);
			if (Object.keys(errors).length > 0) {
				yield put(actions.editMessageErrors(errors));
				continue;
			}
			// clear existing errors. NOTE that errors will be {} when no errors
			yield put(actions.editMessageErrors(errors));

			let messages = getMessageConfigDiagram();

			const { x, y } = window.mousePositionCenterDiagram.current;

			let isHaveNode = false;
			if (!messages[editMessage.id]) {
				messages[editMessage.id] = {
					...editMessage,
					...nodeProps,
					position: {
						x: x || 0,
						y: y || 0,
					},
				};
				isHaveNode = false;
			} else {
				isHaveNode = true;
			}

			let oldMessage,
				newMessage,
				addedPoints,
				removedPoints,
				newButtons,
				oldButtons,
				addedButtons,
				removedButtons,
				newActions,
				oldActions;

			switch (editMessage.mode) {
				case "UNION-MESSAGE/BUTTON":
					/** If no options */
					if (editMessage.input.options.length === 0) {
					}

					const unionMessage = cloneDeep(messages[editMessage.id]);

					newButtons = editMessage.input.options.map((option) => option.id);
					oldButtons = unionMessage.messages[
						unionMessage.messages.length - 1
					].input.options.map((option) => option.id);

					addedButtons = newButtons.filter(
						(id, index) => oldButtons.indexOf(id) < 0
					);
					removedButtons = oldButtons.filter(
						(id, index) => newButtons.indexOf(id) < 0
					);

					unionMessage.messages[unionMessage.messages.length - 1] = cloneDeep({
						mode: "BUTTON",
						input: editMessage.input,
						answer: editMessage.answer,
						question: editMessage.question,
					});

					messages[editMessage.id] = {
						...messages[editMessage.id],
						...unionMessage,
					};

					useStoreDiagram.getState().addHandles(editMessage.id, addedButtons);
					useStoreDiagram
						.getState()
						.removeHandles(editMessage.id, removedButtons);
					break;
				case "LINE/RICH-MENU":
					oldMessage = cloneDeep(messages[editMessage.id]);
					newMessage = editMessage;

					const newCoords = newMessage.input.coordinates.map(
						(coord) => coord.id
					);
					const oldCoords = oldMessage.input.coordinates.map(
						(coord) => coord.id
					);

					addedPoints = newCoords.filter(
						(id, index) => oldCoords.indexOf(id) < 0
					);
					removedPoints = oldCoords.filter(
						(id, index) => newCoords.indexOf(id) < 0
					);

					messages[editMessage.id] = {
						...messages[editMessage.id],
						...editMessage,
					};

					useStoreDiagram.getState().addHandles(editMessage.id, addedPoints);
					useStoreDiagram
						.getState()
						.removeHandles(editMessage.id, removedPoints);
					break;
				case "LINE/BUTTON-TEMPLATE":
					oldMessage = cloneDeep(messages[editMessage.id]);
					newMessage = editMessage;

					newActions = newMessage.config.actions.map(
						(action, index) => `action_${index}`
					);
					oldActions = oldMessage.config.actions.map(
						(action, index) => `action_${index}`
					);

					addedPoints = newActions.filter((id) => oldActions.indexOf(id) < 0);
					removedPoints = oldActions.filter((id) => newActions.indexOf(id) < 0);

					messages[editMessage.id] = {
						...messages[editMessage.id],
						...editMessage,
					};

					useStoreDiagram.getState().addHandles(editMessage.id, addedPoints);
					useStoreDiagram
						.getState()
						.removeHandles(editMessage.id, removedPoints);
					break;
				case "SCALE":
					const message = cloneDeep(messages[editMessage.id]);
					const newFrom = editMessage.input.from || 0;
					const newTo = editMessage.input.to || 0;
					const oldFrom = message.input.from || 0;
					const oldTo = message.input.to || 0;

					newButtons = _.range(
						newFrom,
						newFrom <= newTo ? newTo + 1 : newTo - 1,
						newFrom <= newTo ? 1 : -1
					);
					oldButtons = _.range(
						oldFrom,
						oldFrom <= oldTo ? oldTo + 1 : oldTo - 1,
						oldFrom <= oldTo ? 1 : -1
					);

					addedButtons = newButtons.filter(
						(id, index) => oldButtons.indexOf(id) < 0
					);
					removedButtons = oldButtons.filter(
						(id, index) => newButtons.indexOf(id) < 0
					);

					messages[editMessage.id] = {
						...messages[editMessage.id],
						...editMessage,
						input: {
							...editMessage.input,
							from: newFrom,
							to: newTo,
						},
					};

					useStoreDiagram.getState().addHandles(
						editMessage.id,
						addedButtons.map((n) => `scale_${n}`)
					);
					useStoreDiagram.getState().removeHandles(
						editMessage.id,
						removedButtons.map((n) => `scale_${n}`)
					);
					break;
				case "INTRO":
					/* 				const node = getNode(this.props.diagramEngine.getModel(), "DRAFT");
																					  console.log("engine", engine);
																					  console.log("diagramModel", diagramModel);
																					  console.log(".nodes['DRAFT']", diagramModel.getNodes()); */
					break;
				default:
					if (editMessage.mode.split("/")[0] === "FLEX") {
						oldMessage = cloneDeep(messages[editMessage.id]);
						newMessage = editMessage;

						newActions = Object.keys(newMessage.actions || {});
						oldActions = Object.keys(oldMessage.actions || {});

						addedPoints = newActions.filter((id) => oldActions.indexOf(id) < 0);
						removedPoints = oldActions.filter(
							(id) => newActions.indexOf(id) < 0
						);

						messages[editMessage.id] = {
							...messages[editMessage.id],
							...editMessage,
						};

						useStoreDiagram.getState().addHandles(editMessage.id, addedPoints);
						useStoreDiagram
							.getState()
							.removeHandles(editMessage.id, removedPoints);
					} else {
						messages[editMessage.id] = {
							...messages[editMessage.id],
							...editMessage,
						};
					}
					break;
			}

			yield put(actions.editMessage(null));

			const nodesForceLayout = getToolDiagram("getNodes")().filter(
				(node) => node.type === DIAGRAM_TYPE_FORCE_LAYOUT
			);
			if (nodesForceLayout.length > 0) {
				useStoreDiagram.getState().removeNodesDraft(nodesForceLayout);
			}

			setTimeout(() => {
				window.nodeEventHandle(NODE_DATA_ACTION_COLLABORATIVE, {
					id: editMessage.id,
				});
			}, 1);

			if (!isHaveNode) {
				useStoreDiagram
					.getState()
					.addNewNodeInterface([messages[editMessage.id]]);
			} else {
				setMessageConfigDiagram({
					...messages,
				});
			}
		} catch (err) {
			console.error("[onSubmitEditMessage] ", err);
		}
	}
}

export default onSubmitEditMessage;
