import * as api from '../apis'
import * as types from '../types'

import { call, take } from 'redux-saga/effects'
import eventlog from '../../modules/eventlog'

function* getInvoicePDF() {
	while (true) {
		const {
			payload: { token },
		} = yield take(types.GET_INVOICE_PDF)
		try {
			const response = yield call(api.getInvoicePDF, token)

			const file = new Blob(
				[response.data],
				{ type: 'application/pdf' });

			const fileURL = URL.createObjectURL(file);

			window.open(fileURL);
		} catch (err) {
			console.error('[getInvoicePDF] ', err)
			eventlog.error('get invoice PDF', {
				message: err.message
			})
		}
	}
}

export default getInvoicePDF
