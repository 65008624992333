import * as types from "../types";

const saveDiagramHistory = (
	state = {
		isShow: false,
		saveDiagramHistory: [],
		isFetchingSaveDiagramHistory: false,
		messageConfigBackupDiagramHistory: null,
	},
	action
) => {
	switch (action.type) {
		case types.TOGGLE_SAVE_DIAGRAM_HISTORY:
			return {
				...state,
				isShow: !state.isShow,
			};
		case types.SAVE_DIAGRAM_HISTORIES:
			return {
				...state,
				saveDiagramHistory: [...action.payload.saveDiagramHistory],
			};
		case types.IS_FETCHING_SAVE_DIAGRAM_HISTORY:
			return {
				...state,
				isFetchingSaveDiagramHistory: action.payload.status,
			};
		case types.MESSAGE_CONFIG_BACKUP_DIAGRAM_HISTORY:
			return {
				...state,
				messageConfigBackupDiagramHistory: action.payload.messages,
			};
		default:
			return state;
	}
};

export default saveDiagramHistory;
