import _ from "lodash";
import generateID from "../../../../utils/generateID";
import { useStoreDiagram } from "..";
import { advancedMessages } from "../../../../utils/config";
import { getToolDiagram } from "../global/diagram";

import {
    nodeForceLayoutModes,
    nodeForceLayoutProps,
    nodeId,
} from "../../config";

export const createDraftForceLayoutNode = (set, get, position) => {
    try {
        const selectedApp = window.store.system.selectedApp;
        const messages = [];
        const targetIds = [];

        const mainDraftForceLayout = {
            ...nodeForceLayoutProps,
            position,
            id: `${nodeId.DRAFT}-${generateID()}`,
            data: {
                mode: nodeForceLayoutModes.MAIN,
            },
        };
        messages.push(mainDraftForceLayout);

        Object.entries(advancedMessages).forEach(([key, category]) => {
            const supportedMessages = Object.entries(category.messages).filter(
                ([key, message]) => {
                    return _.get(message, `platform.${selectedApp.platform}`);
                }
            );

            if (key && category && supportedMessages.length > 0) {
                const categoryDraftForceLayout = {
                    ...nodeForceLayoutProps,
                    position,
                    id: `${nodeId.DRAFT}-${generateID()}`,
                    data: {
                        mode: nodeForceLayoutModes.CATEGORY,
                        slug: key,
                        label: category.name,
                    },
                };

                targetIds.push(categoryDraftForceLayout.id);
                messages.push(categoryDraftForceLayout);
            }
        });
        setTimeout(() => {
            getToolDiagram("setNodes")((nds) => nds.concat(...messages));
        }, 100);

        const edges = [];
        targetIds.forEach((targetId) => {
            if (targetId) {
                const source = {
                    nodeId: mainDraftForceLayout.id,
                };

                const target = {
                    nodeId: targetId,
                };

                edges.push({ source, target });
            }
        });
        setTimeout(() => {
            useStoreDiagram.getState().addEdgeForceLayout(edges);
        }, 100);
    } catch (err) {
        console.error("[createDraftForceLayoutNode] ", err);
    }
};
