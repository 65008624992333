import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* updateECommerceConfig() {
	while (true) {
		yield take(types.UPDATE_ECOMMERCE_CONFIG)
		try {
			yield put(actions.updateSaveConfigLoading(true))

			const config = yield select(selectors.getECommerceConfig)
			yield call(api.updateECommerceConfig, config)

			const toastManager = yield select(selectors.getToast)
			toastManager.success('Saved successfully')
			yield put(actions.updateSaveConfigLoading(false))
		} catch (err) {
			console.error('[saveECommerceConfig] ', err)
			yield put(actions.updateSaveConfigLoading(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			eventlog.error('update ecommerce config', {
				message: err.message
			})
		}
	}
}

export default updateECommerceConfig
