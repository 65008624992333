import { getToolDiagram } from "../global/diagram";

export const undo = (set, get) => {
	try {
		const getNodes = getToolDiagram("getNodes");
		const getEdges = getToolDiagram("getEdges");
		const setNodes = getToolDiagram("setNodes");
		const setEdges = getToolDiagram("setEdges");
		const past = get().past;
		const future = get().future;

		// get the last state that we want to go back to
		const pastState = past[past.length - 1];

		if (pastState) {
			// first we remove the state from the history
			set({
				past: past.slice(0, past.length - 1),
			});

			// // we store the current graph for the redo operation
			set({
				future: [
					...future,
					{ type: pastState.type, data: pastState.data, id: pastState.data.id, nodes: getNodes(), edges: getEdges() },
				],
			});
			// now we can set the graph to the past state
			setNodes(pastState.nodes);
			setEdges(pastState.edges);
		}
	} catch (err) {
		console.error("[undo] ", err);
	}
};
