import * as types from '../types'

const response = (
	state = {
		moduleResponseDetail: {
			columns: [],
			responses: [],
		},
	},
	action
) => {
	switch (action.type) {
		case types.MODULE_RESPONSE_DETAIL:
			return {
				...state,
				moduleResponseDetail: action.payload.detail,
			}
		case types.ANALYTIC_DATA:
			return {
				...state,
				analyticData: action.payload.data
			}
		case types.DASHBOARD_DATA:
			return {
				...state,
				dashboardData: action.payload.data
			}
		default:
			return state
	}
}

export default response
