export const getNewMessage = (state) => {
	return state.messageConfig.newMessage;
};

export const getBackendStep = (state) => {
	return state.messageConfig.backendstep;
};

export const getEditMessage = (state) => {
	return state.messageConfig.editMessage;
};

export const getIsFetchingModuleResponses = (state) => {
	return state.messageConfig.isFetchingModuleResponses;
};

export const getModuleResponses = (state) => {
	return state.messageConfig.moduleResponses;
};

export const getRemovedMessages = (state) => {
	return state.messageConfig.removedMessages;
};

export const getEditMap = (state) => {
	return state.messageConfig.editMap;
};

export const getDesignConfig = (state) => {
	return state.messageConfig.design;
};

export const getVariables = (state) => {
	return state.messageConfig.variables;
};

export const getEditModalConfig = (state) => {
	return state.system.editModalConfig;
};

export const getApps = (state) => {
	return state.system.apps;
};

export const getDraftAppConfig = (state) => {
	return state.system.draftAppConfig;
};

export const isConfigLoading = (state) => {
	return state.system.isConfigLoading;
};

export const getToast = (state) => {
	return state.system.toast;
};

export const getDraftUploadMedia = (state) => {
	return state.system.draftUploadMedia;
};

export const getStartOver = (state) => {
	return state.system.startOver;
};

export const getLineConfig = (state) => {
	return state.system.lineConfig;
};

export const getECommerceConfig = (state) => {
	return state.system.ecommerceConfig;
};

export const getCredentials = (state) => {
	return state.system.credentials;
};

export const getUserID = (state) => {
	return state.system.userID;
};

export const getSelectedApp = (state) => {
	return state.system.selectedApp;
};

export const getTypingEmulation = (state) => {
	return state.system.typingEmulation;
};

export const getDraftNewBot = (state) => {
	return state.system.draftNewBot;
};

export const getIsValidatingSlug = (state) => {
	return state.system.isValidatingSlug;
};

export const getTemplateConfig = (state) => {
	return state.system.templateConfig;
};

export const getMobileAppConfig = (state) => {
	return state.system.mobileAppConfig;
};

export const getEnablingFeatureFlags = (state) => {
	return state.system.enablingFeatureFlags;
};

export const getFeatureFlags = (state) => {
	return state.system.featureFlags;
};

export const getVisitors = (state) => {
	return state.messenger.visitors;
};

export const getMessengerManager = (state) => {
	return state.messenger.manager;
};

export const getMessengerMessages = (state) => {
	return state.messenger.messages;
};

export const getSelectedVisitor = (state) => {
	return state.messenger.selectedVisitor;
};

export const getFailedMessages = (state) => {
	return state.messenger.failedMessages;
};

export const getPresenceVisitors = (state) => {
	return state.messenger.presence;
};

export const getHumantakeover = (state) => {
	return state.messenger.humantakeover;
};

export const getAccessControls = (state) => {
	return state.auth.accessControls;
};

export const getLoginFormData = (state) => {
	return state.auth.loginForm;
};

export const getForgotPasswordForm = (state) => {
	return state.auth.forgotPasswordForm;
};

export const getResetPasswordForm = (state) => {
	return state.auth.resetPasswordForm;
};

export const getSelectedPlan = (state) => {
	return state.subscription.selectedPlan;
};

export const getAllPlans = (state) => {
	return state.subscription.allPlans;
};

export const getCurrentSchedule = (state) => {
	return state.subscription.currentSchedule;
};

export const getCurrentCards = (state) => {
	return state.subscription.currentCards;
};

export const getIsAddingCard = (state) => {
	return state.subscription.isAddingCard;
};

export const getDraftCard = (state) => {
	return state.subscription.draftCard;
};

export const getAddCardEror = (state) => {
	return state.subscription.addCardError;
};

export const getIsSubscriptionPageLoading = (state) => {
	return state.subscription.isSubscriptionPageLoading;
};

export const getIsBanLineCustomer = (state) => {
	return state.subscription.allPlans;
};

export const getIsShowingAddPaymentMethod = (state) => {
	return state.subscription.isShowingAddPaymentMethod;
};

export const getIsShowingEditPaymentMethod = (state) => {
	return state.subscription.isShowingEditPaymentMethod;
};

export const getSelectPlanPlatform = (state) => {
	return state.subscription.selectPlansPlatform;
};

export const getBillingInformation = (state) => {
	return state.subscription.billingInformation;
};

export const getBillingInformationError = (state) => {
	return state.subscription.billingInformationError;
};

export const getCardError = (state) => {
	return state.subscription.cardError;
};

export const getIsChangingPlan = (state) => {
	return state.subscription.isChangingPlan;
};

export const getBillingCycle = (state) => {
	return state.subscription.billingCycle;
};

export const getUpgradePlan = (state) => {
	return state.subscription.upgradePlan;
};

export const getPaidInvoicesSubscription = (state) => {
	return state.subscription.invoicesStatusPaidSubscription;
};

export const getUnpiadInvoicesSubscription = (state) => {
	return state.subscription.invoicesStatusUnpaidSubscription;
};

export const getCurrentAppPlan = (state) => {
	return state.subscription.currentAppPlan;
};

export const getSummaryPlan = (state) => {
	return state.subscription.summaryPlan;
};

export const getIsShowCurrentUsageUpgradeDetail = (state) => {
	return state.subscription.IsShowCurrentUsageUpgradeDetail;
};

export const getIsShowChatBot = (state) => {
	return state.subscription.isShowChatBot;
};

export const getIsCancelAutoRenewPlan = (state) => {
	return state.subscription.isCancelAutoRenewPlan;
};

export const getIsShowingUnpaidInvoiceSummary = (state) => {
	return state.subscription.isShowingUnpaidInvoiceSummary;
};

export const getSelectedUnpaidInvoice = (state) => {
	return state.subscription.selectedUnpaidInvoices;
};

export const getisSelectedAllUnpaidInvoice = (state) => {
	return state.subscription.isSelectedAllUnpiadInvoices;
};

export const getIsPayingUnpaidInvoice = (state) => {
	return state.subscription.isPayingUnpaidInvoice;
};

export const getMessageConfigBackupDiagramHistory = (state) => {
	return state.saveDiagramHistory.messageConfigBackupDiagramHistory;
};

export const getUniqueID = (state) => {
	return state.system.uniqueID;
};
