import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* sendCollaboratorInvitation() {
	while (true) {
		const {
			payload: { email, role },
		} = yield take(types.SEND_COLLABORATOR_INVATATION)
		try {
			eventlog.info('send collaborator invitation', {
				email,
				role
			})
			yield put(actions.isInvitingCollaborator(true))
			yield call(api.sendInvitation, email, role)
			const { data } = yield call(api.fetchCollaborators)
			yield put(actions.collaborators(data))
			yield put(actions.isInvitingCollaborator(false))
			yield put(actions.isShowingInvitationModal(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.success('Send invitation to ' + email)
		} catch (err) {
			console.error('[sendCollaboratorInvitation] ', err)
			yield put(actions.isInvitingCollaborator(false))
			yield put(actions.isShowingInvitationModal(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			eventlog.error('send collaborator invitation', {
				message: err.message
			})
		}
	}
}

export default sendCollaboratorInvitation
