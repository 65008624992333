import * as actions from "../actions";
import * as apis from "../apis";
import * as types from "../types";
import { call, put, take } from "redux-saga/effects";

function* onEnterBillingInformation() {
	while (true) {
		yield take(types.ON_ENTER_BILLING_INFORMATION);
		try {
			const { data: billingInformation } = yield call(
				apis.getBillingInformation
			);
			yield put(actions.storeBillingInformation(billingInformation));
		} catch (err) {
			console.error("[onEnterBillingInformation] ", err);
		}
	}
}

export default onEnterBillingInformation;
