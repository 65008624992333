import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* banLineCustomer() {
	while (true) {
		const { payload: { id, limit, offset, search } } = yield take(types.BAN_LINE_CUSTOMER)
		try {
			eventlog.info('ban line customer')
			yield call(api.banLineCustomer, id)
			const { data } = yield call(api.fetchLineCustomerWithQuery, limit, offset, search)
			yield put(actions.lineCustomer(data))
		} catch (err) {
			console.error('[banLineCustomer] ', err)
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')

			eventlog.error('ban line customer', {
				message: err.message
			})
		}
	}
}

export default banLineCustomer
