import * as types from '../types'

const subscription = (
	state = {
		selectedPlan: undefined,
		allPlans: {},
		isSubscriptionPageLoading: true,
		isShowingAddPaymentMethod: false,
		isShowingEditPaymentMethod: false,
		selectPlansPlatform: 'web',
		billingInformation: {},
		billingInformationError: {},
		cardError: {},
		isChangingPlan: false,
		billingCycle: 'monthly',
		upgradePlan: undefined,
		invoicesStatusPaidSubscription: [],
		invoicesStatusUnpaidSubscription: [],
		currentAppPlan: {},
		summaryPlan: {},
		IsShowCurrentUsageUpgradeDetail: false,
		isShowChatBot: {
			status: false,
			chatURL: ''
		},
		isCancelAutoRenewPlan: false,
		isShowingUnpaidInvoiceSummary: false,
		selectedUnpaidInvoices: [],
		isSelectedAllUnpiadInvoices: false,
		isPayingUnpaidInvoice: false,
	},
	action
) => {
	switch (action.type) {
		case types.SELECTED_PLAN:
			return {
				...state,
				selectedPlan: action.payload,
			}
		case types.ALL_PLANS:
			return {
				...state,
				allPlans: action.payload,
			}
		case types.CURRENT_SCHEDULE:
			return {
				...state,
				currentSchedule: action.payload
			}
		case types.CURRENT_CARDS:
			return {
				...state,
				currentCards: action.payload
			}
		case types.DRAFT_CARD:
			return {
				...state,
				draftCard: action.payload
			}
		case types.IS_ADDING_CARD:
			return {
				...state,
				isAddingCard: action.payload
			}
		case types.ADD_CARD_ERROR:
			return {
				...state,
				addCardError: action.payload
			}
		case types.IS_SUBSCIPTION_PAGE_LOADING:
			return {
				...state,
				isSubscriptionPageLoading: action.payload
			}
		case types.IS_SHOWING_ADD_PAYMENT_METHOD:
			return {
				...state,
				isShowingAddPaymentMethod: action.payload
			}
		case types.IS_SHOWING_EDIT_PAYMENT_METHOD:
			return {
				...state,
				isShowingEditPaymentMethod: action.payload
			}
		case types.ON_SELECT_PLANS_PLATFORM_CHANGE:
			return {
				...state,
				selectPlansPlatform: action.payload
			}
		case types.STORE_BILLING_INFORMATION:
			return {
				...state,
				billingInformation: action.payload
			}
		case types.BILLING_INFORMATION_ERROR:
			return {
				...state,
				billingInformationError: action.payload
			}
		case types.CARD_ERROR:
			return {
				...state,
				cardError: action.payload
			}
		case types.ON_CHANGING_PLAN:
			return {
				...state,
				isChangingPlan: action.payload
			}
		case types.STORE_BILLING_CYCLE:
			return {
				...state,
				billingCycle: action.payload
			}
		case types.STORE_UPGRADE_PLAN:
			return {
				...state,
				upgradePlan: action.payload
			}
		case types.STORE_INVOICES_STATUS_PAID_SUBSCRIPTION:
			return {
				...state,
				invoicesStatusPaidSubscription: action.payload
			}
		case types.STORE_INVOICES_STATUS_UNPAID_SUBSCRIPTION:
			return {
				...state,
				invoicesStatusUnpaidSubscription: action.payload
			}
		case types.STORE_CURRENT_APP_PLAN:
			return {
				...state,
				currentAppPlan: action.payload
			}
		case types.STORE_SUMMARY_PLAN:
			return {
				...state,
				summaryPlan: action.payload
			}
		case types.IS_SHOW_CURRENT_USAGE_UPGRADE_DETAIL:
			return {
				...state,
				IsShowCurrentUsageUpgradeDetail: action.payload.status
			}
		case types.IS_SHOW_CHAT_BOT:
			return {
				...state,
				isShowChatBot: action.payload
			}
		case types.IS_CANCEL_AUTO_RENEW_PLAN:
			return {
				...state,
				isCancelAutoRenewPlan: action.payload.status
			}
		case types.IS_SHOWING_UNPAID_INVOICE_SUMMARY:
			return {
				...state,
				isShowingUnpaidInvoiceSummary: action.payload.status
			}
		case types.SELECTED_UNPAID_INVOICES:
			const isIdPresent = state.selectedUnpaidInvoices.some(
				(invoice) => invoice._id === action.payload.invoice._id
			);

			if (!isIdPresent) {
				const updatedUnpaidInvoices = [
					...state.selectedUnpaidInvoices,
					action.payload.invoice,
				].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

				return {
					...state,
					selectedUnpaidInvoices: updatedUnpaidInvoices,
				};
			} else {
				// If id is already present, remove it from the array and sort by created_at
				const updatedUnpaidInvoices = state.selectedUnpaidInvoices
					.filter((invoice) => invoice._id !== action.payload.invoice._id)
					.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

				return {
					...state,
					selectedUnpaidInvoices: updatedUnpaidInvoices,
				};
			}
		case types.SELECTED_ALL_UNPAID_INVOICES:
			if (state.selectedUnpaidInvoices.length === state.invoicesStatusUnpaidSubscription.length) {
				return {
					...state,
					selectedUnpaidInvoices: []
				}
			}
			return {
				...state,
				selectedUnpaidInvoices: action.payload.invoices
			}
		case types.IS_SELECTED_ALL_UNPAID_INVOICES:
			return {
				...state,
				isSelectedAllUnpiadInvoices: action.payload.status
			}
		case types.IS_PAYING_UNPAID_INVOICE:
			return {
				...state,
				isPayingUnpaidInvoice: action.payload.status
			}
		default:
			return state
	}
}

export default subscription
