import "./contribution.css";
import * as React from "react";
import Wave from "./wave";

class ContributionPage extends React.Component {
    canvasRef = React.createRef()

    componentDidMount() {
        this.startWaving()
    }

    componentDidUpdate() {
        this.startWaving()

        const { isPlaying } = this.props

        if (this.wave) {
            isPlaying ? this.wave.play() : this.wave.idle()
        }
    }

    componentWillUnmount() {
        if (this.wave) this.wave.idle()
    }

    startWaving = () => {
        const canvas = this.canvasRef.current

        if (this.wave) {
            if (!canvas) {
                this.wave.idle()
                this.wave = null
            }
            return
        }

        if (canvas) {
            this.wave = new Wave(canvas)
        }
    }

    render() {
        const {
            type,
        } = this.props

        return (
            <div
                className="contribution-wrapper"
            >
                <div
                    className={[
                        "contribution",
                        type,
                    ].join(" ")}
                >
                    {this.renderContent()}
                </div>
            </div>
        )
    }

    renderContent() {
        const {
            primaryButtons,
        } = this.props

        return (
            <>
                <div className="primary-buttons">
                    <canvas ref={this.canvasRef} />
                    {primaryButtons}
                </div>
            </>
        )
    }
}

export default ContributionPage
