import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchRemovedMessages() {
	while (true) {
		yield take(types.FETCH_REMOVED_MESSAGES)
		try {
			const {
				data: { messages },
			} = yield call(api.removedMessages)
			yield put(actions.removedMessages(messages || []))
		} catch (err) {
			console.error('[fetchRemovedMessages] ', err)
			eventlog.error('fetch removed messages', {
				message: err.message
			})
		}
	}
}

export default fetchRemovedMessages
