import * as React from 'react'

const Copy = () => (
	<svg
		width='16'
		height='16'
		x='0px'
		y='0px'
		viewBox='0 0 1000 1000'
		enableBackground='new 0 0 1000 1000'
	>
		<g>
			<g transform='translate(0.000000,511.000000) scale(0.100000,-0.100000)'>
				<path d='M6981.1,4975.1c-370.6-66.4-743.7-232.6-1042.7-460C5718.5,4346.4,3809.5,2427.1,3743,2307l-48.6-86.9l86.9,15.3c347.6,61.3,759,40.9,1157.7-61.3l201.9-51.1l769.3,764.1c679.8,677.3,787.2,774.4,935.4,843.4c513.7,237.7,1040.2,153.3,1456.7-240.2c329.7-311.8,442.1-562.3,442.1-981.4c0-299-46-472.8-186.6-687.5c-40.9-63.9-513.7-554.6-1053-1091.3c-920-912.4-989.1-976.3-1157.7-1052.9c-309.3-138-575-155.9-907.3-63.9c-191.7,51.1-301.6,112.5-477.9,260.7c-250.5,207-380.8,250.5-600.6,204.5c-189.1-40.9-385.9-237.7-426.8-426.8c-48.6-235.1,15.3-403.8,235.1-610.8c488.1-462.6,1219.1-702.8,1898.9-621c449.8,53.7,861.3,219.8,1211.4,483C7528-908.1,9347.7,932,9478,1126.2c155.9,232.6,296.5,549.5,362.9,810.2c79.2,316.9,79.2,874-2.6,1173.1c-217.2,810.2-866.4,1505.3-1658.6,1778.8C7832.1,5008.3,7351.6,5041.6,6981.1,4975.1z' />
				<path d='M3881,1793.2c-350.1-48.5-741.2-201.9-1060.6-416.6C2633.8,1251.4,791.2-575.9,596.9-826.4C29.6-1567.5-59.9-2561.7,369.5-3407.6c314.4-618.5,945.6-1129.6,1615.2-1308.5c342.5-92,853.6-97.1,1191-12.8c260.7,66.5,577.6,207,810.2,362.9c296.5,199.3,2366.6,2289.9,2315.5,2341c-5.1,5.1-115-2.6-245.4-17.9c-281.1-33.2-672.1,0-981.4,81.8l-201.9,56.2l-756.5-753.9c-437-434.5-807.6-782-874.1-820.4c-214.7-120.1-375.7-158.5-651.7-155.9c-408.9,5.1-661.9,120.1-968.6,444.7c-391,416.6-477.9,940.5-240.2,1456.7c71.6,150.8,176.3,265.8,1063.2,1155.2C3300.9,280.3,3454.2,423.4,3605,502.6c322,171.2,677.3,201.9,1001.8,89.4c194.2-66.4,276-112.4,442.1-253c250.4-207,380.8-250.5,600.6-204.5c189.1,40.9,385.9,237.7,426.8,426.8c48.6,235.1-15.3,403.8-235.1,610.8c-163.6,155.9-444.7,342.5-651.7,434.5C4829.2,1765.1,4266.9,1846.9,3881,1793.2z' />
			</g>
		</g>
	</svg>
)

export default Copy