import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* humanTakeoverComplete() {
	while (true) {
		const { payload: { appId, roomId }} = yield take(types.HUMAN_TAKEOVER_COMPLETE)
		try {
			eventlog.info('human takeover complete')

			const apps = yield select(selectors.getApps)
			const appSlug = apps.find((app) => app._id === appId).slug

			yield call(api.humanTakeoverComplete, appSlug, roomId)

			const visitors = yield select(selectors.getVisitors)

			yield put(actions.visitors({
				...visitors,
				[roomId]: {
					...visitors[roomId],
					human_takeover_enabled: false
				}
			}))
		} catch (err) {
			console.error('[humanTakeoverComplete] ', err)
			eventlog.error('human takeover complete', {
				message: err.message
			})
		}
	}
}

export default humanTakeoverComplete
