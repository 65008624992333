import * as types from '../types'

import eventlog from '../../modules/eventlog'
import { take } from 'redux-saga/effects'

function* unauthorization() {
	while (true) {
		yield take(types.UNAUTHORZIATION)
		try {
			const redirectUrl = window.location.href

			const paramUrl = window.location.search
			const searchParams = new URLSearchParams(paramUrl);

			eventlog.info('unauthorization', {
				redirectUrl
			})
			const redirectLogin = localStorage.getItem('redirectLogin')
			const isInvitation = localStorage.getItem('isInvitation')

			localStorage.clear()
			
			if (redirectLogin) {
				window.location.href = redirectLogin
			} else {
				if (!searchParams.has("redirect")) {
					window.location.href = `/login?redirect=${redirectUrl}`
				}
			}
			
			localStorage.setItem('isInvitation', isInvitation);
		} catch (err) {
			console.error('[unauthorization] ', err)
			eventlog.error('unauthorization', {
				message: err.message
			})
		}
	}
}

export default unauthorization
	