import * as actions from '../actions'
import * as selectors from '../selectors'
import * as types from '../types'

import { put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* deleteMessage() {
	while (true) {
		const {
			payload: { message },
		} = yield take(types.DELETE_MESSAGE)
		try {
			eventlog.info('delete a message')

			const failedMessages = yield select(selectors.getFailedMessages)
			const messages = yield select(selectors.getMessengerMessages)
			const selectedVisitor = yield select(selectors.getSelectedVisitor)
			const newMessages = messages[selectedVisitor._id].filter((m) => m.id !== message.id)
			delete failedMessages[message.id]
			yield put(actions.failedMessages({ ...failedMessages }))
			yield put(
				actions.visitorMessages({
					...messages,
					[selectedVisitor._id]: newMessages,
				})
			)
		} catch (err) {
			console.error('[deleteMessage] ', err)
			eventlog.error('delete message', {
				message: err.message
			})
		}
	}
}

export default deleteMessage
