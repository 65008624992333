import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* onFetchApps() {
	while (true) {
		try {
			yield take(types.FETCH_APPS)
			const response = yield call(api.fetchApps)
			yield put(actions.apps(response.data))
			yield put(actions.isAppsLoading(false))
		} catch (err) {
			console.error('[onFetchApps] ', err)
			eventlog.error('fetch apps', {
				message: err.message
			})
		}
	}
}

export default onFetchApps
