const validation = (billingInformation) => {
	let errors = {};

	if (billingInformation.billing_type === "personal") {
		if (!billingInformation.first_name) {
			errors.firstName = "First name name is requried";
		}
		if (!billingInformation.last_name) {
			errors.lastName = "Last name is requried";
		}
	} else {
		if (!billingInformation.company_name) {
			errors.companyName = "Company name is requried";
		}
	}

	if (!billingInformation.email) {
		errors.email = "Email is requried";
	} else {
		// eslint-disable-next-line no-useless-escape
		const reEmail =
			/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (!reEmail.test(billingInformation.email)) {
			errors.email = "Email wrong format";
		}
	}

	if (billingInformation.phone_number) {
		// eslint-disable-next-line no-useless-escape
		const rePhone =
			/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
		if (!rePhone.test(billingInformation.phone_number)) {
			errors.phoneNumber = "Phone number wrong format";
		}
	}

	if (!billingInformation.postal_code) {
		errors.postalCode = "Zip or Postal Code is requried";
	}

	if (!billingInformation.city) {
		errors.city = "City is requried";
	}

	if (!billingInformation.address) {
		errors.address = "Address is requried";
	}

	return new Promise((resolve) => {
		if (Object.keys(errors).length === 0) {
			return resolve({});
		}
		return resolve(errors);
	});
};

export default validation;
