import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* onDeleteCard() {
	while (true) {
		const {
			payload: { cardId },
		} = yield take(types.ON_DELETE_CARD)
		try {
			eventlog.info('delete a payment method')
			yield call(api.deleteCard, cardId)
			yield put(actions.currentCards({ id: '' }))
			const toastManager = yield select(selectors.getToast)
			toastManager.success('Remove card success')
		} catch (err) {
			console.error('[deleteMessage] ', err)
			eventlog.error('delete message', {
				message: err.message
			})
		}
	}
}

export default onDeleteCard
