import { getToolDiagram } from "../global/diagram";

export const removeNodeDraft = (set, get, id) => {
	try {
		getToolDiagram("setNodes")(
			(nodes) => nodes.filter((node) => node.id !== id)
		)
	} catch (err) {
		console.error("[removeNodeDraft] ", err);
	}
};
