import {
    transformDiagram,
    transformMessageConfig,
} from "../../utils/transform";

export const getMessageConfigDiagram = () => {
    if (typeof window.toolDiagramGlobal !== "object") {
        return {};
    }

    const { getNodes } = window.toolDiagramGlobal;

    return transformMessageConfig(getNodes());
};

export const setMessageConfigDiagram = (messages) => {
    if (typeof messages !== "object") {
        return;
    }

    if (typeof window.toolDiagramGlobal !== "object") {
        return;
    }

    const { setNodes } = window.toolDiagramGlobal;

    setNodes(transformDiagram(messages));
};

export const getToolDiagram = (name) => {
    if (typeof window.toolDiagramGlobal !== "object") {
        return;
    }

    return window.toolDiagramGlobal[name];
};
