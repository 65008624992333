import * as types from '../types'

const dataFeed = (
	state = {
		datafeeds: {
			all: 0,
			logs: [],
			limit: 0,
			offset: 0,
		},
		isFetchingDatafeedLog: false,
	},
	action
) => {
	switch (action.type) {
		case types.DATA_FEED_SELECT:
			return {
				...state,
				...action.payload.data,
			}
		case types.DATA_FEED:
			return {
				...state,
				datafeeds: action.payload.datafeeds,
			}
		case types.IS_FETCHING_DATA_FEED:
			return {
				...state,
				isFetchingDatafeedLog: action.payload.status,
			}
		default:
			return state
	}
}

export default dataFeed
