import * as types from '../types'

import eventlog from '../../modules/eventlog'
import { take } from 'redux-saga/effects'

function* onSelectApp() {
	while (true) {
		try {
			yield take(types.ON_SELECT_APP)
		} catch (err) {
			console.error('[onSelectApp] ', err)
			eventlog.error('select app', {
				message: err.message
			})
		}
	}
}

export default onSelectApp
