import advancedMessages from './advancedMessages'

const messages = Object.keys(advancedMessages).reduce((o, key) => {
	return {
		...advancedMessages[key].messages,
		...o,
	}
}, {})

export default messages
