import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* saveLineConfig() {
	while (true) {
		yield take(types.SAVE_LINE_CONFIG)
		try {
			eventlog.info('save line config')
			yield put(actions.updateSaveConfigLoading(true))

			const config = yield select(selectors.getLineConfig)
			yield call(api.saveLineConfig, config)
			const lineConfig = yield call(api.fetchLineConfig)

			yield put(actions.lineConfig(lineConfig))

			const toastManager = yield select(selectors.getToast)
			toastManager.success('Saved successfully')

			// clear line config errors
			yield put(actions.lineConfigErrors({}))
			yield put(actions.updateSaveConfigLoading(false))
		} catch (err) {
			console.error('[saveLineConfig] ', err)
			if (err.response.data.status === 800) {
				yield put(
					actions.lineConfigErrors({
						token: 'Invalid line token',
					})
				)
			}

			yield put(actions.updateSaveConfigLoading(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')

			eventlog.error('save line config', {
				message: err.message
			})
		}
	}
}

export default saveLineConfig
