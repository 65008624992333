import auth from "./auth";
import collaborator from "./collaborator";
import dataFeed from "./dataFeed";
import lineCustomer from "./lineCustomer";
import lineInsight from "./lineInsight";
import messageConfig from "./messageConfig";
import messenger from "./messenger";
import previewConfig from "./previewConfig";
import product from "./product";
import response from "./response";
import saveDiagramHistory from "./saveDiagramHistory";
import subscription from "./subscription";
import system from "./system";
import { combineReducers } from "redux";

export default combineReducers({
	messageConfig,
	auth,
	previewConfig,
	system,
	messenger,
	collaborator,
	product,
	response,
	subscription,
	lineCustomer,
	lineInsight,
	dataFeed,
	saveDiagramHistory
})
