import * as actions from '../actions'
import * as api from '../apis'
import * as types from '../types'

import { call, put, take } from 'redux-saga/effects'

import eventlog from '../../modules/eventlog'

function* fetchGoogleLinkingDetail() {
	while (true) {
		yield take(types.FETCH_GOOGLE_LINKING_DETAIL)
		try {
			const linkingDetail = yield call(api.googleLinkingDetail)
			yield put(actions.googleLinkingDetail(linkingDetail))

			if (linkingDetail) {
				const { data: spreadsheets } = yield call(api.spreadsheets)
				if (spreadsheets) {
					yield put(actions.googleSpreadsheets(spreadsheets))
				}
			}
		} catch (err) {
			console.error('[fetchGoogleLinkingDetail] ', err)
			eventlog.error('fetch google link detail', {
				message: err.message
			})
		}
	}
}

export default fetchGoogleLinkingDetail
