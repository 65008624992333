import * as types from '../types'

const lineCustomer = (
	state = {
		lineCustomers: {
			all: 0,
			customers: [],
			limit: 0,
			offset: 0,
		},
		isFetchingLineCustomer: false,
		isShowingTrackingMessageModal: false,
	},
	action
) => {
	switch (action.type) {
		case types.LINE_CUSTOMER:
			return {
				...state,
				lineCustomers: action.payload.lineCustomer,
			}
		case types.IS_FETCHING_LINE_CUSTOMER:
			return {
				...state,
				isFetchingLineCustomer: action.payload.status,
			}
		case types.IS_SHOWING_TRACKING_MESSAGE_MODAL:
			return {
				...state,
				isShowingTrackingMessageModal: action.payload.status,
			}
		default:
			return state
	}
}

export default lineCustomer
