import lineButtonTemplate from './lineButtonTemplate'
import lineCarouselTemplate from './lineCarouselTemplate'
import lineImageCarouselTemplate from './lineImageCarouselTemplate'
import generateQrCodePayment from './generateQrCodePayment'
import richmenu from './richMenu'
import lineImageMap from './lineImageMap'
import businessHours from './businessHours'
import quickReply from './quickReply'

const validation = (editMessage) => {
	const { mode } = editMessage

	return new Promise((resolve) => {
		if (mode === 'LINE/BUTTON-TEMPLATE') {
			const errors = lineButtonTemplate(editMessage)
			if (Object.keys(errors).length === 0) {
				return resolve({})
			}
			return resolve(errors)
		}

		if (mode === 'LINE/CAROUSEL-TEMPLATE') {
			const errors = lineCarouselTemplate(editMessage)
			if (Object.keys(errors).length === 0) {
				return resolve({})
			}
			return resolve(errors)
		}

		if (mode === 'LINE/IMAGE-CAROUSEL-TEMPLATE') {
			const errors = lineImageCarouselTemplate(editMessage)
			if (Object.keys(errors).length === 0) {
				return resolve({})
			}
			return resolve(errors)
		}

		if (mode === 'GENERATE-QR-CODE-PAYMENT') {
			const errors = generateQrCodePayment(editMessage)
			if (Object.keys(errors).length === 0) {
				return resolve({})
			}
			return resolve(errors)
		}

		if (mode === 'LINE/RICH-MENU') {
			const errors = richmenu(editMessage)
			if (Object.keys(errors).length === 0) {
				return resolve({})
			}
			return resolve(errors)
		}

		if (mode === 'BUSINESS-HOURS') {
			const errors = businessHours(editMessage)
			if (Object.keys(errors).length === 0) {
				return resolve({})
			}
			return resolve(errors)
		}

		if (mode === 'LINE/IMAGE-MAP') {
			const errors = lineImageMap(editMessage)
			if (Object.keys(errors).length === 0) {
				return resolve({})
			}
			return resolve(errors)
		}

		if (mode === 'QUICK-REPLY/DEFAULT' || mode === 'QUICK-REPLY/COLOR' || mode === 'QUICK-REPLY/SHIRT-SIZE') {
			const errors = quickReply(editMessage)
			if (Object.keys(errors).length === 0) {
				return resolve({})
			}
			return resolve(errors)
		}

		return resolve({})
	})
}

export default validation